import { Typography, styled } from '@mui/material';

interface IListHeaderProps {
  title: string
  action?: React.ReactNode
  searchField?: React.ReactNode
  toolTipField?: React.ReactNode
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 40px',
  minWidth: '1000px',
  height: '128px',
});

const TopField = styled('div')({
  display: 'flex',
  height: '28px',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const BottomField = styled('div')({
  marginTop: '16px',
  display: 'flex',
  height: '36px',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const SearchField = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const TooltipField = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

function ListHeader({
  title,
  action,
  searchField,
  toolTipField,
}: IListHeaderProps) {
  return (
    <Container>
      <TopField>
        <Typography variant="subtitle1">{title}</Typography>
        {action}
      </TopField>
      <BottomField>
        <SearchField>{searchField}</SearchField>
        <TooltipField>{toolTipField}</TooltipField>
      </BottomField>
    </Container>
  );
}

export default ListHeader;
