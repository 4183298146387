import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Skeleton from 'react-loading-skeleton';
import ProductLogo from 'src/components/common/ProductLogo';
import { Product } from './models';
import productDescriptions from '../../../const/productDescriptions';

interface ProductCardProps {
  product?: Product
  loading?: boolean
}

function ProductCard({ product, loading }: ProductCardProps) {
  return (
    <Link href={`/${product?.path}`}>
      <Card
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          backgroundColor: (theme) => theme.palette.darkGrey[80],
          borderRadius: '8px',
          height: '160px',
          padding: '30px',
          width: '480px',
          cursor: 'pointer',
          alignItems: 'flex-start',
          gap: '20px',
          '&:hover': { backgroundColor: (theme) => theme.palette.darkGrey[70] },
        }}
      >
        {!loading && product && (
          <>
            <ProductLogo product={product} />
            <Typography
              color="darkGrey.30"
              variant="body2"
            >
              {productDescriptions[product.path].map((paragraph: string) => <div>{paragraph}</div>)}
            </Typography>
          </>
        )}
        {loading && (
        <Skeleton
          width={453}
          height={102}
        />
        )}
      </Card>
    </Link>
  );
}

export default ProductCard;
