import { InfoIcon } from '@lunit/scope-icons';
import {
  Box,
  IconButton,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { SectionContainer, SectionHeader } from './common';

interface ViewOptionsProps {
  children: ReactNode
  containerSx?: SxProps<Theme>
  headerSx?: SxProps<Theme>
  title?: ReactNode
  viewIcon?: ReactNode
  infoIcon?: boolean
  handleOptionClick?: React.MouseEventHandler<HTMLButtonElement>
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export default function ViewOptions({
  children,
  containerSx,
  headerSx,
  title,
  viewIcon,
  infoIcon,
  handleOptionClick,
  onMouseEnter,
  onMouseLeave,
  ...rest
}: ViewOptionsProps) {
  return (
    <SectionContainer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={containerSx}
      {...rest}
    >
      <SectionHeader sx={headerSx}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="body3">View Options</Typography>
          {infoIcon && (
            <Tooltip title={title}>
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: 0.5,
                  padding: 0.5,
                  borderRadius: '8px',
                  color: (theme) => theme.palette.darkGrey[30],
                  '&:hover': { backgroundColor: (theme) => theme.palette.darkGrey[80] },
                }}
              >
                <InfoIcon />
              </Box>
            </Tooltip>
          )}
        </Box>
        <IconButton
          sx={{
            padding: 0.5,
            ':hover': { backgroundColor: (theme) => theme.palette.darkGrey[80] },
          }}
          onClick={handleOptionClick}
        >
          {viewIcon}
        </IconButton>
      </SectionHeader>
      {children}
    </SectionContainer>
  );
}
