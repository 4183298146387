import { SlidePanel } from '@lunit/scope-components';
import { Divider } from '@mui/material';
import { UIComponents } from 'src/api/biomarkers/models';
import { ConfiguredProject, InfoTooltipConfig } from 'src/types';
import ViewOptionsSubPanel from './ViewOptionsSubPanel';

interface ViewOptionsAnalysisPanelProps {
  uiComponents: UIComponents
  config: ConfiguredProject
  AnalysisSummary: React.ReactNode
  infoTooltipConfig?:InfoTooltipConfig[]
}

function ViewOptionsAnalysisPanel({
  AnalysisSummary,
  ...SubPanelProps
}: ViewOptionsAnalysisPanelProps) {
  return (
    <SlidePanel>
      <ViewOptionsSubPanel {...SubPanelProps} />
      <Divider />
      {AnalysisSummary}
    </SlidePanel>
  );
}
export default ViewOptionsAnalysisPanel;
