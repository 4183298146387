import { SectionContainer } from '@lunit/scope-components/Analysis/SlidePanel/common';
import { ViewOptionsHeader } from '../styled';

interface Props {
  children: React.ReactNode
}

function AnalysisSummaryPanel({ children }:Props) {
  return (
    <SectionContainer>
      <ViewOptionsHeader>Analysis Summary</ViewOptionsHeader>
      {children}
    </SectionContainer>
  );
}
export default AnalysisSummaryPanel;
