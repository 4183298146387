import { SectionListItem, Typography } from '../../styled';
import { BaseOptionProps } from '../../models';
import ViewOptionsToggleButton from './styled';
import InfoTooltipIcon from './OptionComponents/InfoTooltipIcon';

function BaseOption({
  visible,
  on,
  toggle,
  content,
  infoTooltip,
  disableToggleButton = false,
}: BaseOptionProps & {
  content: string
}) {
  return (
    <SectionListItem
      sx={{
        opacity: visible ? 1 : 0.5,
        height: 28,
        flex: '0 0 28px',
      }}
    >
      <Typography variant="body5">{content}</Typography>
      {infoTooltip && <InfoTooltipIcon tooltipContent={infoTooltip} />}
      {!disableToggleButton && (
        <ViewOptionsToggleButton
          toggle={toggle}
          on={on}
        />
      )}
    </SectionListItem>
  );
}

export default BaseOption;
