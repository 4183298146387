import {
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Paper,
  Backdrop,
  Popper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { CheckIcon, DeleteIcon, MoreIcon } from '@lunit/scope-icons';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import usePopper from './hooks/usePopper';

export interface EditableSelectItemProps extends MenuItemProps {
  checked?: boolean
  isOptionEditable?: boolean
  onEditOption?: (newOption: { value: any; label: string }) => void
  onDeleteOption?: (value: any) => void
  getOptionIcon?: (value: any) => React.ReactNode
}

interface EditOptionFormProps {
  defaultLabel: string
  onSubmit?: (data: { label: string }) => void
  onDelete?: () => void
}

const EditOptionFormContainer = styled('form')({
  display: 'flex',
  flexDirection: 'column',
});

const FormRow = styled('div')({
  minHeight: 36,
  width: '100%',
  padding: '0 8px',
  display: 'flex',
  alignItems: 'center',
});

function EditOptionForm({
  defaultLabel,
  onSubmit,
  onDelete,
}: EditOptionFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: { label: defaultLabel },
  });
  return (
    <EditOptionFormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <TextField
          required
          autoFocus
          onFocus={(event) => {
            event.target.select();
          }}
          name="label"
          variant="outlined"
          placeholder="User Read Name"
          defaultValue={defaultLabel}
          helperText={errors.label && errors.label.message}
          error={!!errors.label}
          {...register('label', { required: 'This field is required.' })}
          onKeyDown={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        />
      </FormRow>
      <MenuItem onClick={onDelete}>
        <ListItemIcon sx={{ marginRight: 1 }}>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </EditOptionFormContainer>
  );
}

function EditableSelectItem({
  checked,
  children,
  onEditOption,
  onDeleteOption,
  isOptionEditable,
  className: rawClassName,
  getOptionIcon,
  ...props
}: EditableSelectItemProps): ReturnType<typeof MenuItem> {
  const className: string = !checked
    ? rawClassName
    : clsx(rawClassName, 'Mui-selected');
  const { open, anchorEl, onClose, onOpen } = usePopper();

  return (
    <>
      <MenuItem
        {...props}
        className={className}
        disableRipple={open}
      >
        <ListItemIcon
          sx={{
            marginRight: 1,
            opacity: 0,
            '.Mui-selected &': { opacity: 1 },
          }}
        >
          <CheckIcon />
        </ListItemIcon>
        {getOptionIcon && getOptionIcon(props.value)}
        <ListItemText>{children}</ListItemText>
        {isOptionEditable && (
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              padding: 0.5,
            }}
            onClick={(event) => {
              event.stopPropagation();
              if (!open) {
                onOpen(event);
              } else {
                onClose();
              }
            }}
          >
            <MoreIcon />
          </IconButton>
        )}
      </MenuItem>
      {isOptionEditable && (
        <Backdrop
          sx={{
            backgroundColor: 'transparent',
            zIndex: (theme) => theme.zIndex.modal + 1,
          }}
          open={open}
          onClick={onClose}
        >
          <Popper
            open={open}
            style={{ zIndex: 1300 }}
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            anchorEl={anchorEl}
            placement="bottom-end"
            modifiers={[
              {
                name: 'offset',
                enabled: true,
                options: { offset: [0, 4] },
              },
            ]}
          >
            <Paper
              elevation={1}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: (theme) => theme.palette.darkGrey[70],
                width: 280,
                minHeight: 96,
                paddingTop: 1.5,
                paddingBottom: 1.5,
              }}
            >
              <EditOptionForm
                defaultLabel={String(children)}
                onSubmit={({ label }) => {
                  if (onEditOption) onEditOption({ value: props.value, label });
                }}
                onDelete={() => {
                  if (onDeleteOption) onDeleteOption(props.value);
                }}
              />
            </Paper>
          </Popper>
        </Backdrop>
      )}
    </>
  );
}

export default EditableSelectItem;
