import { CloseIcon } from '@lunit/scope-icons';

import {
  Dialog,
  DialogTitle,
  IconButton,
  List,
  Tooltip,
  Typography,
} from '@mui/material';

import { ShortcutListContainer } from '../ToolbarMUI';
import ShortcutListItem from './ShortcutListItem';

import { PAN_ITEMS, ZOOM_ITEMS } from './shortcuts';

interface Props {
  isOpen: boolean
  onClose: () => void
}
function KeyboardShortcutsModal({ isOpen, onClose }: Props) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        elevation: 1,
        sx: {
          width: '400px',
          height: '720px',
          position: 'fixed',
          top: '57px',
          right: '152px',
          m: 0,
        },
      }}
    >
      <DialogTitle
        id="modal-form-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subtitle3">Keyboard Shortcuts</Typography>
        <Tooltip title="Close">
          <IconButton
            size="small"
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <ShortcutListContainer>
        <List sx={{ padding: 0 }}>
          {PAN_ITEMS.map((item) => (
            <ShortcutListItem
              key={item.id}
              title={item.title}
              icon={item.icon}
              shortcut={item.shortcut}
            />
          ))}
          {ZOOM_ITEMS.map((item) => (
            <ShortcutListItem
              key={item.id}
              title={item.title}
              icon={item.icon}
              shortcut={item.shortcut}
            />
          ))}
        </List>
      </ShortcutListContainer>
    </Dialog>
  );
}
export default KeyboardShortcutsModal;
