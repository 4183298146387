import { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { PageRoot, LoadingIndicator } from '@lunit/scope-components';

import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import SignUp from './pages/auth/SignUp';
import VerifyEmail from './pages/auth/VerifyEmail';
import TermsOfUse from './pages/auth/TermsOfUse';
import PersonalInfoTerms from './pages/auth/PersonalInfoTerms';
import NewsLetterTerms from './pages/auth/NewsLetterTerms';
import Home from './pages/home/Home';
import { HER2, IO, PDL1TPS, TumorPurity, CPSBreast, CPSBladder } from './pages/projects';

import withAuthGuard from './hoc/withAuthGuard';
import withMetadataGuard from './hoc/withMetadataGuard';
import withProjectConfig from './hoc/withProjectConfig';

import SlideList from './components/common/SlideList';
import ProfileAvatarButton from './components/common/Toolbar/ProfileAvatar/ProfileAvatarButton';
import ProductLabelButton from './components/common/Toolbar/ProductLabels/ProductLabelButton';
import QuickAppSelector from './components/common/Toolbar/QuickAppSelector';

import {
  useBiomarkerMetaData,
  useBiomarkers,
} from './hooks/useBiomarkerQueries';
import useOnlineStatus from './hooks/useOnlineStatus';
import useSnackbar from './hooks/useSnackbar';

import { getProductsFromAPIProducts } from './pages/home/ProductList/models';

import './App.css';

const PDL1Page = withMetadataGuard(withProjectConfig(PDL1TPS, 'pdl1-tps'));
const CPSBreastPage = withMetadataGuard(withProjectConfig(CPSBreast, 'cps-breast'));
const CPSBladderPage = withMetadataGuard(withProjectConfig(CPSBladder, 'cps-bladder'));
const IOPage = withMetadataGuard(withProjectConfig(IO, 'io'));
const HER2Page = withMetadataGuard(withProjectConfig(HER2, 'her2'));
const TPPage = withMetadataGuard(withProjectConfig(TumorPurity, 'tp'));

function Dashboard() {
  const { data: biomarkers, isLoading: isBiomarkersLoading } = useBiomarkers();
  const { enqueueSnackbar } = useSnackbar();
  const match = useMatch('/:productPath/*');
  const navigate = useNavigate();
  let productPath = '';
  if (match && biomarkers) {
    const path = match.params.productPath;
    productPath = getProductsFromAPIProducts(biomarkers.products).find(
      (product) => product.path === path,
    )?.path || '';
    if (!productPath) {
      navigate('/', { replace: true });
    }
  }
  const {
    data: biomarkerMetadata,
    isLoading: isMetadataLoading,
    isError,
    error,
    refetch,
  } = useBiomarkerMetaData(productPath);

  useEffect(
    () => {
      if (isError && error) {
        enqueueSnackbar(
          `Failed to fetch demo data: ${
            (error as Error).message
          }. Try refreshing.`,
          { variant: 'error' },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isError, error],
  );
  return (
    <PageRoot
      headerDrawerButtonOnLeft
      homeLink={(
        <QuickAppSelector />
      )}
      drawerExtraContent={(
        <Routes>
          <Route
            index
            element={<SlideList loading />}
          />
          <Route
            path=":productPath/*"
            element={(
              <SlideList
                base={match ? match.params.productPath : ''}
                loading={isBiomarkersLoading || isMetadataLoading}
                results={biomarkerMetadata?.results}
                refetchResults={refetch}
              />
            )}
          />
        </Routes>
      )}
      rightContainer={(
        <>
          <ProfileAvatarButton />
          <ProductLabelButton />
        </>
      )}
    >
      <Routes>
        <Route
          index
          element={<Home products={biomarkers?.products ?? []} />}
        />
        <Route
          path="io/*"
          element={<IOPage metadata={biomarkerMetadata} />}
        />
        <Route
          path="pdl1_tps/*"
          element={<PDL1Page metadata={biomarkerMetadata} />}
        />
        <Route
          path="her2/*"
          element={<HER2Page metadata={biomarkerMetadata} />}
        />
        <Route
          path="tumor_purity/*"
          element={<TPPage metadata={biomarkerMetadata} />}
        />
        <Route
          path="pdl1_cps_breast/*"
          element={<CPSBreastPage metadata={biomarkerMetadata} />}
        />
        <Route
          path="pdl1_cps_bladder/*"
          element={<CPSBladderPage metadata={biomarkerMetadata} />}
        />
      </Routes>
      {(isBiomarkersLoading || isMetadataLoading) && (
        <LoadingIndicator
          message="Loading preview data..."
          styles={{ zIndex: 2000 }}
        />
      )}
    </PageRoot>
  );
}

const GuardedDashboard = withAuthGuard(Dashboard);

function App() {
  const isOnline = useOnlineStatus();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`
    ███████╗ ██████╗ ██████╗ ██████╗ ███████╗    ██████╗ ███████╗███╗   ███╗ ██████╗ 
    ██╔════╝██╔════╝██╔═══██╗██╔══██╗██╔════╝    ██╔══██╗██╔════╝████╗ ████║██╔═══██╗
    ███████╗██║     ██║   ██║██████╔╝█████╗      ██║  ██║█████╗  ██╔████╔██║██║   ██║
    ╚════██║██║     ██║   ██║██╔═══╝ ██╔══╝      ██║  ██║██╔══╝  ██║╚██╔╝██║██║   ██║
    ███████║╚██████╗╚██████╔╝██║     ███████╗    ██████╔╝███████╗██║ ╚═╝ ██║╚██████╔╝
    ╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚══════╝    ╚═════╝ ╚══════╝╚═╝     ╚═╝ ╚═════╝ 
    v${process.env.REACT_APP_COMPONENT_VERSION}
    `);
  }, []);

  useEffect(() => {
    if (!isOnline) {
      enqueueSnackbar('Connection unavailable', {
        variant: 'error',
        preventDuplicate: true,
        persist: true,
      });
    } else closeSnackbar();
  }, [enqueueSnackbar, closeSnackbar, isOnline]);

  return (
    <SkeletonTheme
      baseColor="#626264"
      highlightColor="#7F7F81"
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="login"
            element={<Login />}
          />
          <Route
            path="forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            path="reset-password/:key"
            element={<ResetPassword />}
          />
          <Route
            path="signup"
            element={<VerifyEmail />}
          />
          <Route
            path="signup/:key"
            element={<SignUp />}
          />
          <Route
            path="service"
            element={<TermsOfUse />}
          />
          <Route
            path="personal-info"
            element={<PersonalInfoTerms />}
          />
          <Route
            path="newsletter"
            element={<NewsLetterTerms />}
          />
          <Route
            path="/*"
            element={<GuardedDashboard />}
          />
        </Routes>
      </BrowserRouter>
    </SkeletonTheme>
  );
}

export default App;

// http://localhost:3000/signup/bLgyDYBdE4KrGfAaLlQp9BpioVynEBEt3bGJqzSN?email=taiwon_scope_test%40yopmail.com&type=SIGNUP
