import { FormControl, FormHelperText, Grid, MenuItem, Select } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import countryList from 'country-list';
import { ArrowDownIcon } from '@lunit/scope-icons';

const COUNTRIES = countryList.getNames().sort();

interface Props {
  control: Control<any, any>
  errors:any
}

function Countries({ control, errors }:Props) {
  return (
    <Grid
      item
      xs={12}
    >
      <Controller
        name="country"
        control={control}
        rules={{ required: 'This field is required.' }}
        defaultValue=""
        render={({ field: { onChange, value, ref } }) => (
          <FormControl sx={{ width: '100%' }}>
            <Select
              labelId="country-select-label"
              sx={{
                width: '100%',
                '& .MuiSelect-icon': { color: (theme) => theme.palette.darkGrey[5] },
              }}
              inputRef={ref}
              id="country-select"
              value={value}
              displayEmpty
              renderValue={(val) => {
                if (!val) {
                  return (
                    <span style={{ color: '#737377' }}>
                      Select your country
                    </span>
                  );
                }
                return val;
              }}
              onChange={onChange}
              name="country"
              MenuProps={{
                sx: { maxHeight: 300 },
                anchorOrigin: {
                  horizontal: 'left',
                  vertical: 'bottom',
                },
                transformOrigin: {
                  horizontal: 'left',
                  vertical: 'top',
                },
              }}
              IconComponent={ArrowDownIcon}
              error={!!errors.country}
            >
              {/* <MenuItem disabled>Select your country</MenuItem> */}
              {COUNTRIES.map((country) => (
                <MenuItem
                  key={country}
                  value={country}
                >
                  {country}
                </MenuItem>
              ))}
            </Select>
            {errors.country && (
            <FormHelperText
              sx={{ color: (theme) => theme.palette.error.main }}
            >
              {errors.country.message}
            </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Grid>
  );
}
export default Countries;
