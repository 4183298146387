import * as React from 'react'

const SvgMenuOpen = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5a.5.5 0 0 0 0 1h12a.5.5 0 0 0 0-1H2Zm0 9a.5.5 0 0 0 0 1h12a.5.5 0 0 0 0-1H2Zm-.5-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H2a.5.5 0 0 1-.5-.5Zm16.146 3.354a.5.5 0 0 0 .708-.708L15.707 10l2.647-2.646a.5.5 0 0 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgMenuOpen
