import { styled } from '@mui/material';

export const Bar = styled('div')({
  height: 4,
  borderRadius: 2,
  flexGrow: 0,
  flexShrink: 0,
});

export const BarContainer = styled('div')({
  height: 4,
  position: 'relative',
  display: 'flex',
});

export const BarListItem = styled('div')({
  width: '100%',
  padding: '9px 20px 0',
  height: 36,
  position: 'relative',
});

export const BarLabel = styled('span')(({ theme }) => ({
  position: 'absolute',
  textAlign: 'center',
  fontWeight: 500,
  fontSize: '8px',
  lineHeight: '12px',
  color: theme.palette.darkGrey[30],
  opacity: 0.9,
  transform: 'translateX(-50%)',
  '&:first-child': { transform: 'translateX(0%)' },
  '&:last-child': { transform: 'translateX(-100%)' },
}));

export const BarTextContainer = styled('div')({
  position: 'relative',
  marginTop: 2,
});

export const ScoreDot = styled('div')(({ theme }) => ({
  width: 7,
  height: 7,
  borderRadius: 3.5,
  border: `1.5px solid ${theme.palette.darkGrey[90]}`,
  position: 'absolute',
  top: -7.5,
  backgroundColor: theme.palette.darkGrey[0],
  transform: 'translateX(-50%)',
  '&[data-value="0"]': { transform: 'translateX(0%)' },
  '&[data-value="98"]': { transform: 'translateX(-64%)' },
  '&[data-value="99"]': { transform: 'translateX(-82%)' },
  '&[data-value="100"]': { transform: 'translateX(-100%)' },
}));

export const ScoreText = styled('span')(({ theme }) => ({
  position: 'absolute',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '10px',
  lineHeight: '12px',
  color: '#fff',
  backgroundColor: theme.palette.darkGrey[85],
  transform: 'translateX(-50%)',
  '&[data-value="0"]': { transform: 'translateX(0%)' },
  '&[data-value="98"]': { transform: 'translateX(-64%)' },
  '&[data-value="99"]': { transform: 'translateX(-82%)' },
  '&[data-value="100"]': { transform: 'translateX(-100%)' },
  top: -19,
}));

export const BarScore = styled('div')({ position: 'relative' });
