import { SectionListItem } from '../../styled';
import { BarListItem, BarTextContainer, BarContainer, CenteredBox } from '../styled';

interface Props {
  title:JSX.Element
  bars:React.ReactNode[]
  barLabels:React.ReactNode[]
}

function BarChartContainer({
  title,
  bars = [],
  barLabels = [],
}:Props) {
  return (
    <>
      <SectionListItem>
        <CenteredBox>
          {title}
        </CenteredBox>
      </SectionListItem>
      <BarListItem>
        <BarContainer sx={{ height: 10, backgroundColor: '#3E3E40' }}>
          {bars}
        </BarContainer>
        <BarTextContainer>
          {barLabels}
        </BarTextContainer>
      </BarListItem>
    </>
  );
}
export default BarChartContainer;
