import OpenSeadragon from 'openseadragon';
import { DEFAULT_MAX_ZOOM } from 'src/const';
import { CoordData, MaskImage } from 'src/workers/AnalysisResultWorker/common/types';
import { ResultWorkerPreparedEventData } from 'src/workers/AnalysisResultWorker/types';
import { checkAndSetGlobalAlpha, hexToRgba } from './utils';

export const drawGridTiles = (
  context: CanvasRenderingContext2D | null,
  viewer: OpenSeadragon.Viewer,
  gridData: ResultWorkerPreparedEventData['gridData'],
) => {
  if (!gridData || !context) return;
  const bounds = viewer.viewport.viewportToImageRectangle(
    viewer.viewport.getBounds(true),
  );
  gridData.forEach((grid) => {
    const {
      indexedCoords,
      color,
      gridPixelSizeX,
      gridPixelSizeY,
      gridResults,
    } = grid;
    if (indexedCoords) {
      indexedCoords
        .range(
          bounds.x - gridPixelSizeX,
          bounds.y - gridPixelSizeY,
          bounds.x + bounds.width,
          bounds.y + bounds.height,
        )
        .forEach((coordId) => {
          const data = gridResults[coordId];
          const { r, g, b } = hexToRgba(color);
          const { minX, minY } = data;
          context.save();
          context.fillStyle = `rgba(${r}, ${g}, ${b}, 0.4)`;
          context.fillRect(minX, minY, gridPixelSizeX, gridPixelSizeY);
          context.restore();
        });
    }
  });
};
export const drawTissueImage = (
  ctx: CanvasRenderingContext2D | null,
  alpha: number,
  maskImage: MaskImage,
) => {
  if (!ctx) return;
  const { minX, minY, downscale, image } = maskImage;
  ctx.save();
  ctx.imageSmoothingEnabled = false;
  ctx.globalAlpha = alpha;
  ctx.drawImage(
    image,
    minX!,
    minY!,
    image.width * downscale!,
    image.height * downscale!,
  );
  ctx.restore();
};

export function drawCellToCanvas(
  context: CanvasRenderingContext2D,
  coords:CoordData,
  coordId:number,
  pointSize:number,
) {
  const { x, y } = coords.coordinates[coordId];
  context.beginPath();
  context.arc(x, y, pointSize, 0, 2 * Math.PI);
  context.fillStyle = coords.color;
  context.fill();
  context.closePath();
}

export function handleCellDrawing(
  viewer:OpenSeadragon.Viewer,
  context: CanvasRenderingContext2D,
  cellData: CoordData[],
  zoom: number,
) {
  const bounds = viewer.viewport.viewportToImageRectangle(
    viewer.viewport.getBounds(true),
  );
  const radius = 6 * Math.sqrt(zoom / DEFAULT_MAX_ZOOM);
  const sizeRect = new OpenSeadragon.Rect(0, 0, radius, radius);
  const pointSize = viewer.viewport.viewportToImageRectangle(
    viewer.viewport.viewerElementToViewportRectangle(sizeRect),
  ).width;
  context.save();
  checkAndSetGlobalAlpha(context, 1);
  cellData.forEach((coords) => {
    const { indexedCoords } = coords;
    if (indexedCoords) {
      indexedCoords
        .range(
          bounds.x - pointSize * 2,
          bounds.y - pointSize * 2,
          bounds.x + bounds.width + pointSize * 2,
          bounds.y + bounds.height + pointSize * 2,
        )
        .forEach((coordId) => {
          drawCellToCanvas(context, coords, coordId, pointSize);
        });
    }
  });
}
