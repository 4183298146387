import { useState, FormEvent } from 'react';
import AuthTemplate from '@lunit/scope-components/Auth/AuthTemplate';
import AuthHeading from '@lunit/scope-components/Auth/AuthHeading';
import AuthTextField from '@lunit/scope-components/Auth/AuthTextField';
import AuthActionButton from '@lunit/scope-components/Auth/AuthActionButton';
import AuthNav from '@lunit/scope-components/Auth/AuthNav';
import AuthFooter from '@lunit/scope-components/Auth/AuthFooter';
import AuthAlert from '@lunit/scope-components/Auth/AuthAlert';
import { Box, Link } from '@mui/material';
import { useMutation } from 'react-query';
import { APIError } from '@lunit/scope-components/api/models';
import { useSetRecoilState } from 'recoil';
import * as authAPI from '../../api/auth/queries';
import { UserToken } from '../../api/auth/models';
import { authAtom } from '../../state/auth';
import { setTokensAndRedirectToMain, validateEmail } from './utils';

function Login() {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const setAuth = useSetRecoilState(authAtom);

  const actionDisabled = !email.trim() || !password.trim();
  const hasError = !!errorMessage.trim();

  const login = useMutation(authAPI.login, {
    onSuccess: (data: UserToken) => {
      setAuth(data);
      setTokensAndRedirectToMain(data);
    },
    onError: (error: APIError) => {
      if (String(error.details).includes('activate')) {
        setErrorMessage(
          'Customer is not yet activated. Please contact your administrator or email cs-scope-io@lunit.io',
        );
      } else {
        setErrorMessage(
          String(error.status).substring(0, 1) === '4'
            ? 'The email or password you entered is incorrect.'
            : 'Something went wrong while logging in. Please try again.',
        );
      }
    },
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateEmail(email)) {
      login.mutate({
        email,
        password,
      });
    } else setErrorMessage('The email you entered is invalid.');
  };

  const handleEmailEntry = (value:string) => {
    if (value !== value.toLowerCase()) {
      setErrorMessage('The email address should be lowercase');
    } else {
      setErrorMessage('');
    }
    setEmail(value.toLowerCase());
  };

  return (
    <AuthTemplate logo="/logo-scope-r.svg">
      <form
        onSubmit={handleSubmit}
        noValidate
      >
        <AuthHeading>Sign in</AuthHeading>
        <AuthNav sx={{ marginTop: 0, marginBottom: 3 }}>
          <div />
          <Link href="signup">Don&apos;t have an account?</Link>
        </AuthNav>
        <AuthTextField
          id="email"
          type="email"
          aria-label="Email"
          placeholder="Email*"
          value={email}
          onChange={(e) => handleEmailEntry(e.target.value)}
          autoComplete="username"
        />
        <AuthTextField
          id="password"
          type="password"
          aria-label="Password"
          placeholder="Password*"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
        />
        <AuthActionButton
          size="large"
          disabled={actionDisabled}
        >
          Log in
        </AuthActionButton>
        {hasError && (
          <AuthAlert
            sx={{ marginTop: 3 }}
            severity="error"
          >
            {errorMessage}
          </AuthAlert>
        )}
        <AuthNav>
          <Link href="forgot-password">Forgot your password?</Link>
          <Link
            href="mailto:cs-scope@lunit.io"
            target="_blank"
            sx={{ textAlign: 'right' }}
          >
            cs-scope@lunit.io
          </Link>
        </AuthNav>
        <AuthFooter description="Lunit SCOPE" />
        <AuthNav>
          <Box>
            <Link
              href="/service"
              target="_blank"
            >
              Terms of use&nbsp;&nbsp;
            </Link>
            <Link
              href="https://www.lunit.io/en/doc/privacy-policy"
              target="_blank"
            >
              |&nbsp;&nbsp;Privacy Policy
            </Link>
          </Box>
          { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
          <Link sx={{ color: '#AFAFB1', textAlign: 'right' }}>
            Lunit Inc. All rights reserved
          </Link>
        </AuthNav>
      </form>
    </AuthTemplate>
  );
}

export default Login;
