import { Button, ButtonProps } from '@mui/material';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { AuthPropKeys } from './types';

interface AuthActionButtonProps
  extends Pick<ButtonProps, AuthPropKeys | 'color' | 'disabled' | 'size'> {
  // https://github.com/storybookjs/storybook/issues/12185
  type?: ButtonProps['type']
}

const AuthActionButton = forwardRef<HTMLButtonElement, AuthActionButtonProps>(
  ({ color = 'primary', disabled = false, type = 'submit', ...rest }, ref) => (
    <Button
      ref={ref}
      {...rest}
      className={clsx('AuthActionButton', rest.className)}
      sx={{
        display: 'flex',
        marginTop: 3,
        width: '100%',
        ...rest.sx,
      }}
      type={type}
      variant="contained"
      color={color}
      disabled={disabled}
    />
  ),
);

AuthActionButton.displayName = 'AuthActionButton';

export default AuthActionButton;
