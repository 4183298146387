import qs from 'qs';
import wretch, { ResponseChain, WretcherError } from 'wretch';
import { camelize, decamelize } from '@ridi/object-case-converter';
import env, { API_TARGETS, DEFAULT_API_TARGET } from '../../env/index';
import accessTokenManager from './accessTokenManager';
import { removeTokensAndRedirectToLogin } from '../../pages/auth/utils';
import { UserToken } from '../auth/models';

wretch().errorType('json');

export enum ErrorMessage {
  AuthError = 'AUTH_ERROR',
  BadRequest = 'BAD_REQUEST',
  ServerError = 'SERVER_ERROR',
  NotFoundError = 'NOT_FOUND',
  TimeoutError = 'Request timed out',
  FetchError = 'Failed to fetch',
  ServerUnavailable = 'Server Unavailable',
}

export enum FetchMethod {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Delete = 'delete',
  Patch = 'patch',
}

interface RequestParamType {
  method?: FetchMethod
  path: string
  getText?: boolean
  skipJson?: boolean
  skipAuth?: boolean // Can only be skipped on getting JWT token
  payload?: unknown
}

export class APIError extends Error {
  status: number;

  details: string = '';

  timestamp: string = '';

  code: number = 0;

  constructor(type: ErrorMessage, error: WretcherError) {
    super(type);
    this.status = error.status;
    if (error.json) {
      this.details = error.json.details;
      this.timestamp = error.json.timestamp;
      this.code = error.json.code;
    }
  }
}

let currentTarget = DEFAULT_API_TARGET;
export const setAPITarget = (target: API_TARGETS) => {
  currentTarget = target;
};

let isRenewingCredentials = false;

const renewToken = async (currentToken: UserToken) => {
  try {
    const renewedToken: UserToken = await wretch(
      `${env.API_URLS[currentTarget]}/token`,
    )
      .post(decamelize(currentToken, { recursive: true }))
      .json((response) => camelize(response, { recursive: true }));
    accessTokenManager.setAccessToken(renewedToken.accessToken);
    accessTokenManager.setRefreshToken(renewedToken.refreshToken);
    document.dispatchEvent(new Event('credentials-renewed'));
  } catch (error) {
    removeTokensAndRedirectToLogin();
    throw error;
  } finally {
    isRenewingCredentials = false;
  }
};

const renewCredentials = async () => {
  const currentToken = {
    tokenType: 'Bearer',
    accessToken: accessTokenManager.getAccessToken() as string,
    refreshToken: accessTokenManager.getRefreshToken() as string,
  };
  isRenewingCredentials = true;
  await renewToken(currentToken);
};

const errorHandlingWrapper = (
  wrapper: ResponseChain,
  options: {
    skipJson: boolean | undefined
    getText: boolean | undefined
    skipAuth: boolean | undefined
  },
) => wrapper
  .badRequest((error: WretcherError) => {
    throw new APIError(ErrorMessage.BadRequest, error);
  })
  .notFound((error: WretcherError) => {
    throw new APIError(ErrorMessage.NotFoundError, error);
  })
  .unauthorized(async (error: WretcherError, req: any) => {
    if (options.skipAuth) {
      throw new APIError(ErrorMessage.AuthError, error);
    }
    if (!isRenewingCredentials) {
      await renewCredentials();
      return req
        .auth(`Bearer ${accessTokenManager.getAccessToken()}`)
        .unauthorized((err: WretcherError) => {
          throw new APIError(ErrorMessage.AuthError, err);
        });
    }
    return new Promise((resolve, reject) => {
      const handler = () => {
        const request = req
          .auth(`Bearer ${accessTokenManager.getAccessToken()}`)
          .replay()
          .unauthorized((err: WretcherError) => {
            reject(new APIError(ErrorMessage.AuthError, err));
          });
        if (options.skipJson) request.res((res: any) => resolve(res.statusText));
        else if (options.getText) request.text((res: string) => resolve(res));
        else request.json((res: any) => resolve(res));
        document.removeEventListener('credentials-renewed', handler);
      };
      document.addEventListener('credentials-renewed', handler);
    });
  })
  .forbidden((error: WretcherError) => {
    throw new APIError(ErrorMessage.AuthError, error);
  })
  .internalError((error: WretcherError) => {
    throw new APIError(ErrorMessage.ServerError, error);
  })
  .timeout((error: WretcherError) => {
    throw new APIError(ErrorMessage.TimeoutError, error);
  })
  .error(503, (error: WretcherError) => {
    throw new APIError(ErrorMessage.ServerUnavailable, error);
  })
  .fetchError((error: WretcherError) => {
    throw new APIError(ErrorMessage.FetchError, error);
  });

export const request = <Response = any>({
  method = FetchMethod.Get,
  path,
  getText,
  skipJson,
  skipAuth,
  payload,
}: RequestParamType): Promise<Response> => {
  if (!skipAuth && !accessTokenManager.hasAccessTokenInStorage()) {
    throw new Error(ErrorMessage.AuthError);
  }
  const wrapper = errorHandlingWrapper(
    wretch(`${env.API_URLS[currentTarget]}/${path}`)
      .auth(skipAuth ? '' : `Bearer ${accessTokenManager.getAccessToken()}`)
      .json(decamelize(payload, { recursive: true }))[method](),
    { skipJson, getText, skipAuth },
  );
  if (getText) {
    return wrapper.text();
  }
  if (skipJson) {
    return wrapper.res((res: any) => res.statusText);
  }
  return wrapper.json((res: any) => camelize(res, { recursive: true, excludes: ['presigned_url_info'] }));
};

export const DEFAULT_TOKEN_EXP_SEC = 259200;

const qsStringfyDefaultOptions = {
  encode: true,
  indices: false,
};

export const queryString = (obj: any) => qs.stringify(obj, qsStringfyDefaultOptions);
