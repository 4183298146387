/* eslint-disable react/no-array-index-key */
import React from 'react';
import { UIElement } from 'src/api/biomarkers/models';
import { ConfiguredProject } from 'src/types';
import { Divider, TooltipContainer } from './styled';
import TooltipOption from './TooltipElements';

interface Props {
  uiElements : UIElement[]
  config:ConfiguredProject
}

function ViewOptionsTooltip({ uiElements, config }:Props) {
  return (
    <TooltipContainer>
      {uiElements.map((e, i) => (
        <React.Fragment key={`${config.projectName}-${e.type}-${i}`}>
          <TooltipOption
            element={e}
            config={config.elementConfig[i]}
          />
          {i !== uiElements.length - 1 && (
          <Divider
            sx={{ margin: '16px 0' }}
          />
          )}
        </React.Fragment>
      ))}
    </TooltipContainer>
  );
}
export default ViewOptionsTooltip;
