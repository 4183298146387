import { useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { map } from 'lodash';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import AuthTemplate from '@lunit/scope-components/Auth/AuthTemplate';
import AuthHeading from '@lunit/scope-components/Auth/AuthHeading';
import AuthActionButton from '@lunit/scope-components/Auth/AuthActionButton';
import AuthNav from '@lunit/scope-components/Auth/AuthNav';
import AuthAlert from '@lunit/scope-components/Auth/AuthAlert';
import {
  Link,
  Grid,
  AlertColor,
} from '@mui/material';
import { APIError } from '@lunit/scope-components/api/models';

import { useSignupInfoQuery } from 'src/hooks/useAuthQueries';
import useQueryParams from '@lunit/scope-components/hooks/useQueryParams';
import * as authAPI from '../../../api/auth/queries';
import { SurveyOption, UserToken } from '../../../api/auth/models';
import { setTokensAndRedirectToMain } from '../utils';
import PersonalDetails from './PersonalDetails';
import Countries from './Countries';
import Survey from './Survey';
import Consent from './Consent';

interface Alert {
  severity: AlertColor
  message: string
}

function SignUp() {
  const match = useParams();
  const queryParams = useQueryParams<{
    email: string
    type: string
  }>();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [showSurveyDetail, setShowSurveyDetail] = useState<{
    [question: string]: boolean
  }>({});
  const [validCaptcha, setValidCaptcha] = useState<boolean>(false);
  // const [consentsAllChecked, setConsentsAllChecked] = useState<boolean>(false);
  const { data: signupInfo } = useSignupInfoQuery();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { email: queryParams.email },
  });

  const verifyValidKey = useMutation(authAPI.verifyValidKey, {
    onError: () => {
      navigate('/signup?expired');
    },
  });

  const validateCaseSensitive = (value:string) => {
    if (value !== value.toLowerCase()) {
      setErrorMessage('The email address should be lowercase');
      setValue('email', value.toLowerCase());
    } else {
      setErrorMessage('');
    }
    return true;
  };

  useEffect(
    () => {
      if (
        match.key
        && queryParams.email
        && queryParams.type
        && !verifyValidKey.isLoading
        && !verifyValidKey.isSuccess
        && !verifyValidKey.isError
      ) {
        verifyValidKey.mutate({ key: match.key, keyType: queryParams.type });
      }
      if (!queryParams.email || !queryParams.type) {
        navigate('/signup');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      match.key,
      navigate,
      queryParams.email,
      queryParams.type,
      verifyValidKey.mutate,
      verifyValidKey.isLoading,
      verifyValidKey.isSuccess,
      verifyValidKey.isError,
    ],
  );

  const alert: Alert | null = useMemo(() => {
    if (errorMessage) {
      return {
        severity: 'error',
        message: errorMessage,
      };
    }
    if (successMessage) {
      return {
        severity: 'success',
        message: successMessage,
      };
    }
    return null;
  }, [errorMessage, successMessage]);

  const login = useMutation(authAPI.login, {
    onSuccess: (data: UserToken) => {
      setTokensAndRedirectToMain(data);
    },
  });

  const signUp = useMutation(authAPI.signUp, {
    onSuccess: () => {
      setSuccessMessage('Account created');
      const { password } = getValues();
      login.mutate({ email: queryParams.email, password });
    },
    onError: (error: APIError) => {
      setErrorMessage(`Something went wrong: ${error.details}`);
    },
  });

  const handleCheckSurvey = (option: SurveyOption, checked: boolean) => {
    let newSurvey = getValues()[`survey_${option.surveyQuestionId}`] ?? [];
    if (checked) {
      newSurvey.push(option);
    } else {
      newSurvey = newSurvey.filter(
        (opt: SurveyOption) => opt.surveyOptionId !== option.surveyOptionId,
      );
    }
    if (option.detailRequired) {
      setShowSurveyDetail((prev) => ({
        ...prev,
        [`${option.surveyQuestionId}_${option.surveyOptionId}`]: checked,
      }));
    }
    return newSurvey;
  };

  const onSubmit = (data: any) => {
    setErrorMessage('');
    signUp.mutate({
      email: queryParams.email,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
      country: data.country,
      job: data.job,
      organization: data.organization,
      survey:
        signupInfo?.survey.reduce<
        Array<{
          surveyQuestionId: number
          surveyOptionId: number
          details: string
        }>
        >((result, question) => {
          const newResult = [...result];
          const options: SurveyOption[] = data[`survey_${question.surveyQuestionId}`];
          options.forEach((option) => {
            newResult.push({
              surveyQuestionId: option.surveyQuestionId,
              surveyOptionId: option.surveyOptionId,
              details:
                data[`${option.surveyQuestionId}_${option.surveyOptionId}`],
            });
          });
          return newResult;
        }, []) ?? [],
      termsOfService: map(signupInfo?.serviceTerm, (tos) => ({
        serviceTermId: tos.serviceTermId,
        agree: data[`tos_${tos.serviceTermId}`],
      })),
      key: match.key,
    });
  };

  function onChangeRecaptcha(value: any) {
    // console.log('Captcha value:', value);
    setValidCaptcha(!!value);
  }

  return (
    <AuthTemplate
      wide
      headerFixed={false}
      logo="/logo-scope-r.svg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthHeading>Sign up</AuthHeading>
        <AuthNav sx={{ marginBottom: 3 }}>
          <Link
            href="/login"
            sx={{ textAlign: 'right' }}
          >
            Back to sign-in
          </Link>
        </AuthNav>

        <Grid
          container
          spacing={2}
          rowSpacing={0}
        >
          <PersonalDetails
            register={register}
            errors={errors}
            validateCaseSensitive={validateCaseSensitive}
            getValues={getValues}
          />
          <Countries
            control={control}
            errors={errors}
          />
          <Survey
            register={register}
            control={control}
            errors={errors}
            survey={signupInfo?.survey}
            handleCheckSurvey={handleCheckSurvey}
            showSurveyDetail={showSurveyDetail}
          />
          <Consent
            register={register}
            control={control}
            errors={errors}
            serviceTerm={signupInfo?.serviceTerm}
          />
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            {/* @ts-ignore */}
            <ReCAPTCHA
              sitekey="6LcrmtkfAAAAAOKGrr6NRCNhNwbIMcxia-wxxog6"
              theme="dark"
              // eslint-disable-next-line react/jsx-no-bind
              onChange={onChangeRecaptcha}
            />
          </Grid>
        </Grid>

        <AuthActionButton
          size="large"
          disabled={!validCaptcha}
        >
          Create
        </AuthActionButton>
        {alert && (
          <AuthAlert
            sx={{ marginTop: 3 }}
            severity={alert.severity}
          >
            {alert.message}
          </AuthAlert>
        )}
        <AuthNav>
          <Link
            href="mailto:cs-scope@lunit.io"
            target="_blank"
            sx={{ textAlign: 'right' }}
          >
            cs-scope@lunit.io
          </Link>
        </AuthNav>
      </form>
    </AuthTemplate>
  );
}

export default SignUp;
