import { ProjectConfigSpec, ProjectPath } from 'src/types';
import { cpsBladderConfig, CPSBladderControllerOrder } from './cps-bladder';
import { cpsBreastConfig, CPSBreastControllerOrder } from './cps-breast';
import { pdl1TPSConfig, PDL1TPSControllerOrder } from './pdl1-tps';
import { her2Config, HER2ControllerOrder } from './her2';
import { ioConfig, IOControllerOrder } from './io';
import { tpConfig, TPControllerOrder } from './tp';

export const config: { [K in ProjectPath]: ProjectConfigSpec } = {
  'pdl1-tps': pdl1TPSConfig,
  'cps-breast': cpsBreastConfig,
  'cps-bladder': cpsBladderConfig,
  her2: her2Config,
  io: ioConfig,
  tp: tpConfig,
};
export interface ControllerOrder {
  'pdl1-tps': PDL1TPSControllerOrder,
  'cps-breast':CPSBreastControllerOrder,
  'cps-bladder': CPSBladderControllerOrder,
  her2: HER2ControllerOrder
  io: IOControllerOrder
  tp: TPControllerOrder
}
