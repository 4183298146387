import { BasicChildState, DropdownChildState, SubChildState } from 'src/types';

export function isDropdownChild(
  element: BasicChildState | DropdownChildState | SubChildState,
): element is DropdownChildState {
  return (element as DropdownChildState).active !== undefined;
}

export function isSubChildStateChild(
  element: BasicChildState | DropdownChildState | SubChildState,
): element is SubChildState {
  return (element as SubChildState).childrenOn !== undefined;
}
