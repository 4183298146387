import { Link, Typography } from '@mui/material';
import { AuthTemplate } from '@lunit/scope-components';
import AuthDescription from '@lunit/scope-components/Auth/AuthDescription';
import AuthHeading from '@lunit/scope-components/Auth/AuthHeading';
import AuthNav from '@lunit/scope-components/Auth/AuthNav';
import { useSignupInfoQuery } from 'src/hooks/useAuthQueries';
import { getTermsBySeq, getTermsDescription, termsWithNewLine } from './utils';

function PersonalInfoTerms() {
  const { data: signupInfo } = useSignupInfoQuery();

  const targetTerms = getTermsBySeq(signupInfo?.serviceTerm, 2);
  const findTerms = targetTerms?.terms;
  const personalInfoTerms = termsWithNewLine(
    getTermsDescription(targetTerms).replaceAll('-', 'ㅤㅤ•'),
  );

  return (
    <AuthTemplate
      logo="/logo-scope-r.svg"
      wide
      headerFixed={false}
    >
      <AuthHeading>The Collection and Use of Personal Information</AuthHeading>
      <AuthNav>
        <Link
          href="/login"
          sx={{ textAlign: 'right' }}
        >
          Back to sign-in
        </Link>
      </AuthNav>
      <AuthDescription sx={{ marginTop: 5 }}>
        <Typography
          variant="body5"
          sx={{ mb: 3 }}
        >
          {findTerms}
        </Typography>
        <Typography variant="body5">{personalInfoTerms}</Typography>
      </AuthDescription>
      <AuthNav>
        <Link
          href="mailto:cs-scope@lunit.io"
          target="_blank"
          sx={{ textAlign: 'right' }}
        >
          cs-scope@lunit.io
        </Link>
      </AuthNav>
    </AuthTemplate>
  );
}

export default PersonalInfoTerms;
