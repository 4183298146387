import { useTheme } from '@mui/material';
import ReadOnlyViewer from '../SlideViewer/ReadOnlyViewer';
import ViewerScaleBar from '../SlideViewer/ViewerScaleBar';
import { ProjectViewerProps } from './types';

function ProjectViewer(
  { slideData, resultData, visualization, defaultZoom = 1 }:ProjectViewerProps,
) {
  const theme = useTheme();

  return (
    <ReadOnlyViewer
      slideData={slideData}
      mpp={resultData?.slideProperties?.mppX}
      defaultZoom={defaultZoom}
      canvasOverlay={<canvasOverlay onRedraw={visualization.onCanvasOverlayRedraw} />}
      tooltipOverlay={
        visualization.onTooltipOverlayRedraw
        && <tooltipOverlay onRedraw={visualization.onTooltipOverlayRedraw} />
    }
      webGLOverlay={
      visualization.onWebGLOverlayRedraw
      && <webGLOverlay onRedraw={visualization.onWebGLOverlayRedraw} />
}
      scaleBar={(
        <ViewerScaleBar
          mppX={resultData?.slideProperties?.mppX}
          theme={theme}
        />
    )}
    />
  );
}
export default ProjectViewer;
