import { CanvasOverlayProps } from '@lunit/osd-react-renderer';
import forEach from 'lodash/forEach';
import { useCallback } from 'react';
import { ControllerOrder } from 'src/const/projectConfigSpecs';
import { ResultWorkerPreparedEventData } from 'src/workers/AnalysisResultWorker/types';
import { useProjectState } from '../projectConfig';
import useViewerZoom from '../useViewerZoom';
import { drawTissueImage } from './func/drawing';
import { getZoomFromViewportZoom } from './func/utils';
import { Origin } from './webgl/types';
import useWebGLCellDrawing from './webgl/useWebGLCellDrawing';

const useTumorPurityVisualization = (
  resultData: ResultWorkerPreparedEventData | undefined,
) => {
  const viewOptions = useProjectState();
  const { handleCellDrawing } = useWebGLCellDrawing();

  const {
    zoomState: { zoom },
    physicalWidthPx,
  } = useViewerZoom();

  const [densityMap, cellDetection, tissueSegmentation] = viewOptions.controllers as ControllerOrder['tp'];

  // In TP, used for Tissue Segmentation, and Density Heatmap
  const onCanvasOverlayRedraw: CanvasOverlayProps['onRedraw'] = useCallback(
    (overlayEl: HTMLCanvasElement) => {
      if (!resultData) return;
      if (!viewOptions) return;
      if (!densityMap || !tissueSegmentation) return;
      if (
        !tissueSegmentation.childController
      ) {
        return;
      }
      if (!viewOptions.visible) return;

      const context = overlayEl.getContext('2d');
      if (!context) return;

      const subTissueSegmentation = tissueSegmentation.childController;
      if (
        tissueSegmentation.visible
        && resultData.maskImages
        && resultData.maskImageIndex
      ) {
        forEach(subTissueSegmentation, ({ visible }, index) => {
          if (
            visible
            && resultData.maskImages
            && resultData.maskImageIndex
            && resultData.maskImages[
              resultData.maskImageIndex[`Tissue Segmentation_${index}`]
            ]
          ) {
            // render tissue image
            drawTissueImage(
              context,
              0.3,
              resultData.maskImages[
                resultData.maskImageIndex[`Tissue Segmentation_${index}`]
              ],
            );
          }
        });
      }
      if (
        densityMap.visible
        && resultData.maskImages
        && resultData.maskImageIndex
        && resultData.maskImages[resultData.maskImageIndex['Tumor Density Map']]
      ) {
        // render density map
        drawTissueImage(
          context,
          0.3,
          resultData.maskImages[resultData.maskImageIndex['Tumor Density Map']],
        );
      }
    },
    [resultData, viewOptions, tissueSegmentation, densityMap],
  );

  // In TP, used for Cell Detection
  const onWebGLOverlayRedraw = useCallback((
    glCanvas: HTMLCanvasElement,
    normalCanvas: HTMLCanvasElement,
    viewer: OpenSeadragon.Viewer,
    origin:Origin,
  ) => {
    if (!resultData) return;
    if (!viewOptions.visible) return;
    if (!cellDetection) return;

    const microscopeWidth1x = physicalWidthPx * 10;
    const currentZoom = getZoomFromViewportZoom(
      viewer.viewport.getZoom(true),
      microscopeWidth1x,
      viewer,
    );

    const subCellDetection = cellDetection.childController;

    const gl = glCanvas.getContext('webgl2', {
      antialias: true,
      premultipliedAlpha: false,
    });
    const ctx = normalCanvas.getContext('2d');

    if (!gl) {
      console.log('failed to load webgl context');
      return;
    }
    if (!ctx) {
      console.log('failed to load 2d context');
      return;
    }

    if (
      cellDetection.visible
      && zoom >= 10
      && Math.abs(currentZoom - zoom) < 0.1
      && resultData.cellData
    ) {
      forEach(subCellDetection, ({ visible }, index) => {
        if (visible && resultData.cellData && resultData.cellData[index]) {
          handleCellDrawing(gl, ctx, viewer, resultData.cellData[index], zoom, origin);
        }
      });
    }
  }, [cellDetection, handleCellDrawing, physicalWidthPx, resultData, viewOptions.visible, zoom]);

  return { onCanvasOverlayRedraw, onWebGLOverlayRedraw };
};

export default useTumorPurityVisualization;
