import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ShortcutTextContainer } from '../ToolbarMUI';

interface ShortcutListItemProps {
  icon: React.ReactNode
  title: string
  shortcut: string | undefined
}

function ShortcutListItem({ icon, title, shortcut }: ShortcutListItemProps) {
  const shortcutPieces = shortcut?.split('+') || [''];
  return (
    <ListItem
      sx={{
        height: '44px',
        padding: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: (theme) => theme.palette.darkGrey[0],
        position: 'relative',
      }}
    >
      <ListItemIcon
        sx={{
          color: 'inherit',
          padding: '0 4px',
          marginRight: '1px',
          width: '48px',
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: 'body5',
          color: 'inherit',
        }}
      />
      <ShortcutTextContainer>
        {shortcutPieces.map((piece, index) => (
          <Typography
            variant="body6"
            color="darkGrey.30"
            // eslint-disable-next-line react/no-array-index-key
            key={`${piece}_${index}`}
          >
            {piece.replace('=', '+')}
          </Typography>
        ))}
      </ShortcutTextContainer>
    </ListItem>
  );
}

export default ShortcutListItem;
