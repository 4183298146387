import { CanvasOverlayProps } from '@lunit/osd-react-renderer';
import { forEach } from 'lodash';
import { useCallback } from 'react';
import { ControllerOrder } from 'src/const/projectConfigSpecs';
import { ResultWorkerPreparedEventData } from 'src/workers/AnalysisResultWorker/types';
import { useProjectState } from '../projectConfig';
import useViewerZoom from '../useViewerZoom';
import { drawGridTiles, drawTissueImage } from './func/drawing';
import { getZoomFromViewportZoom } from './func/utils';
import { Origin } from './webgl/types';
import useWebGLCellDrawing from './webgl/useWebGLCellDrawing';

const useCPSBladderVisualization = (
  resultData: ResultWorkerPreparedEventData | undefined,
) => {
  const viewOptions = useProjectState();
  const { handleCellDrawing } = useWebGLCellDrawing();
  const {
    zoomState: { zoom },
    physicalWidthPx,
  } = useViewerZoom();

  const [cpsMap, tumorArea, cellDetection] = viewOptions.controllers as ControllerOrder['cps-bladder'];

  const onWebGLOverlayRedraw = useCallback((
    glCanvas: HTMLCanvasElement,
    normalCanvas: HTMLCanvasElement,
    viewer: OpenSeadragon.Viewer,
    origin:Origin,
  ) => {
    if (!resultData) return;
    if (!viewOptions.visible) return;
    if (!cellDetection) return;

    const microscopeWidth1x = physicalWidthPx * 10;
    const currentZoom = getZoomFromViewportZoom(
      viewer.viewport.getZoom(true),
      microscopeWidth1x,
      viewer,
    );

    const gl = glCanvas.getContext('webgl2', {
      antialias: true,
      premultipliedAlpha: false,
    });
    const ctx = normalCanvas.getContext('2d');

    if (!gl) {
      console.log('failed to load webgl context');
      return;
    }
    if (!ctx) {
      console.log('failed to load 2d context');
      return;
    }

    if (
      cellDetection.visible
      && zoom >= 20
      && Math.abs(currentZoom - zoom) < 0.1
      && resultData.cellData
    ) {
      forEach(cellDetection.childController, (cell, index) => {
        if (cell.visible && resultData.cellData && resultData?.cellData[index]) {
          handleCellDrawing(gl, ctx, viewer, resultData.cellData[index], zoom, origin);
        }
      });
    }
  }, [cellDetection, handleCellDrawing, physicalWidthPx, resultData, viewOptions.visible, zoom]);

  const onCanvasOverlayRedraw: CanvasOverlayProps['onRedraw'] = useCallback(
    (overlayEl: HTMLCanvasElement, viewer: OpenSeadragon.Viewer) => {
      if (!resultData) return;
      if (!viewOptions.visible) return;
      if (!cpsMap || !tumorArea || !cellDetection) return;

      const context = overlayEl.getContext('2d');
      if (!context) return;
      if (
        tumorArea.visible
          && resultData.maskImages
          && resultData.maskImageIndex
          && resultData.maskImages[resultData.maskImageIndex['Tumor Area']]
      ) {
        drawTissueImage(
          context,
          0.3,
          resultData.maskImages[resultData.maskImageIndex['Tumor Area']],
        );
      }
      if (cpsMap.visible && resultData.gridData && zoom < 20) {
        drawGridTiles(context, viewer, resultData.gridData);
      }
    },
    [resultData, zoom, viewOptions.visible, cpsMap, tumorArea, cellDetection],
  );

  return { onCanvasOverlayRedraw, onWebGLOverlayRedraw };
};

export default useCPSBladderVisualization;
