import { HiddenIcon, ShownIcon } from '@lunit/scope-icons';
import { Box, IconButton, Typography } from '@mui/material';
import { MarkerLabelElement } from 'src/api/biomarkers/models';
import { SubOptionProps } from '../../../models';
import { MaskIndicator, PointIndicator, SubSectionListItem, SubViewOptionLeftContainer } from '../../../styled';
import TreeIndicator from './TreeIndicator';

function MarkerLabelOption({
  uiElement,
  disableToggleButton,
  isInTooltip,
  on,
  showToggleButton,
  toggle,
  visible,
  last,
}: SubOptionProps & {
  uiElement: MarkerLabelElement
}) {
  if (isInTooltip) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {uiElement.symbol === 'tissue' && (
          <MaskIndicator
            sx={{
              backgroundColor: uiElement.color,
              marginRight: 1,
            }}
          />
        )}
        {uiElement.symbol === 'cell' && (
          <PointIndicator
            sx={{
              backgroundColor: uiElement.color,
              marginRight: '10px',
            }}
          />
        )}
        <Typography
          variant="body5"
          color="#fff"
        >
          {uiElement.content}
        </Typography>
      </Box>
    );
  }

  return (
    <SubSectionListItem
      sx={!disableToggleButton ? { opacity: visible ? 1 : 0.5 } : {}}
    >
      <SubViewOptionLeftContainer>
        <TreeIndicator last={last} />
        {uiElement.symbol === 'tissue' && (
          <MaskIndicator
            sx={{
              backgroundColor: uiElement.color,
              marginLeft: '6px',
              marginRight: 1,
            }}
          />
        )}
        {uiElement.symbol === 'cell' && (
          <PointIndicator
            sx={{
              backgroundColor: uiElement.color,
              marginLeft: '8px',
              marginRight: '10px',
            }}
          />
        )}
        <Typography
          variant="body5"
          color="darkGrey.20"
        >
          {uiElement.content}
        </Typography>
      </SubViewOptionLeftContainer>
      {!disableToggleButton && (
        <IconButton
          sx={{
            opacity: showToggleButton || !on ? 1 : 0,
            padding: 0.5,
            ':hover': { backgroundColor: (theme) => theme.palette.darkGrey[80] },
          }}
          onClick={toggle}
        >
          {on ? <ShownIcon /> : <HiddenIcon />}
        </IconButton>
      )}
    </SubSectionListItem>
  );
}

export default MarkerLabelOption;
