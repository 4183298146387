import { ReactNode, useLayoutEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Drawer, { NavLinkObject } from './Drawer';
import Header from './Header';
import useToggleState from '../hooks/useToggleState';

interface ContainerProps {
  leftShifted: boolean
}

const Container = styled('div', { shouldForwardProp: (prop) => prop !== 'leftShifted' })<ContainerProps>(({ leftShifted, theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  paddingTop: 48,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(leftShifted && {
    width: `calc(100% - ${240}px)`,
    marginLeft: 240,
  }),
}));

const PAGE_ROOT_ID = 'page-root';

interface PageRootComponent {
  children: ReactNode
  homeLink?: ReactNode
  headerDrawerButtonOnLeft?: boolean
  routes?: NavLinkObject[]
  drawerExtraContent?: ReactNode
  rightContainer?: ReactNode
}

function PageRoot({
  children,
  routes = [],
  headerDrawerButtonOnLeft,
  homeLink,
  drawerExtraContent,
  rightContainer,
}: PageRootComponent) {
  const hasDrawer = !!drawerExtraContent;
  const [drawerOpen, toggleDrawerOpen] = useToggleState(hasDrawer);
  const [childrenToRender, setChildrenToRender] = useState<ReactNode>(null);

  useLayoutEffect(() => {
    setChildrenToRender(children);
  }, [children]);

  return (
    <Container
      id={PAGE_ROOT_ID}
      className={drawerOpen ? 'drawer-open' : 'drawer-close'}
      leftShifted={drawerOpen}
    >
      <Header
        homeLink={homeLink}
        drawerButtonOnLeft={headerDrawerButtonOnLeft}
        drawerOpen={drawerOpen}
        onDrawerToggle={toggleDrawerOpen}
        rightContainer={rightContainer}
        hasDrawer={!!drawerExtraContent}
      />
      { hasDrawer && (
        <Drawer
          drawerOpen={drawerOpen}
          routes={routes}
          extraContent={drawerExtraContent}
        />
      )}
      {childrenToRender}
    </Container>
  );
}

export default PageRoot;
