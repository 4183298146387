import map from 'lodash/map';
import { Biomarkers, Product as APIProduct } from 'src/api/biomarkers/models';

export interface Product extends APIProduct {
  id: string
  name: string
  description: string
  logo: string
  assay?: string
}

export const productIndex: {
  [path: string]: Omit<Product, keyof APIProduct>
} = {
  io: {
    id: 'io',
    logo: 'logo-scope-io-w.svg',
  },
  pdl1_tps: {
    id: 'pdl1',
    logo: 'logo-scope-pdl1-w.svg',
  },
  her2: {
    id: 'her2',
    logo: 'logo-scope-her2-w.svg',
  },
  tumor_purity: {
    id: 'tumor-purity',
    logo: 'logo-scope-tumor-purity-w.svg',
  },
};

export const getProductsFromAPIProducts = (
  products: Biomarkers['products'],
): Product[] => map(products, (product) => ({
  ...product,
  ...productIndex[product.path],
}));
