/* eslint-disable react/destructuring-assignment */
import wretch from 'wretch';
import { ErrorBoundary } from 'react-error-boundary';
import { useQuery, useQueryErrorResetBoundary } from 'react-query';
import Button from '@mui/material/Button';
import { ReactNode } from 'react';
import { LoadableImageContainer } from './styled';

interface LoadableImageProps {
  src: string
  fallback: string
  // eslint-disable-next-line react/no-unused-prop-types
  onRefetch?: () => void
  loadingSkeleton?: ReactNode
}

function LoadableImageContent({
  src,
  fallback,
  loadingSkeleton,
}: LoadableImageProps) {
  const { data, isLoading } = useQuery(
    src || fallback,
    () => wretch(src)
      .get()
      .blob((blob) => URL.createObjectURL(blob)),
    {
      suspense: false,
      refetchOnWindowFocus: false,
      cacheTime: 10 * 3600 * 1000,
    },
  );
  return isLoading ? (
    <>{loadingSkeleton}</>
  ) : (
    <LoadableImageContainer>
      <img
        src={data}
        alt={`${src}`}
      />
    </LoadableImageContainer>
  );
}

function LoadableImage(props: LoadableImageProps) {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <ErrorBoundary
      onReset={() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        props.onRefetch && props.onRefetch();
        reset();
      }}
      fallbackRender={({ resetErrorBoundary }) => (
        <LoadableImageContainer>
          <img
            src={props.fallback}
            alt=""
          />
          <Button
            sx={{ position: 'absolute' }}
            onClick={() => resetErrorBoundary()}
          >
            Retry
          </Button>
        </LoadableImageContainer>
      )}
      resetKeys={[props.src]}
    >
      <LoadableImageContent {...props} />
    </ErrorBoundary>
  );
}

export default LoadableImage;
