import max from 'lodash/max';
import min from 'lodash/min';
import OpenSeadragon from 'openseadragon';
import { useRecoilState } from 'recoil';

import { INITIAL_ZOOM } from 'src/const';
import { physWidthPxAtom, zoomAtom } from '../state';

// const microscopeWidth1x = physicalWidthPx * 10;

function useViewerZoom() {
  const [zoomState, setZoomState] = useRecoilState(zoomAtom);
  const [physicalWidthPx, setPhysicalWidthPx] = useRecoilState(physWidthPxAtom);

  function setZoomLevel(
    zoom: number,
    refPoint: OpenSeadragon.Point | null = null,
  ) {
    const clampedZoom = min([max([zoom, 0.1]), 160]) || INITIAL_ZOOM;
    setZoomState({ zoom: clampedZoom, refPoint });
  }

  // function handleOSDViewerZoom(event: any) {
  //   const {
  //     eventSource: viewer,
  //     zoom: viewportZoom,
  //     refPoint,
  //     immediately,
  //   } = event

  //   if (viewer === null || viewportZoom === null || immediately) return

  //   const viewportWidth = viewer.viewport.getContainerSize().x
  //   const scaleFactor = microscopeWidth1x / viewportWidth
  //   viewer.viewport.maxZoomLevel = DEFAULT_MAX_ZOOM * scaleFactor
  //   viewer.viewport.minZoomLevel = 0.1 * scaleFactor
  //   const targetZoom = viewportZoom / scaleFactor
  // }

  return {
    zoomState,
    setZoomLevel,
    physicalWidthPx,
    setPhysicalWidthPx,
  };
}

export default useViewerZoom;

// get DEFAULT_MAX_ZOOM
// microscopeWidth1x

/*

  const onZoom = useCallback(
    (event) => {
      const {
        eventSource: viewer,
        zoom: viewportZoom,
        refPoint,
        immediately,
      } = event;
      if (viewer == null || viewportZoom == null || immediately) {
        return;
      }
      const viewportWidth = viewer.viewport.getContainerSize().x;
      const scaleFactor = microscopeWidth1x / viewportWidth;
      viewer.viewport.maxZoomLevel = DEFAULT_MAX_ZOOM * scaleFactor;
      viewer.viewport.minZoomLevel = 0.1 * scaleFactor;
      const targetZoom = viewportZoom / scaleFactor;
      if (targetZoom < 10 && activeTool === ViewerToolType.Cell) {
        dispatchToolbar(selectTool(ViewerToolType.Pointer, "hand"));
      }
      dispatch(setZoom(targetZoom, refPoint));
    },
    [dispatch, dispatchToolbar, microscopeWidth1x, activeTool]
  );

*/
