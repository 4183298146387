import { LeftContainer } from '../../ToolbarMUI';

interface ToolbarLeftProps {
  children: React.ReactNode
}

function ToolbarLeft({ children }: ToolbarLeftProps) {
  return <LeftContainer>{children}</LeftContainer>;
}

export default ToolbarLeft;
