import * as React from 'react'

const SvgError = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="error_svg__stroke-inherit"
      d="M18.223 16.032a1 1 0 0 1-.884 1.468H2.66a1 1 0 0 1-.883-1.468L9.117 2.17a1 1 0 0 1 1.767 0l7.339 13.863Z"
      stroke="currentColor"
    />
    <path
      className="error_svg__stroke-inherit"
      d="M9.969 6.5v5"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <circle cx={10} cy={14.25} r={0.75} fill="currentColor" />
  </svg>
)

export default SvgError
