import { useRecoilState } from 'recoil';
import { cloneDeep } from 'lodash';

import {
  ProjectState,
  DropdownChildState,
  ProjectStateController,
  DropdownControllerProps,
  WithSubChildControllerProps,
  ControllerProps,
  SubChildState,
  BasicChildState,
} from 'src/types';

import { projectStateAtom } from 'src/state';
import { isDropdownChild, isSubChildStateChild } from './_func';

function useProjectState(): ProjectStateController {
  const [projectState, setProjectState] = useRecoilState(projectStateAtom);
  const { on, childState: prevChildState } = projectState;

  // // State Update Functions:

  // Calculate visibility:
  function isVisible():boolean {
    return projectState.on && projectState.childState.some((element) => element.on);
  }

  // Toggle View Options as a whole
  function masterToggle() {
    setProjectState((prevState) => {
      const newState = cloneDeep(prevState);
      newState.on = !newState.on;
      return newState;
    });
  }

  // Toggle a child's state
  function toggleChildState(index: number) {
    const newChildState = cloneDeep(prevChildState);
    newChildState[index].on = !newChildState[index].on;
    setProjectState({ on: projectState.on, childState: newChildState });
  }

  // Update the active property of a child that is a dropdown element
  function updateDropdownChildActiveState(index: number, newActive: string) {
    // assert type is correct
    if (!isDropdownChild(prevChildState[index])) return;

    // copy and modify relevant state
    const newDropdownState = { ...prevChildState[index] } as DropdownChildState;
    newDropdownState.active = newActive;

    // assemble new state array
    const newChildState = cloneDeep(prevChildState);
    newChildState[index] = newDropdownState;

    setProjectState({ on: projectState.on, childState: newChildState });
  }

  function toggleSubState(
    substate: boolean[],
    parentIndex: number,
    index: number,
  ) {
    // update state of subChild
    const newSubstate = [...substate];
    newSubstate[index] = !newSubstate[index];

    // update state of parent viewOption element
    const newState = cloneDeep(prevChildState) as SubChildState[];
    newState[parentIndex].childrenOn = newSubstate;

    setProjectState({ on, childState: newState });
  }

  // // Controller Generator Functions:
  function generateDropdownElementController(child:DropdownChildState, index:number)
    : DropdownControllerProps {
    return {
      on: child.on,
      visible: child.on && projectState.on,
      toggle: () => toggleChildState(index),
      active: child.active,
      onChange: (_: string) => updateDropdownChildActiveState(index, _),
    };
  }

  function generateSubChildrenElementController(child:SubChildState, index:number)
    : WithSubChildControllerProps {
    // subChildControllers toggle children of children, such as in HER2 Cell Detection
    function generateSubChildControllers(): ControllerProps[] {
      return child.childrenOn.map((_, i) => ({
        on: child.childrenOn[i],
        toggle: () => toggleSubState(child.childrenOn, index, i),
        visible: on && child.on && child.childrenOn[i],
      }));
    }

    return {
      on: child.on,
      visible: child.on && projectState.on,
      toggle: () => toggleChildState(index),
      childController: generateSubChildControllers(),
    };
  }

  function generateBasicController(child:BasicChildState, index:number)
    : ControllerProps {
    return {
      on: child.on,
      visible: child.on && projectState.on,
      toggle: () => toggleChildState(index),
    };
  }

  function generateControllers(
    childState: ProjectState['childState'],
  ): ProjectStateController['controllers'] {
    return childState.map((child, index) => {
      if (isDropdownChild(child)) {
        return generateDropdownElementController(child, index);
      }

      if (isSubChildStateChild(child)) {
        return generateSubChildrenElementController(child, index);
      }

      return generateBasicController(child, index);
    });
  }

  return {
    on,
    toggle: masterToggle,
    visible: isVisible(),
    controllers: generateControllers(projectState.childState),
  };
}
export default useProjectState;
