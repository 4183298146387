import {
  ControllerProps,
  ProjectConfigSpec,
  WithSubChildControllerProps,
} from 'src/types';

export const ioConfig: ProjectConfigSpec = {
  projectName: 'io',
  titleHasTooltip: true,
  hasSummarySelector: false,
  elementConfig: [
    {
      name: 'Immune Phenotype Map',
      elementType: 'label',
      childOptions: 'show-only',
    },
    {
      name: 'Histological Features',
      elementType: 'label',
      childOptions: 'show-with-state',
    },
  ],
};

export type IOControllerOrder = [ControllerProps, WithSubChildControllerProps];
