import { AnalysisFootnotes } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements';

function HER2Footnotes() {
  return (
    <AnalysisFootnotes>
      <span>&sup1; Invasive Cancer Area</span>
      <span>&sup2; Carcinoma In Situ</span>
    </AnalysisFootnotes>
  );
}
export default HER2Footnotes;
