// eslint-disable-next-line @typescript-eslint/naming-convention
export enum API_TARGETS {
  'production' = 'production',
  'qe' = 'qe',
  'dev' = 'dev',
  'demo' = 'demo',
  'optdev' = 'optdev',
}

export const DEFAULT_API_TARGET = (() => {
  switch (process.env.REACT_APP_VERCEL_ENV) {
    case 'production':
      return API_TARGETS.production;
    case 'qe':
      return API_TARGETS.qe;
    case 'dev':
      return API_TARGETS.dev;
    case 'demo':
      return API_TARGETS.demo;
    case 'optdev':
      return API_TARGETS.optdev;
    default:
      return API_TARGETS.dev;
  }
})();

const env = {
  isProduction: process.env.NODE_ENV === 'production',
  isOnPrem: process.env.REACT_APP_IS_ON_PREM === 'true',
  SENTRY_CONFIG_URL: process.env.REACT_APP_SENTRY_DSN,
  API_URLS: {
    [API_TARGETS.production]: 'https://pdl1tps.api.scope.lunit.io',
    [API_TARGETS.qe]: 'https://main.qe.pdl1tps.api.scope.lunit.io',
    [API_TARGETS.dev]: 'https://pdl1tps.api.scope.lunit.io',
    [API_TARGETS.optdev]: 'http://api.officer.pivotal.dev.scope.lunit.io/',
    [API_TARGETS.demo]: 'https://api.pdl1.demo.scope.lunit.io',
  },
  SOCKET_URLS: {
    [API_TARGETS.production]:
      'https://webpush.pdl1tps.api.scope.lunit.io/fast-push-websocket',
    [API_TARGETS.qe]:
      'https://webpush.main.qe.pdl1tps.api.scope.lunit.io/fast-push-websocket',
    [API_TARGETS.dev]:
      'https://fast-push-pdl1.api.dev.scope.lunit.io/fast-push-websocket',
    [API_TARGETS.optdev]:
      'http://fast-noti-webpush-pdl1.api.pivotal.dev.scope.lunit.io/fast-push-websocket',
  },
  // npm expects versions following the SemVer 60 syntax,
  // and that syntax doesn’t allow for 4 digits.
  // So the last digits connected with '-' should be repalce \w '.' to make it 4 digits of format.

  // version code commenting to get rid of the 'undefiend replace' error @sh.bae
  // version: process.env.REACT_APP_VERSION.replace("-", "."),
};

export default env;
