import { forwardRef } from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  PopoverProps,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import map from 'lodash/map';

import { PAN_ITEMS, ZOOM_ITEMS } from '../../const';

const ShortcutListContainer = styled('div')({
  width: '100%',
  padding: '16px 32px',
  maxHeight: 478,
  overflowY: 'auto',
});

interface ShortcutListItemProps {
  icon: React.ReactNode
  title: string
  shortcut: string
}

const ShortcutTextContainer = styled('div')(({ theme }) => ({
  left: 276,
  position: 'absolute',
  '& > .MuiTypography-root': {
    marginRight: 8,
    padding: '4px 8px',
    border: `1px solid ${theme.palette.darkGrey[70]}`,
    borderRadius: '8px',
    '&:last-child': { marginRight: 0 },
  },
}));

function ShortcutListItem({ icon, title, shortcut }: ShortcutListItemProps) {
  const shortcutPieces = shortcut.split('+');
  return (
    <ListItem
      sx={{
        height: 44,
        padding: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        color: (theme) => theme.palette.darkGrey[0],
        position: 'relative',
      }}
    >
      <ListItemIcon
        sx={{
          color: 'inherit',
          padding: '0 4px',
          marginRight: 1,
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: 'body5',
          color: 'inherit',
        }}
      />
      <ShortcutTextContainer>
        {map(shortcutPieces, (piece, index) => (
          <Typography
            variant="body6"
            color="darkGrey.30"
            key={`${piece}_${index}`}
          >
            {piece.replace('=', '+')}
          </Typography>
        ))}
      </ShortcutTextContainer>
    </ListItem>
  );
}

const KeyboardShortcutPopover = forwardRef<HTMLDivElement, PopoverProps>(
  (props, ref) => (
    <Popover
      {...props}
      ref={ref}
      PaperProps={{ sx: { marginTop: 0.5 } }}
    >
      <Paper
        elevation={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 500,
          height: 544,
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#fff',
            height: 64,
          }}
        >
          <Typography variant="subtitle3">Keyboard Shortcuts</Typography>
        </Toolbar>
        <Divider />
        <ShortcutListContainer>
          <List sx={{ padding: 0 }}>
            {map(PAN_ITEMS, (item) => (
              <ShortcutListItem
                key={item.id}
                title={item.title}
                icon={item.icon}
                shortcut={item.shortcut}
              />
            ))}
            {map(ZOOM_ITEMS, (item) => (
              <ShortcutListItem
                key={item.id}
                title={item.title}
                icon={item.icon}
                shortcut={item.shortcut}
              />
            ))}
          </List>
        </ShortcutListContainer>
      </Paper>
    </Popover>
  ),
);

export default KeyboardShortcutPopover;
