import { ComponentType } from 'react';
import { BiomarkerMetadata } from 'src/api/biomarkers/models';
import { LoadingIndicator } from '@lunit/scope-components';

interface ComponentProps {
  metadata: BiomarkerMetadata | undefined
}

interface InnerComponentProps {
  metadata: BiomarkerMetadata
}

export default function withMetadataGuard(
  Component: ComponentType<InnerComponentProps>,
): ComponentType<ComponentProps> {
  return function InnerComponent({ metadata }:ComponentProps) {
    if (!metadata) {
      return (
        <LoadingIndicator
          message="Metadata failed to load for this product"
        />
      );
    }
    return <Component metadata={metadata} />;
  };
}
