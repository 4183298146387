import {
  CanvasOverlayProps,
  TooltipOverlayProps,
} from '@lunit/osd-react-renderer';
import OpenSeadragon from 'openseadragon';
import { useCallback } from 'react';
import { UIComponents } from 'src/api/biomarkers/models';
import { numberWithCommas } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/func';
import { ControllerOrder } from 'src/const/projectConfigSpecs';
import { GridResult } from 'src/workers/AnalysisResultWorker/common/types';
import { ResultWorkerPreparedEventData } from 'src/workers/AnalysisResultWorker/types';
import { useProjectState } from '../projectConfig';
import useViewerZoom from '../useViewerZoom';
import { drawGridTiles, drawTissueImage } from './func/drawing';
import { findCellElement, getZoomFromViewportZoom, roundToFirstDecimal } from './func/utils';
import { Origin } from './webgl/types';
import useWebGLCellDrawing from './webgl/useWebGLCellDrawing';

interface TooltipMsg extends GridResult {
  client: OpenSeadragon.Point
  gridPixelSizeX: number
  gridPixelSizeY: number
  cellCounts: {
    counts: number
    id: string
    title: string
  }[]
}

const useIOVisualization = (
  resultData: ResultWorkerPreparedEventData | undefined,
  uiComponents: UIComponents | undefined,
) => {
  const viewOptions = useProjectState();
  const { handleCellDrawing } = useWebGLCellDrawing();
  const {
    zoomState: { zoom },
    physicalWidthPx,
  } = useViewerZoom();

  const [immunePhenotypeMap, histologicalFeatures] = viewOptions.controllers as ControllerOrder['io'];

  // In IO, used for Tissue Segmentation and Immune Phenotype Map
  const onCanvasOverlayRedraw: CanvasOverlayProps['onRedraw'] = useCallback(
    (overlayEl: HTMLCanvasElement, viewer: OpenSeadragon.Viewer) => {
      if (!resultData) return;
      if (!viewOptions.visible) return;
      if (!histologicalFeatures) return;

      const context = overlayEl.getContext('2d');
      if (!context) return;

      const [
        cancerArea, cancerStroma,
      ] = histologicalFeatures.childController;

      if (histologicalFeatures.visible) {
        if (
          cancerArea.visible
          && resultData.maskImages
          && resultData.maskImageIndex
          && resultData.maskImages[resultData.maskImageIndex['Cancer Area']]
        ) {
          drawTissueImage(
            context,
            0.3,
            resultData.maskImages[resultData.maskImageIndex['Cancer Area']],
          );
        }
        if (
          cancerStroma.visible
          && resultData.maskImages
          && resultData.maskImageIndex
          && resultData.maskImages[resultData.maskImageIndex['Cancer Stroma']]
        ) {
          drawTissueImage(
            context,
            0.3,
            resultData.maskImages[resultData.maskImageIndex['Cancer Stroma']],
          );
        }
      }
      if (immunePhenotypeMap.visible && resultData.gridData && zoom < 10) {
        drawGridTiles(context, viewer, resultData.gridData);
      }
    },
    [resultData, zoom, viewOptions, immunePhenotypeMap, histologicalFeatures],
  );

  const drawBiomarkerInfoTooltip = (
    context: CanvasRenderingContext2D,
    viewer: OpenSeadragon.Viewer,
    tooltipMsg: TooltipMsg,
  ) => {
    const {
      minX,
      minY,
      client,
      type,
      data: { intraTilDensity, stromalTilDensity },
      gridPixelSizeX,
      gridPixelSizeY,
      cellCounts,
    } = tooltipMsg;
    const bounds = viewer.viewport.viewportToImageRectangle(
      viewer.viewport.getBounds(true),
    );
    // Draw grid border
    const sizeRect = new OpenSeadragon.Rect(0, 0, 2, 2);
    const strokeWidth = viewer.viewport.viewportToImageRectangle(
      viewer.viewport.viewerElementToViewportRectangle(sizeRect),
    ).width;
    context.save();
    context.lineWidth = strokeWidth;
    context.strokeStyle = 'black';
    context.fillStyle = 'transparent';
    context.beginPath();
    context.rect(minX, minY, gridPixelSizeX, gridPixelSizeY);
    context.stroke();
    // tooltip box
    const lineNum = 3 + cellCounts.length;
    const tooltipRect = new OpenSeadragon.Rect(0, 28, 230, 21 * lineNum);
    const tooltipBounds = viewer.viewport.viewportToImageRectangle(
      viewer.viewport.viewerElementToViewportRectangle(tooltipRect),
    );
    const textHeight = (18 / 21) * tooltipBounds.height;
    const lineHeight = textHeight / lineNum;
    const fontSize = (14 / 18) * lineHeight;
    context.font = `400 ${fontSize}px/${lineHeight}px Proxima Nova`;
    // measureText to get the box width
    const longestTextWidth = context.measureText('Intratumoral TIL density(mm^2) : 0.000').width
      + (16 / 230) * tooltipBounds.width;
    const { x } = client;
    const y = client.y + (tooltipBounds.y - bounds.y);
    const width = Math.max(tooltipBounds.width, longestTextWidth);
    const { height } = tooltipBounds;
    context.fillStyle = 'rgba(21,30,45,0.8)';
    context.beginPath();
    context.rect(x, y, width, height);
    context.closePath();
    context.fill();
    // tooltip text
    context.fillStyle = '#FFFFFF';
    context.textBaseline = 'top';
    context.textAlign = 'start';
    const textOffsetX = client.x + tooltipBounds.width * (8 / 230);
    let textOffsetY = client.y + (tooltipBounds.y - bounds.y) + tooltipBounds.height * (6 / 63);
    context.fillText(`Type: ${type}`, textOffsetX, textOffsetY);
    textOffsetY += lineHeight;
    context.fillText(
      `Intratumoral TIL density(mm²) : ${roundToFirstDecimal(intraTilDensity)}`,
      textOffsetX,
      textOffsetY,
    );
    textOffsetY += lineHeight;
    context.fillText(
      `Stromal TIL density(mm²) : ${roundToFirstDecimal(stromalTilDensity)}`,
      textOffsetX,
      textOffsetY,
    );
    cellCounts.forEach((cellCount) => {
      textOffsetY += lineHeight;
      context.fillText(
        `# of ${cellCount.title}s: ${numberWithCommas(cellCount.counts)}`,
        textOffsetX,
        textOffsetY,
      );
    });
  };

  const onTooltipOverlayRedraw: TooltipOverlayProps['onRedraw'] = useCallback(
    ({ overlayCanvasEl, viewer, tooltipCoord }
    : {
      overlayCanvasEl:HTMLCanvasElement,
      viewer:OpenSeadragon.Viewer,
      tooltipCoord?:OpenSeadragon.Point
    }) => {
      const context = overlayCanvasEl.getContext('2d');
      if (!resultData || !context || !tooltipCoord) return;
      if (!immunePhenotypeMap) return;
      const { gridData, cellData } = resultData;
      if (
        cellData
        && cellData.length > 0
        && gridData
        && zoom >= 10
        && immunePhenotypeMap.visible
      ) {
        let found = false;
        let tooltipMsg: TooltipMsg | null = null;
        gridData.forEach(
          ({ indexedCoords, gridResults, gridPixelSizeX, gridPixelSizeY }) => {
            const targetGrid = indexedCoords?.range(
              tooltipCoord.x - gridPixelSizeX,
              tooltipCoord.y - gridPixelSizeY,
              tooltipCoord.x,
              tooltipCoord.y,
            );
            if (targetGrid && targetGrid.length) {
              const id = targetGrid[0];
              const data = gridResults[id];
              found = true;
              const cellCounts = cellData.map((cellList) => ({
                id: cellList.id,
                title:
                  findCellElement(uiComponents, cellList.id)?.content || '',
                counts: cellList.indexedCoords!.range(
                  data.minX,
                  data.minY,
                  data.minX + gridPixelSizeX,
                  data.minY + gridPixelSizeY,
                ).length,
              }));
              // draw
              tooltipMsg = {
                // Biomarker info
                ...data,
                // Mouse position
                client: tooltipCoord,
                gridPixelSizeX,
                gridPixelSizeY,
                cellCounts,
              };
            }
          },
        );
        if (found && tooltipMsg) drawBiomarkerInfoTooltip(context, viewer, tooltipMsg);
      }
    },
    [resultData, immunePhenotypeMap, zoom, uiComponents],
  );

  // In IO, used for Cell Detection
  const onWebGLOverlayRedraw = useCallback((
    glCanvas: HTMLCanvasElement,
    normalCanvas: HTMLCanvasElement,
    viewer: OpenSeadragon.Viewer,
    origin:Origin,
  ) => {
    if (!resultData) return;
    if (!viewOptions.visible) return;
    if (!histologicalFeatures) return;

    const microscopeWidth1x = physicalWidthPx * 10;
    const currentZoom = getZoomFromViewportZoom(
      viewer.viewport.getZoom(true),
      microscopeWidth1x,
      viewer,
    );

    const lymphoplasmaCells = histologicalFeatures.childController[2];
    const tumorCells = histologicalFeatures.childController[3];

    const gl = glCanvas.getContext('webgl2', {
      antialias: true,
      premultipliedAlpha: false,
    });
    const ctx = normalCanvas.getContext('2d');

    if (!gl) {
      console.log('failed to load webgl context');
      return;
    }
    if (!ctx) {
      console.log('failed to load 2d context');
      return;
    }

    if (
      histologicalFeatures.visible
      && zoom >= 10
      && Math.abs(currentZoom - zoom) < 0.1
      && resultData.cellData
    ) {
      const lymphocytesCoord = resultData.cellData.find(
        (cells) => cells.id === 'lc',
      );
      const tumorCellsCoord = resultData.cellData.find(
        (cells) => cells.id === 'tc',
      );
      if (lymphoplasmaCells.visible && lymphocytesCoord) {
        handleCellDrawing(gl, ctx, viewer, lymphocytesCoord, zoom, origin);
      }
      if (tumorCells.visible && tumorCellsCoord) {
        handleCellDrawing(gl, ctx, viewer, tumorCellsCoord, zoom, origin);
      }
    }
  }, [
    handleCellDrawing,
    histologicalFeatures,
    physicalWidthPx,
    resultData,
    viewOptions.visible,
    zoom,
  ]);

  return { onCanvasOverlayRedraw, onTooltipOverlayRedraw, onWebGLOverlayRedraw };
};

export default useIOVisualization;
