import * as React from 'react'

const SvgMoveLeft = props => (
  <svg
    width={21}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.956 9.64a.499.499 0 0 0 .009.728l6.998 6.498a.5.5 0 1 0 .68-.732L4.576 10.5h12.727a.5.5 0 1 0 0-1H4.576l6.067-5.634a.5.5 0 0 0-.68-.732l-7 6.499a.552.552 0 0 0-.007.007Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgMoveLeft
