import { HeaderButtonMenuItem } from '@lunit/scope-components/HeaderButton';

import {
  ZoomInIcon,
  ZoomOutIcon,
  MoveUpIcon,
  MoveDownIcon,
  MoveLeftIcon,
  MoveRightIcon,
} from '@lunit/scope-icons';

import { Typography } from '@mui/material';
import { ZOOM_LEVELS } from 'src/const';
import { ViewIconContainer } from '../ToolbarMUI';

export const PAN_ITEMS: HeaderButtonMenuItem[] = [
  {
    id: 'toggle',
    icon: (
      <ViewIconContainer>
        <Typography sx={{ fontSize: 20 }}>...</Typography>
      </ViewIconContainer>
    ),
    title: 'Toggle shortcut menu',
    shortcut: 'T',
  },
  {
    id: 'up',
    icon: (
      <ViewIconContainer>
        <MoveUpIcon />
      </ViewIconContainer>
    ),
    title: 'Slide move up',
    shortcut: '↑+W',
  },
  {
    id: 'down',
    icon: (
      <ViewIconContainer>
        <MoveDownIcon />
      </ViewIconContainer>
    ),
    title: 'Slide move down',
    shortcut: '↓+S',
  },
  {
    id: 'left',
    icon: (
      <ViewIconContainer>
        <MoveLeftIcon />
      </ViewIconContainer>
    ),
    title: 'Slide move left',
    shortcut: '←+A',
  },

  {
    id: 'right',
    icon: (
      <ViewIconContainer>
        <MoveRightIcon />
      </ViewIconContainer>
    ),
    title: 'Slide move right',
    shortcut: '→+D',
  },
];

export const ZOOM_ITEMS: HeaderButtonMenuItem[] = [
  {
    id: 'in',
    icon: (
      <ViewIconContainer>
        <ZoomInIcon />
      </ViewIconContainer>
    ),
    title: 'Zoom in',
    shortcut: '=',
  },
  {
    id: 'out',
    icon: (
      <ViewIconContainer>
        <ZoomOutIcon />
      </ViewIconContainer>
    ),
    title: 'Zoom out',
    shortcut: '-',
  },
  ...ZOOM_LEVELS.filter((_, i) => i > 0 && i < 8).map((zoomLevel, i) => ({
    id: `zoom${zoomLevel}`,
    icon: (
      <ViewIconContainer>
        <Typography sx={{ fontSize: '14px' }}>{`x${zoomLevel}`}</Typography>
      </ViewIconContainer>
    ),
    title: `Magnification ${zoomLevel}`,
    shortcut: `${i + 1}`,
  })),
];
