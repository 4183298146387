import * as React from 'react'

const SvgMoveRight = props => (
  <svg
    width={21}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.65 10.36a.499.499 0 0 0-.01-.728l-6.997-6.498a.5.5 0 0 0-.68.732L16.029 9.5H3.303a.5.5 0 1 0 0 1h12.726l-6.066 5.634a.5.5 0 1 0 .68.732l6.999-6.499.008-.007Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgMoveRight
