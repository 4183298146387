import OpenSeadragon from 'openseadragon';
import { atom } from 'recoil';
import { INITIAL_ZOOM } from 'src/const';

export const zoomAtom = atom<{
  zoom: number
  refPoint: OpenSeadragon.Point | null
}>({
  key: 'zoom',
  default: {
    zoom: INITIAL_ZOOM,
    refPoint: null,
  },
});

export const physWidthPxAtom = atom<number>({
  key: 'physWidthPx',
  default: 0,
});
