import { ScalebarLocation } from '@lunit/osd-react-renderer';
import { Theme } from '@mui/material';

interface Props {
  mppX: number | undefined
  theme:Theme // calling useTheme in this component fails to load our theme :S
}

const MICRONS_PER_METER = 1000000;

function ViewerScaleBar({ mppX, theme }:Props) {
  return (
    <scalebar
      pixelsPerMeter={
      MICRONS_PER_METER / (mppX || 0.25)
    }
      xOffset={10}
      yOffset={30}
      barThickness={2}
      color={theme.palette.darkGrey[80]}
      fontColor={theme.palette.darkGrey[80]}
      backgroundColor="rgba(255,255,255,0.5)"
      location={ScalebarLocation.BOTTOM_RIGHT}
      stayInsideImage={false}
    />
  );
}
export default ViewerScaleBar;
