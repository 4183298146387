import { TypographyOptions } from '@mui/material/styles/createTypography'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle3: React.CSSProperties
    body3: React.CSSProperties
    body4: React.CSSProperties
    body5: React.CSSProperties
    body6: React.CSSProperties
    caption: React.CSSProperties
    button2: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    subtitle3?: React.CSSProperties
    body3?: React.CSSProperties
    body4?: React.CSSProperties
    body5?: React.CSSProperties
    body6?: React.CSSProperties
    caption?: React.CSSProperties
    button2?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true
    body3: true
    body4: true
    body5: true
    body6: true
    caption: true
    button2: true
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    subtitle3?: TypographyStyle
    body3?: TypographyStyle
    body4?: TypographyStyle
    body5?: TypographyStyle
    body6?: TypographyStyle
    caption?: TypographyStyle
    button2?: TypographyStyle
  }

  interface TypographyOptions {
    subtitle3?: TypographyStyleOptions
    body3?: TypographyStyleOptions
    body4?: TypographyStyleOptions
    body5?: TypographyStyleOptions
    body6?: TypographyStyleOptions
    caption?: TypographyStyleOptions
    button2?: TypographyStyleOptions
  }
}

const typographyOptions: TypographyOptions = {
  fontFamily: [
    'Pretendard',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  h1: {
    fontSize: 52,
    fontWeight: 'normal',
    lineHeight: '68px',
  },
  h2: {
    fontSize: 38,
    fontWeight: 'bold',
    lineHeight: '48px',
  },
  h3: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '36px',
  },
  h4: {
    fontSize: 20,
    fontWeight: 'normal',
    lineHeight: '28px',
  },
  h5: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '28px',
  },
  h6: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '28px',
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
  },
  subtitle3: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  body1: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  body2: {
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: '24px',
  },
  body3: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '20px',
  },
  body4: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
  },
  body5: {
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '20px',
  },
  body6: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.2px',
  },
  overline: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  button: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
    textTransform: 'none',
  },
  button2: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 400,
  },
  caption: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
  },
}

export default typographyOptions
