import * as React from 'react'

const SvgSearch = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.438 14.145a7.5 7.5 0 1 1 .707-.707l4.658 4.658a.5.5 0 1 1-.707.707l-4.658-4.658ZM15 8.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgSearch
