import { Box, Divider, styled } from '@mui/material';
import { SectionListItem } from '../../styled';

export * from './Accordion';
export * from './BarChart';

export const CenteredBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const DenseSectionListItem = styled(SectionListItem)({
  padding: 0,
  flex: '0 0 20px',
  height: 20,
  marginTop: 4,
  '&:first-of-type': { marginTop: 0 },
});

const MUIDivider = styled(Divider)({ margin: '8px 16px' });
export function SummaryDivider() {
  return <MUIDivider orientation="horizontal" />;
}
