import type { ComponentsOverrides } from '@mui/material/styles'

const styleOverrides: ComponentsOverrides['MuiTableHead'] = {
  root: {
    height: 48,
  },
}
export default {
  styleOverrides,
}
