import * as React from 'react'

const SvgInformation = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 8.75a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0v-5a.5.5 0 0 1 .5-.5ZM10 5.75a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 10a8.5 8.5 0 1 1 17 0 8.5 8.5 0 0 1-17 0ZM10 2.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgInformation
