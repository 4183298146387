import { ImageList } from '@mui/material';
import map from 'lodash/map';
import { useMemo } from 'react';
import { Biomarkers } from 'src/api/biomarkers/models';
import { getProductsFromAPIProducts } from './models';
import ProductCard from './ProductCard';
import { Container } from './styled';

interface ProductListProps {
  products: Biomarkers['products']
}

// eslint-disable-next-line import/prefer-default-export
export function ProductList({ products: apiProducts }: ProductListProps) {
  const products = useMemo(
    () => getProductsFromAPIProducts(apiProducts),
    [apiProducts],
  );
  return (
    <Container sx={{ margin: 0, height: 'fit-content' }}>
      <ImageList
        rowHeight={160}
        gap={16}
        sx={{
          margin: 0,
          justifyContent: 'center',
        }}
        cols={2}
        style={{
          gridTemplateColumns: '480px 480px',
          gridAutoRows: 'min-content',
        }}
      >
        {products.length > 0 ? (
          map(products, (product) => (
            <ProductCard
              product={product}
              key={product.id}
            />
          ))
        ) : (
          <>
            <ProductCard loading />
            <ProductCard loading />
            <ProductCard loading />
            <ProductCard loading />
            <ProductCard loading />
            <ProductCard loading />
          </>
        )}
      </ImageList>
    </Container>
  );
}
