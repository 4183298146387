import * as React from 'react'

const SvgMore = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={5.168} cy={10} r={1.5} fill="currentColor" />
    <circle cx={10.668} cy={10} r={1.5} fill="currentColor" />
    <circle cx={16.168} cy={10} r={1.5} fill="currentColor" />
  </svg>
)

export default SvgMore
