import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { scope } from '@lunit/scope-theme';
import { SnackbarProvider } from 'notistack';
import SlideTransition from '@mui/material/Slide';
import { RecoilRoot } from 'recoil';
import { DialogProvider } from './Dialog/context';
import { ResizeObserverProvider } from './ResizeObserverContext';

interface AppProviderProps {
  children: ReactNode
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      useErrorBoundary: true,
      retry: 1,
    },
  },
});

const SnackbarNotiProvider = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
`;

export default function ScopeComponentsProvider({ children }: AppProviderProps) {
  const theme = createTheme(scope);
  return (
    <QueryClientProvider client={queryClient}>
      <ResizeObserverProvider>
        <RecoilRoot>
          <ThemeProvider theme={theme}>
            <DialogProvider>
              <SnackbarNotiProvider
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                TransitionComponent={SlideTransition}
                hideIconVariant
              >
                <CssBaseline />
                {children}
              </SnackbarNotiProvider>
              <div id="dialog-root" />
              <div id="mui-dialog-root" />
            </DialogProvider>
          </ThemeProvider>
        </RecoilRoot>
      </ResizeObserverProvider>
    </QueryClientProvider>
  );
}
