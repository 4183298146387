import type { ComponentsOverrides } from '@mui/material/styles'

const styleOverrides: ComponentsOverrides['MuiListItemIcon'] = {
  root: {
    color: 'inherit',
    minWidth: 'auto',
    marginRight: 12,
    '.MuiButtonBase-root.MuiMenuItem-root &': {
      minWidth: 'auto',
    },
  },
}
export default {
  styleOverrides,
}
