import { useState } from 'react';

import HeaderButton from '@lunit/scope-components/HeaderButton';
import { ShortcutIcon } from '@lunit/scope-icons';

import KeyboardShortcutsModal from './KeyboardShortcutsModal';

function KeyboardShortcutsButton() {
  const [shortcutsOpen, setShortcutsOpen] = useState(false);
  const openShortcuts = () => setShortcutsOpen(!shortcutsOpen);
  return (
    <>
      <HeaderButton
        onClick={openShortcuts}
        shortcut="T"
        onShortcutPress={openShortcuts}
        tooltip={!shortcutsOpen ? 'Keyboard Shortcuts' : ''}
      >
        <ShortcutIcon />
      </HeaderButton>
      <KeyboardShortcutsModal
        isOpen={shortcutsOpen}
        onClose={() => setShortcutsOpen(false)}
      />
    </>
  );
}
export default KeyboardShortcutsButton;
