import { isLabelElement, LabelElement, UIComponents } from 'src/api/biomarkers/models';
import { TooltipContainer } from 'src/components/common/ViewOptionsPanel/styled';
import TooltipOption from 'src/components/common/ViewOptionsPanel/TooltipElements';

interface Props {
  uiComponents:UIComponents
}

function HER2DensityHeatmapTooltip({ uiComponents }:Props) {
  const densityHeatmapElement = uiComponents.elements.find((elem) => (isLabelElement(elem) && elem.content === 'Density Heatmap'));
  if (densityHeatmapElement) {
    const heatmapElement = densityHeatmapElement as LabelElement;
    const element = { ...heatmapElement, elements: heatmapElement.elements.slice(0, 4) };

    return (
      <TooltipContainer>
        <TooltipOption element={element} />
      </TooltipContainer>
    );
  }
  return null;
}
export default HER2DensityHeatmapTooltip;
