import { HiddenIcon, ShownIcon } from '@lunit/scope-icons';
import { IconButton } from '../../styled';

interface Props {
  toggle?:()=> void;
  on?:boolean;
}

export default function ViewOptionsToggleButton({ toggle, on }:Props) {
  return (
    <IconButton
      sx={{
        padding: 0.5,
        ':hover': { backgroundColor: (theme) => theme.palette.darkGrey[80] },
      }}
      onClick={toggle}
    >
      {on ? <ShownIcon /> : <HiddenIcon />}
    </IconButton>
  );
}
