import * as React from 'react'

const SvgMoveDown = props => (
  <svg
    width={21}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.943 16.847a.498.498 0 0 0 .728-.009L17.17 9.84a.5.5 0 1 0-.733-.68l-5.633 6.067V2.5a.5.5 0 1 0-1 0v12.727L4.169 9.16a.5.5 0 0 0-.733.68l6.5 7a.892.892 0 0 0 .007.007Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgMoveDown
