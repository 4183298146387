import { SectionListItem } from '@lunit/scope-components/Analysis/SlidePanel/common';
import PlainSelect from '@lunit/scope-components/PlainSelect';
import { MenuItem } from '@mui/material';

import { DropdownElement } from 'src/api/biomarkers/models';
import { DropdownControllerProps } from 'src/types';
import { SingleOptionProps } from '../../models';
import ViewOptionsToggleButton from './styled';

function DropdownOption({
  uiElement,
  isInTooltip,
  elementController,
}: SingleOptionProps<DropdownControllerProps> & {
  uiElement: DropdownElement
}) {
  const { elements } = uiElement;

  if (isInTooltip) {
    return null;
  }
  return (
    <SectionListItem
      sx={{
        opacity: !elementController.visible || !elementController.on ? 0.5 : 1,
        padding: (theme) => theme.spacing(0, 2.5),
      }}
    >
      <PlainSelect
        value={elementController.active}
        onChange={(value) => elementController.onChange(value)}
      >
        {elements.map((e) => (
          <MenuItem
            value={e.content}
            key={e.content}
          >
            {e.content}
          </MenuItem>
        ))}
      </PlainSelect>
      <ViewOptionsToggleButton
        toggle={elementController.toggle}
        on={elementController.on}
      />
    </SectionListItem>
  );
}
export default DropdownOption;
