import type { ComponentsOverrides } from '@mui/material/styles'

const styleOverrides: ComponentsOverrides['MuiList'] = {
  root: {
    paddingTop: 12,
    paddingBottom: 12,
  },
}
export default {
  styleOverrides,
}
