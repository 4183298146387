import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  DeleteIcon,
  PanIcon,
  PointIcon,
  PolygonIcon,
  RedoIcon,
  UndoIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from '@lunit/scope-icons';

import range from 'lodash/range';
import { HeaderButtonMenuItem } from '../../HeaderButton';
import isMac from '../../utils/isMac';

// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
export const POLYGON_MENU_ITEMs: HeaderButtonMenuItem[] = [
  {
    id: 'polygon',
    icon: <PolygonIcon />,
    title: 'Draw :roiType',
    shortcut: 'R',
  },
];

export const POINTER_MENU_ITEMS: HeaderButtonMenuItem[] = [
  {
    id: 'pointer',
    icon: <PointIcon />,
    title: 'Point',
    shortcut: 'V',
  },
  {
    id: 'hand',
    icon: <PanIcon />,
    title: 'Hand tool',
    shortcut: 'H',
  },
];

export const UNDO_SHORTCUT = isMac() ? 'Cmd+Z' : 'Ctrl+Z';
export const REDO_SHORTCUT = isMac() ? 'Cmd+Shift+Z' : 'Ctrl+Shift+Z';

export const MISC_TOOL_ITEMS: HeaderButtonMenuItem[] = [
  {
    id: 'undo',
    icon: <UndoIcon />,
    title: 'Undo',
    shortcut: UNDO_SHORTCUT,
  },
  {
    id: 'redo',
    icon: <RedoIcon />,
    title: 'Redo',
    shortcut: REDO_SHORTCUT,
  },
  {
    id: 'delete',
    icon: <DeleteIcon />,
    title: 'Delete selected',
    shortcut: 'Backspace',
  },
];

export const RESET_ITEM: HeaderButtonMenuItem = {
  id: 'reset',
  icon: null,
  title: 'Reset',
};

const ViewIconContainer = styled('div')({
  minWidth: 32,
  display: 'flex',
  alignItems: 'center',
});

export const PAN_ITEMS: HeaderButtonMenuItem[] = [
  {
    id: 'up',
    icon: <ViewIconContainer />,
    title: 'Slide move up',
    shortcut: 'W',
  },
  {
    id: 'left',
    icon: <ViewIconContainer />,
    title: 'Slide move left',
    shortcut: 'A',
  },
  {
    id: 'down',
    icon: <ViewIconContainer />,
    title: 'Slide move down',
    shortcut: 'S',
  },
  {
    id: 'right',
    icon: <ViewIconContainer />,
    title: 'Slide move right',
    shortcut: 'D',
  },
];

const zoomLevelLabels = [0.1, 0.5, 1, 2, 5, 10, 20, 40, 160];

const getZoomLevelForShortcut = (shortcutKey: string) => {
  const pressedNum = Number(shortcutKey);
  if ((8 - pressedNum) % 8) {
    return zoomLevelLabels[(8 - pressedNum) % 8];
  }
  return -1;
};

export const ZOOM_ITEMS: HeaderButtonMenuItem[] = [
  {
    id: 'in',
    icon: (
      <ViewIconContainer>
        <ZoomInIcon />
      </ViewIconContainer>
    ),
    title: 'Zoom in',
    shortcut: '=',
  },
  {
    id: 'out',
    icon: (
      <ViewIconContainer>
        <ZoomOutIcon />
      </ViewIconContainer>
    ),
    title: 'Zoom out',
    shortcut: '-',
  },
  ...range(7).map((val) => ({
    id: `zoom${val + 1}`,
    icon: (
      <ViewIconContainer>
        <Typography sx={{ marginLeft: '6.5px' }}>
          {`x${getZoomLevelForShortcut(`${val + 1}`)}`}
        </Typography>
      </ViewIconContainer>
    ),
    title: `Magnification ${getZoomLevelForShortcut(`${val + 1}`)}`,
    shortcut: `${val + 1}`,
  })),
];
