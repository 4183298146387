import { SectionListItem } from '@lunit/scope-components/Analysis/SlidePanel/common';
import { Typography } from '@mui/material';
import { LabelElement, MarkerLabelElement } from 'src/api/biomarkers/models';
import { PointIndicator } from 'src/components/common/ViewOptionsPanel/styled';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import { numberWithCommas } from '../func';
import { CenteredBox } from '../styled';

interface Props {
  data: AnalysisData
  cellDetectionElement: LabelElement
}

function IntensityTypeProportionIndicator({
  data,
  cellDetectionElement,
}: Props) {
  return (
    <SectionListItem
      sx={{
        padding: '1px 20px',
        height: 20,
        flex: '0 0 20px',
      }}
      key={`proportion_${data.labelId}`}
    >
      <CenteredBox>
        <PointIndicator
          sx={{
            backgroundColor: (
              cellDetectionElement.elements.find(
                (elem) => elem.id === data.labelId,
              ) as MarkerLabelElement
            ).color,
            marginLeft: '0px',
            marginRight: '10px',
          }}
        />
        <Typography variant="body5">{data.name}</Typography>
      </CenteredBox>
      <CenteredBox>
        <Typography variant="body4">
          {(Math.floor(Number(data.value) * 10) / 10).toFixed(1)}
          %
        </Typography>
        &nbsp;
        <Typography variant="body4">
          (
          {data.items
            && numberWithCommas(Math.floor(Number(data.items[0].value)))}
          )
        </Typography>
      </CenteredBox>
    </SectionListItem>
  );
}
export default IntensityTypeProportionIndicator;
