import { map } from 'lodash';
import React from 'react';
import { LabelElement } from 'src/api/biomarkers/models';
import { ElementInTitleTooltipConfig } from 'src/types';
import { SubOption } from '../../OptionElements/OptionVariants';
import { TooltipCaption, TooltipListItem, TooltipSpacer, TooltipTitle, Typography } from '../../styled';

interface Props {
  element:LabelElement
  elementInTitleTooltipConfig?:ElementInTitleTooltipConfig
}

function TooltipLabelOption({ element, elementInTitleTooltipConfig }:Props) {
  function isLast(index:number):boolean {
    return index === element.elements.length - 1;
  }

  return (
    <TooltipListItem>
      <TooltipTitle>{element.content}</TooltipTitle>
      <TooltipSpacer height="3px" />
      {element.elements && map(element.elements, (e, index) => (
        <React.Fragment key={e.id}>
          <SubOption
            uiElement={e}
            disableToggleButton
            last={isLast(index)}
            isInTooltip
          />
          {!isLast(index) && (
          <TooltipSpacer
            height="4px"
          />
          )}
        </React.Fragment>
      ))}
      {elementInTitleTooltipConfig?.content && (
      <Typography
        variant="body5"
        sx={{ width: '168px' }}
      >
        {elementInTitleTooltipConfig.content}
      </Typography>
      )}
      {elementInTitleTooltipConfig?.caption
      && <TooltipCaption>{elementInTitleTooltipConfig.caption}</TooltipCaption>}
    </TooltipListItem>
  );
}
export default TooltipLabelOption;
