import type { ComponentsOverrides } from '@mui/material/styles'
import palette from '../colors'
import typographyOptions from '../system/typography'

const styleOverrides: ComponentsOverrides['MuiButton'] = {
  root: {
    borderRadius: 8,
    width: 'auto',
    height: '36px',
    padding: '8px 12px',
    ...typographyOptions.body4,
    color: palette.scope1,
    textTransform: 'none',
    '&:hover, &:focus-visible': {
      backgroundColor: palette.darkGrey[80],
      boxShadow: 'none',
    },
    '&:disabled': {
      background: 'transparent',
      cursor: 'default',
      opacity: 0.3,
    },
  },
  sizeSmall: {
    height: '28px',
    padding: '4px 12px',
  },
  sizeLarge: {
    ...typographyOptions.button,
    height: '44px',
    paddingTop: '10px',
  },
  contained: {
    backgroundColor: palette.scope2,
    boxShadow: 'none',
    color: palette.darkGrey[5],
    '&:hover, &:focus-visible': {
      backgroundColor: palette.scope4,
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: palette.scope2,
      opacity: 0.3,
      cursor: 'default',
    },
  },
  containedSizeMedium: {
    padding: '0',
  },
}

export default {
  styleOverrides,
}
