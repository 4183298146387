import React from 'react';
import OSDViewer from '@lunit/osd-react-renderer';

import { OSDSlideContainer } from '../SlideViewerMUI';

import { useViewportHandlers } from './slideViewerHooks';

import { OSD_OPTIONS } from './osdConfig';

import { SlideUrlData } from '../types';

interface ReadOnlyViewerProps {
  slideData: SlideUrlData
  mpp?: number
  canvasOverlay?: React.ReactNode
  tooltipOverlay?: React.ReactNode
  webGLOverlay?:React.ReactNode
  scaleBar?: React.ReactNode
  defaultZoom?: number
}

function ReadOnlyViewer({
  slideData,
  defaultZoom = 1,
  mpp = 0.25,
  canvasOverlay,
  tooltipOverlay,
  webGLOverlay,
  scaleBar,
}: ReadOnlyViewerProps) {
  const {
    onZoom,
    onSlideOpen,
    onSlideClose,
    onHome,
    onCanvasDoubleClick,
    getViewportZoomFromZoom,
    mouseTracker,
    currentZoomLevel,
    zoomOSDRef,
    viewerRef,
    physicalWidthPx,
  } = useViewportHandlers(mpp, defaultZoom);

  const microscopeWidth1x = physicalWidthPx * 10;

  return (
    <OSDSlideContainer>
      <OSDViewer
        ref={viewerRef}
        options={OSD_OPTIONS}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <viewport
          rotation={0}
          zoom={getViewportZoomFromZoom(
            currentZoomLevel,
            microscopeWidth1x,
            viewerRef?.current ? viewerRef?.current?.viewer : undefined,
          )}
          refPoint={zoomOSDRef || undefined}
          onZoom={onZoom}
          onCanvasDoubleClick={onCanvasDoubleClick}
          onOpen={onSlideOpen}
          onHome={onHome}
          onClose={onSlideClose}
        />
        <tiledImage
          url={slideData.slideDziMetaUrl}
          tileUrlBase={slideData.slideDziUrl}
        />
        <mouseTracker
          onExit={mouseTracker.onExit}
          onNonPrimaryPress={mouseTracker.onNonPrimaryPress}
          onNonPrimaryRelease={mouseTracker.onNonPrimaryRelease}
          onMove={mouseTracker.onMove}
        />
        {canvasOverlay}
        {webGLOverlay}
        {tooltipOverlay}
        {scaleBar}
      </OSDViewer>
    </OSDSlideContainer>
  );
}

export default ReadOnlyViewer;
