import * as React from 'react'

const SvgArrow = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m10 12-.354.354a.5.5 0 0 0 .708 0L10 12Zm4.354-3.646a.5.5 0 0 0-.708-.708l.708.708Zm-8-.708a.5.5 0 1 0-.708.708l.708-.708Zm4 4.708 4-4-.708-.708-4 4 .708.708Zm0-.708-4-4-.708.708 4 4 .708-.708Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgArrow
