import ProjectViewer from 'src/components/common/ProjectPage/ProjectViewer';
import useHER2Visualization from 'src/hooks/visualization/useHER2Visualization';
import { ResultWorkerPreparedEventData } from 'src/workers/AnalysisResultWorker/types';
import { SlideUrlData } from '../../../components/common/SlideViewer/types';

interface HER2ViewerProps {
  slideData: SlideUrlData
  resultData?: ResultWorkerPreparedEventData

}

function HER2Viewer({ slideData, resultData }: HER2ViewerProps) {
  const visualization = useHER2Visualization(resultData);

  return (
    <ProjectViewer
      defaultZoom={2}
      slideData={slideData}
      resultData={resultData}
      visualization={visualization}
    />
  );
}

export default HER2Viewer;
