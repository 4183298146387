import { find, max } from 'lodash';
import { isLabelElement, isMarkerLabelElement, LabelElement, MarkerLabelElement, UIElement } from 'src/api/biomarkers/models';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';

export const calculateBarWidth = (proportion: number): number | undefined => max(
  [(proportion / 100) * 197, 1],
);
export function convertMinAvgMax(str: string): string {
  switch (str) {
    case 'Min':
      return 'Minimum';
    case 'Avg':
      return 'Average';
    case 'Max':
      return 'Maximum';
    default:
      return str;
  }
}

export function findLabelElementByContent(uiElements:UIElement[] | undefined, contentQuery:string)
  :LabelElement | undefined {
  return uiElements?.find(
    (element): element is LabelElement => isLabelElement(element)
    && element.content === contentQuery,
  );
}

export const findSummaryItemByTitle = (
  summary: AnalysisData[] | undefined,
  name: string,
): AnalysisData | null => find(summary, (item) => item.name === name) || null;

export function formatUnit(unit: string): string {
  switch (unit) {
    case 'sq_mm':
      return 'mm²';
    case 'percent':
      return '%';
    default:
      return unit;
  }
}

export function getColorFromMarkerLabel(element:LabelElement | undefined, idQuery:string)
  :string | undefined {
  return element?.elements.find(
    (cell): cell is MarkerLabelElement => cell.id === idQuery && isMarkerLabelElement(cell),
  )?.color;
}

export const getNumberFromString = (value: string | undefined) => Number(value) || 0;

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getStringFromHasComma(x: string) {
  if (x.includes(',')) {
    return x;
  }
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const roundToFirstDecimal = (num: number) => `${(Math.round(num * 10) / 10).toFixed(1)}`;

export function spaceToHyphen(str:string):string {
  return str.replaceAll(' ', '-');
}
