import { Divider, Menu, MenuItem } from '@mui/material';
import AvatarButton from '@lunit/scope-components/AvatarButton';

import { useState } from 'react';

import { useMyInfoQuery } from 'src/hooks/useAuthQueries';
import usePopper from 'src/hooks/usePopper';
import { removeTokensAndRedirectToLogin } from 'src/pages/auth/utils';
import ProfileDialog from './Dialog/ProfileDialog';
import PasswordDialog from './Dialog/PasswordDialog';

function ProfileAvatarButton() {
  const { anchorEl, onClose, onOpen, open } = usePopper();

  const [profileOpen, setProfileOpen] = useState<boolean>(false);
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);

  const { data: user, isError, isSuccess, error } = useMyInfoQuery();

  if (isError) {
    return <span>{error.message}</span>;
  }

  if (!isSuccess) {
    return null;
  }

  return (
    <>
      <AvatarButton
        name={user.firstName}
        onClick={onOpen}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        PaperProps={{
          elevation: 1,
          sx: { width: '200px' },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setProfileOpen(true);
              onClose();
            }}
          >
            Your profile
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPasswordOpen(true);
              onClose();
            }}
          >
            Reset password
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => removeTokensAndRedirectToLogin()}>
            Sign out
          </MenuItem>
        </div>
      </Menu>
      <ProfileDialog
        open={profileOpen}
        onCancel={() => setProfileOpen(false)}
      />
      <PasswordDialog
        open={passwordOpen}
        onCancel={() => setPasswordOpen(false)}
      />
    </>
  );
}

export default ProfileAvatarButton;
