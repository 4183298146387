import { ComponentType } from 'react';
import { BiomarkerMetadata } from 'src/api/biomarkers/models';
import { useConfiguredProject } from 'src/hooks/projectConfig';
import { config } from 'src/const/projectConfigSpecs';
import { ConfiguredProject, ProjectPath } from 'src/types';

interface ComponentProps {
  metadata: BiomarkerMetadata
}

interface InnerComponentProps {
  metadata: BiomarkerMetadata
  projectConfig: ConfiguredProject
}

export default function withProjectConfig(
  Component: ComponentType<InnerComponentProps>,
  projectPath: ProjectPath,
): ComponentType<ComponentProps> {
  return function InnerComponent({ metadata }:ComponentProps) {
    const configuration = useConfiguredProject(
      config[projectPath],
      metadata.uiComponents,
    );

    return (
      <Component
        metadata={metadata}
        projectConfig={configuration}
      />
    );
  };
}
