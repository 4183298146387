import { UIComponents } from 'src/api/biomarkers/models';
import ProjectViewer from 'src/components/common/ProjectPage/ProjectViewer';
import { SlideUrlData } from 'src/components/common/SlideViewer/types';
import useIOVisualization from 'src/hooks/visualization/useIOVisualization';
import { ResultWorkerPreparedEventData } from 'src/workers/AnalysisResultWorker/types';

interface IOSlideViewerProps {
  slideData: SlideUrlData
  resultData?: ResultWorkerPreparedEventData
  uiComponents: UIComponents | undefined
}

function IOSlideViewer({
  slideData,
  resultData,
  uiComponents,
}: IOSlideViewerProps) {
  const visualization = useIOVisualization(
    resultData,
    uiComponents,
  );

  return (
    <ProjectViewer
      slideData={slideData}
      resultData={resultData}
      visualization={visualization}
    />
  );
}

export default IOSlideViewer;
