import { map } from 'lodash';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import { SectionListItem, Typography } from '../styled';
import { findSummaryItemByTitle, formatUnit, getNumberFromString, roundToFirstDecimal } from './func';
import { CenteredBox } from './styled';

interface Props {
  summary:AnalysisData[] | undefined
  titles:string[]
}

function AnalysisTable({ summary, titles }:Props) {
  return (
    <>
      {map(titles, (title) => {
        const summaryItem = findSummaryItemByTitle(summary, title);
        return (
          summaryItem && (
          <SectionListItem key={`${summaryItem.name}`}>
            <CenteredBox>
              <Typography variant="body5">{summaryItem.name}</Typography>
            </CenteredBox>
            <CenteredBox>
              <Typography variant="body4">
                {`${roundToFirstDecimal(getNumberFromString(summaryItem.value))}${
                  summaryItem.unit && summaryItem.unit !== 'Integer'
                    ? `${formatUnit(summaryItem.unit)}`
                    : ''
                }`}
              </Typography>
            </CenteredBox>
          </SectionListItem>
          )
        );
      })}
    </>
  );
}
export default AnalysisTable;
