import type { ComponentsOverrides } from '@mui/material/styles'

const styleOverrides: ComponentsOverrides['MuiCheckbox'] = {
  root: {
    '&.Mui-checked': {
      color: '#587EFC'
    },
  },
  colorSecondary: {
    color: '#8694B1',
    '&$checked': {
      color: '#7292FD',
    },
    '&$indeterminate': {
      color: '#7292FD',
    },
  },
}
export default {
  styleOverrides,
}