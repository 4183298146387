import { JetHeatmapElement } from 'src/api/biomarkers/models';
import HeatmapOption from '../../OptionElements/OptionVariants/OptionComponents/HeatmapOption';
import { TooltipListItem, TooltipSpacer, TooltipTitle } from '../../styled';

interface Props {
  element:JetHeatmapElement
}

function TooltipHeatmapOption({ element }:Props) {
  return (

    <TooltipListItem>
      <TooltipTitle>{element.content}</TooltipTitle>
      <TooltipSpacer height="4qpx" />
      <HeatmapOption
        isInTooltip
        hasCenter
      />
    </TooltipListItem>
  );
}
export default TooltipHeatmapOption;
