function TreeIndicator({ last }: { last?: boolean }) {
  if (last) {
    return (
      <svg
        width="7"
        height="28"
        viewBox="0 0 7 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 0V14H7"
          stroke="#3E3E40"
          fill="none"
          className="fixed-color"
        />
      </svg>
    );
  }
  return (
    <svg
      width="7"
      height="28"
      viewBox="0 0 7 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0V14H7"
        stroke="#3E3E40"
        fill="none"
        className="fixed-color"
      />
      <path
        d="M1 0V28"
        stroke="#3E3E40"
        fill="none"
        className="fixed-color"
      />
    </svg>
  );
}
export default TreeIndicator;
