import { IconButton, Typography } from '@mui/material';
import { ArrowDownIcon } from '@lunit/scope-icons';
import useToggleState from '@lunit/scope-components/hooks/useToggleState';

import { StyledAccordion, StyledAccordionDetail, StyledAccordionSummary } from './styled';
import { spaceToHyphen } from './func';

interface Props {
  title:string,
  children:React.ReactNode
}

function AnalysisAccordion({ title, children }:Props) {
  const [isOpen, handleToggleOpen] = useToggleState(true);

  return (
    <StyledAccordion
      disableGutters
      square
      expanded={isOpen}
    >
      <StyledAccordionSummary
        expandIcon={(
          <IconButton
            sx={{
              padding: 0.5,
              '&:hover': { backgroundColor: (theme) => theme.palette.darkGrey[80] },
            }}
            onClick={handleToggleOpen}
          >
            <ArrowDownIcon />
          </IconButton>
        )}
        aria-label="Expand"
        aria-controls={`${spaceToHyphen(title)}-content`}
        id={`${spaceToHyphen(title)}-header`}
      >
        <Typography variant="body4">{title}</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetail>
        {children}
      </StyledAccordionDetail>
    </StyledAccordion>
  );
}
export default AnalysisAccordion;
