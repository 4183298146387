import { SectionContent } from '@lunit/scope-components/Analysis/SlidePanel/common';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

interface Props {
  active:string,
  onChange:(event: React.MouseEvent<HTMLElement, MouseEvent>, value: any)=>void
}

function HER2Buttons({ active, onChange }:Props) {
  return (
    <SectionContent sx={{ padding: '8px 16px' }}>
      <ToggleButtonGroup
        sx={{
          borderRadius: 8,
          height: 28,
          width: '100%',
        }}
        value={active}
        exclusive
        onChange={onChange}
      >
        <ToggleButton
          sx={{
            flex: '1 1 50%',
            color: active === 'CA' ? 'inherit' : 'darkGrey.40',
          }}
          value="CA"
          aria-label="Invasive"
        >
          <Typography variant="button2">
            CA&sup1;
          </Typography>
        </ToggleButton>
        <ToggleButton
          sx={{
            flex: '1 1 50%',
            color: active === 'CIS' ? 'inherit' : 'darkGrey.40',
          }}
          value="CIS"
          aria-label="InSitu"
        >
          <Typography variant="button2">
            CIS&sup2;
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </SectionContent>
  );
}
export default HER2Buttons;
