import AuthTextField from '@lunit/scope-components/Auth/AuthTextField';
import { Box, Grid, Tooltip } from '@mui/material';
import { useState } from 'react';
import { UseFormRegister, UseFormGetValues } from 'react-hook-form';

interface Props {
  register: UseFormRegister<any>
  errors:any
  validateCaseSensitive:(value:string)=>boolean
  getValues:UseFormGetValues<any>
}

function PersonalDetails({ register, errors, validateCaseSensitive, getValues }:Props) {
  const [pwdTooltipOpen, setPwdTooltipOpen] = useState<boolean>(false);
  const [confirmPwdTooltipOpen, setConfirmPwdTooltipOpen] = useState<boolean>(false);

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <AuthTextField
          id="email"
          type="email"
          autoComplete="username"
          aria-label="Business email"
          placeholder="Business email"
          sx={{ marginBottom: 0 }}
          {...register('email', {
            disabled: true,
            required: 'This field is required.',
            validate: (value) => validateCaseSensitive(value),
          })}
          error={!!errors.email}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Tooltip
          open={pwdTooltipOpen}
          placement="left"
          title={(
            <Box sx={{ margin: 2 }}>
              Password should contain
              <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
                <li
                  style={{
                    color:
                          errors
                          && errors.password
                          && (errors.password.type === 'minLength'
                            || errors.password.type === 'maxLength')
                            ? '#FA4D56'
                            : 'inherit',
                  }}
                >
                  8-20 characters.
                </li>
                <li
                  style={{
                    color:
                          errors
                          && errors.password
                          && errors.password.type === 'containsNumber'
                            ? '#FA4D56'
                            : 'inherit',
                  }}
                >
                  At least one number.
                </li>
                <li
                  style={{
                    color:
                          errors
                          && errors.password
                          && errors.password.type === 'containsLetter'
                            ? '#FA4D56'
                            : 'inherit',
                  }}
                >
                  At least one letter.
                </li>
                <li
                  style={{
                    color:
                          errors
                          && errors.password
                          && errors.password.type === 'containsSpecialCharacters'
                            ? '#FA4D56'
                            : 'inherit',
                  }}
                >
                  At least one special
                  <br />
                  {' '}
                  character($@%^&*!#).
                </li>
              </ul>
            </Box>
              )}
        >
          <AuthTextField
            id="password"
            type="password"
            autoComplete="new-password"
            aria-label="Password"
            placeholder="Password"
            onFocus={() => setPwdTooltipOpen(true)}
            sx={{ marginBottom: 0 }}
            {...register('password', {
              required: 'This field is required.',
              onBlur: () => setPwdTooltipOpen(false),
              minLength: {
                value: 8,
                message: 'Minimum 8 characters.',
              },
              maxLength: {
                value: 20,
                message: 'Maximum 20 characters.',
              },
              validate: {
                containsNumber: (value) => (/\d/g.test(value) ? true : 'At least one number.'),
                containsLetter: (value) => (/[a-zA-Z]/g.test(value)
                  ? true
                  : 'At least one letter.'),
                containsSpecialCharacters: (value) => (/[$@%^&*!#]/g.test(value)
                  ? true
                  : 'At least one special character ($@%^&*!#).'),
              },
            })}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
          />
        </Tooltip>
      </Grid>

      <Grid
        item
        xs={6}
      >
        <Tooltip
          open={confirmPwdTooltipOpen}
          placement="right"
          title={(
            <Box sx={{ margin: 2 }}>
              Password should contain
              <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
                <li>8-20 characters.</li>
                <li>At least one number.</li>
                <li>At least one letter.</li>
                <li>
                  At least one special
                  <br />
                  {' '}
                  character($@%^&*!#).
                </li>
              </ul>
            </Box>
              )}
        >
          <AuthTextField
            id="passwordConfirmation"
            type="password"
            autoComplete="new-password"
            aria-label="Password confirmation"
            placeholder="Confirm password"
            onFocus={() => setConfirmPwdTooltipOpen(true)}
            sx={{ marginBottom: 0 }}
            {...register('passwordConfirmation', {
              required: 'This field is required.',
              onBlur: () => setConfirmPwdTooltipOpen(false),
              minLength: {
                value: 8,
                message: 'Minimum 8 characters.',
              },
              maxLength: {
                value: 20,
                message: 'Maximum 20 characters.',
              },
              validate: {
                passwordMatches: (value) => {
                  const newPassword = getValues('password');
                  return value === newPassword
                    ? true
                    : 'Password must match with the new password.';
                },
              },
            })}
            error={!!errors.passwordConfirmation}
            helperText={
                  errors.passwordConfirmation
                  && errors.passwordConfirmation.message
                }
          />
        </Tooltip>
      </Grid>

      <Grid
        item
        xs={6}
      >
        <AuthTextField
          id="firstName"
          type="text"
          aria-label="First name"
          placeholder="First name"
          sx={{ marginBottom: 0 }}
          {...register('firstName', {
            required: 'This field is required.',
            maxLength: {
              value: 100,
              message: 'Maximum 100 characters.',
            },
          })}
          error={!!errors.firstName}
          helperText={errors.firstName && errors.firstName.message}
        />
      </Grid>

      <Grid
        item
        xs={6}
      >
        <AuthTextField
          id="lastName"
          type="text"
          aria-label="Last name"
          placeholder="Last name"
          sx={{ marginBottom: 0 }}
          {...register('lastName', {
            required: 'This field is required.',
            maxLength: {
              value: 100,
              message: 'Maximum 100 characters.',
            },
          })}
          error={!!errors.lastName}
          helperText={errors.lastName && errors.lastName.message}
        />
      </Grid>

      <Grid
        item
        xs={6}
      >
        <AuthTextField
          id="organization"
          type="text"
          aria-label="Organization"
          placeholder="Organization"
          sx={{ marginBottom: 0 }}
          {...register('organization', {
            required: 'This field is required.',
            maxLength: {
              value: 100,
              message: 'Maximum 100 characters.',
            },
          })}
          error={!!errors.organization}
          helperText={errors.organization && errors.organization.message}
        />
      </Grid>

      <Grid
        item
        xs={6}
      >
        <AuthTextField
          id="job"
          type="text"
          aria-label="Job title"
          placeholder="Job title"
          sx={{ marginBottom: 0 }}
          {...register('job', {
            required: 'This field is required.',
            maxLength: {
              value: 100,
              message: 'Maximum 100 characters.',
            },
          })}
          error={!!errors.job}
          helperText={errors.job && errors.job.message}
        />
      </Grid>
    </>

  );
}
export default PersonalDetails;
