import { map } from 'lodash';

import { LabelElement } from 'src/api/biomarkers/models';
import { ControllerProps, WithSubChildControllerProps } from 'src/types';
import BaseOption from './BaseOption';
import SubOption from './SubOption/SubOption';

import { SingleOptionProps } from '../../models';

function LabelWithChildrenOption({
  uiElement,
  config,
  disableSubOptionToggleButtons,
  isInTooltip,
  subOptionStates,
  inLastIndex = true,
  showToggleButton,
  ...baseOptionProps
}: SingleOptionProps<WithSubChildControllerProps | ControllerProps> & {
  uiElement: LabelElement
}) {
  const { childController, ...elementController } = baseOptionProps.elementController;

  function hasStateControls():boolean {
    return !!(config?.childOptions === 'show-with-state');
  }

  function isLast(index:number):boolean {
    return inLastIndex
      ? index === uiElement.elements.length - 1
      : index === uiElement.elements.length - 2;
  }

  if (isInTooltip) {
    // TODO: handle rendering an option in tooltip
    return null;
  }

  if (hasStateControls() && childController) {
    return (
      <>
        <BaseOption
          {...baseOptionProps}
          {...elementController}
          content={uiElement.content}
        />
        {map(uiElement.elements, (element, index) => (
          <SubOption
            key={element.id}
            uiElement={element}
            disableToggleButton={disableSubOptionToggleButtons}
            last={isLast(index)}
            on={childController[index].on || false}
            toggle={childController[index].toggle}
            visible={childController[index].visible}
            isInTooltip={isInTooltip}
            showToggleButton={showToggleButton}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <BaseOption
        {...baseOptionProps}
        {...elementController}
        content={uiElement.content}
      />
      {map(uiElement.elements, (element, index) => (
        <SubOption
          key={element.id}
          uiElement={element}
          disableToggleButton
          last={isLast(index)}
          isInTooltip={isInTooltip}
          showToggleButton={showToggleButton}
        />
      ))}
    </>
  );
}

export default LabelWithChildrenOption;
