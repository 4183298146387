import type { ComponentsOverrides, ComponentsProps } from '@mui/material/styles'

const styleOverrides: ComponentsOverrides['MuiDialog'] = {
  paper: {
    borderRadius: '10px',
  },
  paperWidthSm: {
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.5)',
  },
}
const defaultProps: ComponentsProps['MuiDialog'] = {
  container: () => document.getElementById('mui-dialog-root'),
}
export default {
  styleOverrides,
  defaultProps,
}
