import { isDropdownElement, isJetHeatmapElement, isLabelElement, UIElement } from 'src/api/biomarkers/models';
import { ElementConfig } from 'src/types';
import TooltipLabelOption from './TooltipOptionVariants/TooltipLabelOption';
import { TooltipSpacer } from '../styled';
import TooltipHeatmapOption from './TooltipOptionVariants/TooltipHeatmapOption';

interface Props {
  element:UIElement
  config?:ElementConfig
}

function SingleTooltipOption({ element, config }:Props) {
  if (isLabelElement(element)) {
    return (
      <TooltipLabelOption
        element={element}
        elementInTitleTooltipConfig={config?.elementInTitleTooltipConfig}
      />
    );
  }
  if (isJetHeatmapElement(element)) {
    return (
      <TooltipHeatmapOption element={element} />
    );
  }
  return null;
}

function TooltipOption({ element, config }:Props) {
  if (isDropdownElement(element)) {
    return (
      <>
        {element.elements.map((e, i) => (
          <>
            <SingleTooltipOption
              element={e}
            />
            {i <= element.elements.length - 1
            && <TooltipSpacer height="2.5px" />}
          </>
        ))}
      </>
    );
  }
  return (
    <SingleTooltipOption
      element={element}
      config={config}
    />
  );
}

export default TooltipOption;
