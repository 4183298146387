import { ListItemIcon, ListItemText } from '@mui/material';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { CheckIcon } from '@lunit/scope-icons';
import clsx from 'clsx';

export interface SelectItemProps extends MenuItemProps {
  checked?: boolean
}

function SelectItem({
  checked,
  children,
  className: rawClassName,
  ...props
}: SelectItemProps): ReturnType<typeof MenuItem> {
  const className: string | undefined = !checked
    ? rawClassName
    : clsx(rawClassName, 'Mui-selected');

  return (
    <MenuItem
      {...props}
      className={className}
    >
      <ListItemIcon
        sx={{
          marginRight: 1,
          opacity: 0,
          '.Mui-selected &': { opacity: 1 },
        }}
      >
        <CheckIcon />
      </ListItemIcon>
      <ListItemText>{children}</ListItemText>
    </MenuItem>
  );
}

export default SelectItem;
