import { SectionListItem, Typography } from '../styled';

interface Props {
  children:React.ReactNode
}

function AnalysisFootnotes({ children }:Props) {
  return (
    <SectionListItem
      sx={{
        padding: (theme) => theme.spacing(1, 2.5),
        height: 48,
        flex: '0 0 48px',
      }}
    >
      <Typography
        variant="caption"
        color="darkGrey.30"
      >
        <>
          {children}
        </>
      </Typography>
    </SectionListItem>
  );
}
export default AnalysisFootnotes;
