import AnalysisSummaryPanel from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/AnalysisSummaryPanel';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import TumorPurityChart from './TumorPurityChart';
import TumorPurityFootnotes from './TumorPurityFootnotes';
import TumorPurityTable from './TumorPurityTable';

interface TumorPurityAnalysisSummaryProps {
  summary: AnalysisData[] | undefined
}

function TumorPurityAnalysisSummary({ summary }: TumorPurityAnalysisSummaryProps) {
  return (
    <AnalysisSummaryPanel>
      {!!summary && (
      <>
        <TumorPurityChart summaryItem={summary[0]} />
        <TumorPurityTable analysisData={summary.slice(1)} />
        <TumorPurityFootnotes />
      </>
      )}
    </AnalysisSummaryPanel>
  );
}

export default TumorPurityAnalysisSummary;
