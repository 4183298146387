import { createAPIQuery } from '@lunit/scope-components/api/utils/apiQueryHelper';
import { FetchMethod, request } from '../utils/apiRequestHelper';
import { BiomarkerMetadata, Biomarkers } from './models';

export const getBiomarkers = createAPIQuery(
  () => request<Biomarkers>({
    method: FetchMethod.Get,
    path: 'biomarkers',
  }),
  () => ['biomarkers'],
);

export const getSlideData = createAPIQuery(
  (projectPath: string) => request<BiomarkerMetadata>({
    method: FetchMethod.Get,
    path: `biomarkers/meta-data?path=${projectPath}`,
  }),
  (projectPath: string) => ['Biomarker_Metadata_For', projectPath],
);

export const getSlideMsgpackPresignedUrl = createAPIQuery(
  (msgpackPath: string) => request<string>({
    method: FetchMethod.Get,
    path: `biomarkers/msgpack?path=${encodeURIComponent(msgpackPath)}`,
    getText: true,
  }),
  (msgpackPath: string) => ['Biomarker_Msgpack_Data_For', msgpackPath],
);
