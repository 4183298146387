import { useMemo } from 'react';
import { filter } from 'lodash';

import { ToggleButtonGroupProps } from '@mui/material';
import { UIComponents } from 'src/api/biomarkers/models';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import { IntensityProportionTable } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements';
import { findLabelElementByContent } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/func';

import AnalysisSummaryPanel from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/AnalysisSummaryPanel';
import useHER2SummarySelection from '../useHER2SummarySelection';
import HER2Chart from './HER2Chart';
import HER2Buttons from './HER2Buttons';
import HER2Footnotes from './HER2Footnotes';

interface HER2AnalysisSummaryProps {
  summary: AnalysisData[] | undefined
  uiComponents:UIComponents
}

function HER2AnalysisSummary({
  summary,
  uiComponents,
}: HER2AnalysisSummaryProps) {
  const summaryTab = useHER2SummarySelection();
  const handleSetReadType: ToggleButtonGroupProps['onChange'] = (_, value) => {
    if (!value) return;
    summaryTab.set(value);
  };
  const selectedSummary = useMemo(
    () => filter(summary, (data) => data.name === summaryTab.value)[0],
    [summary, summaryTab.value],
  );
  const cellDetectionElement = findLabelElementByContent(uiComponents.elements, 'Cell Detection');
  return (
    <AnalysisSummaryPanel>
      <HER2Buttons
        active={summaryTab.value}
        onChange={handleSetReadType}
      />
      {selectedSummary && (
      <>
        <HER2Chart
          summaryItem={selectedSummary}
          cellDetectionElement={cellDetectionElement}
        />
        {selectedSummary.items && (
        <IntensityProportionTable
          summaryItem={selectedSummary.items[1]}
          cellDetectionElement={cellDetectionElement!}
        />
        )}
        <HER2Footnotes />
      </>
      )}
    </AnalysisSummaryPanel>
  );
}

export default HER2AnalysisSummary;
