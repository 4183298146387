export default function useQueryParams<
  Params extends { [K in keyof Params]?: string | string[] | undefined } = {},
>(): Params {
  const params = {} as Params;
  const searchParams = new URLSearchParams(
    new URL(window.location.href).search,
  );

  Array.from(searchParams.entries()).forEach(([key, value]) => {
    if (typeof params[key] === 'undefined') {
      params[key] = value;
    } else if (Array.isArray(params[key])) {
      params[key].push(value);
    } else {
      params[key] = [params[key], value];
    }
  });
  return params;
}
