import { LoadingIndicator } from '@lunit/scope-components';
import { useRecoilValue } from 'recoil';
import { dummyToolState } from 'src/const';
import { slideOpenAtom } from 'src/state';
import { ProjectPageProps } from 'src/types';
import SlideViewerContainer from '../SlideViewer/SlideViewerContainer';
import { ViewOptionsAnalysisPanel } from '../ViewOptionsPanel';

interface Props extends ProjectPageProps {
  viewer:React.ReactNode,
  analysisSummaryPanel:React.ReactNode
  isLoadingResultData:boolean
}

function ProjectPage({
  viewer,
  analysisSummaryPanel,
  metadata,
  projectConfig,
  isLoadingResultData,
  infoTooltipConfig,
}:Props) {
  const slideOpen = useRecoilValue(slideOpenAtom);

  return (
    <>
      <SlideViewerContainer
        toolState={dummyToolState}
        slideViewport={viewer}
        sidePanel={(
          <ViewOptionsAnalysisPanel
            uiComponents={metadata.uiComponents}
            config={projectConfig}
            AnalysisSummary={
              analysisSummaryPanel
            }
            infoTooltipConfig={infoTooltipConfig}
          />
        )}
      />
      {(isLoadingResultData || !slideOpen) && (
      <LoadingIndicator styles={{ zIndex: 2000 }} />
      )}
    </>
  );
}
export default ProjectPage;
