import { Checkbox, FormHelperText, FormLabel, Grid, Link, Typography } from '@mui/material';
import { map, sortBy } from 'lodash';
import { Controller, Control, UseFormRegister } from 'react-hook-form';
import { SignUpInfo } from 'src/api/auth/models';

const TERMS_LINKS = ['/service', '/personal-info', '/newsletter'];

interface Props {
  register: UseFormRegister<any>
  control: Control<any, any>
  errors:any
  serviceTerm:SignUpInfo['serviceTerm'] | undefined
}

function Consent({ register, control, errors, serviceTerm }: Props) {
  if (!serviceTerm) {
    return null;
  }
  return (
    <>
      {
        map(sortBy(serviceTerm, 'seq'), (tos, idx) => (
          <Controller
            key={tos.serviceTermId}
            name={`tos_${tos.serviceTermId}`}
            control={control}
            rules={{
              required:
              tos.required
              && 'Please check to agree to this terms of service.',
            }}
            render={() => (
              <Grid
                item
                xs={12}
              >
                <Grid
                  key={tos.serviceTermId}
                  container
                  spacing={0}
                  sx={{ mt: 1 }}
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={1}
                  >
                    <Checkbox
                      id={`tos_${tos.serviceTermId}`}
                      {...register(`tos_${tos.serviceTermId}`, {
                        required:
                            tos.required
                            && 'Please check to agree to this terms of service.',
                      })}
                    />

                  </Grid>
                  <Grid
                    item
                    xs={11}
                  >

                    <FormLabel component="legend">

                      <Typography
                        variant="body5"
                        color="darkGrey.30"
                      >
                        {!tos.required && <>(Optional) &nbsp;</>}
                        I have read and
                        fully agreed to the&nbsp;
                        <Link
                          href={TERMS_LINKS[idx]}
                          target="_blank"
                        >
                          terms of&nbsp;
                          {tos.terms === 'TERMS OF USE' ? 'Use' : tos.terms}
                        </Link>
                      </Typography>
                    </FormLabel>
                  </Grid>
                  {errors[`tos_${tos.serviceTermId}`] && (
                    <FormHelperText
                      sx={{ color: (theme) => theme.palette.error.main }}
                    >
                      {errors[`tos_${tos.serviceTermId}`].message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            )}
          />
        ))
}

    </>
  );
}
export default Consent;
