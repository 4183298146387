/* eslint-disable no-param-reassign */
import OpenSeadragon from 'openseadragon';
import { CoordData } from 'src/workers/AnalysisResultWorker/common/types';
import { source, vertexAttributeConfig } from './const';
import { clampedPointSize, setProgram } from './func';
import { Origin } from './types';
import WebGLTile from './webGLTile';

function useWebGLCellDrawing() {
  let program: WebGLProgram | undefined;
  let positionAttrLocation: number;
  let resolutionUniformLocation: WebGLUniformLocation | null;
  let colorUniformLocation: WebGLUniformLocation | null;
  let offsetUniformLocation: WebGLUniformLocation | null;
  let pointSizeUniformLocation: WebGLUniformLocation | null;

  function drawWithWebGL(
    gl: WebGL2RenderingContext,
    ctx: CanvasRenderingContext2D,
    tile: WebGLTile,
    zoom: number,
    origin:Origin,
  ) {
    if (!program) program = setProgram(gl, source);
    if (!program) {
      console.warn('failed to set webGL program');
      return;
    }

    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);

    // initialize memory locations
    if (!positionAttrLocation) positionAttrLocation = gl.getAttribLocation(program, 'a_position');
    if (!resolutionUniformLocation) resolutionUniformLocation = gl.getUniformLocation(program, 'u_resolution');
    if (!colorUniformLocation) colorUniformLocation = gl.getUniformLocation(program, 'u_color');
    if (!offsetUniformLocation) offsetUniformLocation = gl.getUniformLocation(program, 'u_offset');
    if (!pointSizeUniformLocation) pointSizeUniformLocation = gl.getUniformLocation(program, 'u_pointSize');

    const pointSize = clampedPointSize(zoom, 4);
    // place vertices into webgl memory
    const positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, tile.coordBuffer, gl.STATIC_DRAW);
    // set up canvas before drawing
    gl.canvas.width = tile.w * origin.zoom;
    gl.canvas.height = tile.h * origin.zoom;
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);
    // activate program
    gl.useProgram(program);

    // Turn on the attribute
    gl.enableVertexAttribArray(positionAttrLocation);

    // Tell the attribute how to get data out of positionBuffer (ARRAY_BUFFER)
    gl.vertexAttribPointer(
      positionAttrLocation,
      vertexAttributeConfig.size,
      gl.FLOAT,
      vertexAttributeConfig.normalize,
      vertexAttributeConfig.stride,
      vertexAttributeConfig.offset,
    );

    gl.uniform2f(resolutionUniformLocation, tile.w, tile.h);
    gl.uniform2f(offsetUniformLocation, tile.x, tile.y);
    gl.uniform4f(
      colorUniformLocation,
      tile.rgba.r,
      tile.rgba.g,
      tile.rgba.b,
      tile.rgba.a,
    );
    gl.uniform1i(pointSizeUniformLocation, pointSize);
    // draw
    gl.drawArrays(
      gl.POINTS, // what shape
      0, // starting from where in the buffer
      tile.coordBuffer.length / 2, // how many shapes
    );
    // move webGL rendered image to 2d canvas
    ctx.drawImage(
      gl.canvas,
      0,
      0,
      gl.canvas.width,
      gl.canvas.height,
    );
  }

  function handleCellDrawing(
    gl: WebGL2RenderingContext,
    ctx: CanvasRenderingContext2D,
    viewer: OpenSeadragon.Viewer,
    coordData:CoordData,
    zoom: number,
    origin:Origin,
  ) {
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

    const bounds = viewer.viewport.viewportToImageRectangle(
      viewer.viewport.getBounds(true),
    );
    const { indexedCoords } = coordData;
    if (indexedCoords) {
      const cellsToDraw:number[] = [];
      indexedCoords
        .range(
          bounds.x,
          bounds.y,
          bounds.x + bounds.width,
          bounds.y + bounds.height,
        ).forEach((index) => {
          cellsToDraw.push(coordData.coordinates[index].x, coordData.coordinates[index].y);
        });
      drawWithWebGL(
        gl,
        ctx,
        new WebGLTile(
          bounds.x,
          bounds.y,
          bounds.width,
          bounds.height,
          coordData.color,
          cellsToDraw,
        ),
        zoom,
        origin,
      );
    }
  }

  return { drawWithWebGL, handleCellDrawing };
}
export default useWebGLCellDrawing;
