import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { MenuOpenIcon, MenuIcon } from '@lunit/scope-icons';
import Tooltip from '@mui/material/Tooltip';
import { useContext, useEffect, useRef, useState, ReactNode } from 'react';
import HeaderButton from '../HeaderButton';
import { ResizeObserverContext } from '../ResizeObserverContext';

interface IHeaderProps {
  homeLink?: React.ReactNode
  drawerButtonOnLeft?: boolean
  drawerOpen: boolean
  onDrawerToggle: () => void
  renderLabelDialog?: (open: boolean) => React.ReactNode
  rightContainer: ReactNode
  hasDrawer: boolean
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 2),
  minHeight: 'inherit',
}));

const LeftContainer = styled('div')({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  minHeight: 'inherit',
  flex: 1,
});

const RightContainer = styled('div')({
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'row-reverse',
});

function Header({
  drawerOpen,
  drawerButtonOnLeft,
  onDrawerToggle,
  homeLink,
  renderLabelDialog,
  rightContainer,
  hasDrawer,
}: IHeaderProps) {
  const [labelDialogOpen] = useState(false);
  const appBarRef = useRef<HTMLDivElement>(null);
  const prevWidthRef = useRef(0);
  const { addResizeHandler, removeResizeHandler } = useContext(
    ResizeObserverContext,
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const target = appBarRef.current;
    const resizeHandler = (localTarget: Element) => {
      if (
        prevWidthRef.current >= 1920
        && localTarget.clientWidth < 1920
        && drawerOpen
      ) {
        onDrawerToggle();
      }
      prevWidthRef.current = localTarget.clientWidth;
    };
    if (target) {
      addResizeHandler(target, resizeHandler);
      return () => removeResizeHandler(target);
    }
  }, [addResizeHandler, removeResizeHandler, drawerOpen, onDrawerToggle]);

  return (
    <AppBar
      position="fixed"
      color="primary"
      ref={appBarRef}
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        transition: (theme) => theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
    >
      <Toolbar disableGutters>
        <Container>
          {
            hasDrawer ? (
              <>
                { !drawerButtonOnLeft && homeLink}
                <Tooltip title={drawerOpen ? 'Close side bar' : 'Open side bar'}>
                  <HeaderButton
                    onClick={onDrawerToggle}
                    id="drawer-toggle"
                  >
                    {drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
                  </HeaderButton>
                </Tooltip>
                { drawerButtonOnLeft && homeLink}
              </>
            ) : (
              <>{ homeLink }</>
            )
          }
          <LeftContainer id="toolbar-container" />
          <RightContainer>
            {rightContainer}
          </RightContainer>
        </Container>
      </Toolbar>
      {!!renderLabelDialog && renderLabelDialog(labelDialogOpen)}
    </AppBar>
  );
}

export default Header;
