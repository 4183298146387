import { useMatch } from 'react-router-dom';
import { BiomarkerMetadata } from 'src/api/biomarkers/models';

function useSlideFromPath(results?: BiomarkerMetadata['results']) {
  const match = useMatch('/:productPath/:slideDesc');

  let currentSlide;
  if (results?.length && match) {
    currentSlide = results.find((s) => s.description === match.params.slideDesc);
  }
  return { currentSlide };
}
export default useSlideFromPath;
