import { map } from 'lodash';
import { isMarkerLabelElement, LabelElement, MarkerLabelElement } from 'src/api/biomarkers/models';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import { Box, SectionContent, SectionListItem, Typography } from '../styled';
import { findSummaryItemByTitle, roundToFirstDecimal, getNumberFromString, formatUnit } from './func';
import { CenteredBox, DenseSectionListItem } from './styled';

interface Props {
  title:string,
  cellTitles:string[],
  sourceComponent: LabelElement | undefined
  summary:AnalysisData[] | undefined

}

function LegendaryBarChart({ title, cellTitles, sourceComponent, summary }:Props) {
  const getColorFromElement = (chartTitle: string) => sourceComponent?.elements.find(
    (ipType): ipType is MarkerLabelElement => ipType.content === chartTitle
      && isMarkerLabelElement(ipType),
  )?.color;

  return (
    <>
      <SectionListItem>
        <Typography variant="body5">{title}</Typography>
      </SectionListItem>
      <SectionContent sx={{ padding: '6px 20px' }}>
        {map(cellTitles, (t) => {
          const summaryItem = findSummaryItemByTitle(summary, t);
          return (
            summaryItem && (
            <DenseSectionListItem key={`${summaryItem.name}`}>
              <CenteredBox>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    backgroundColor:
                    summaryItem.name
                    && getColorFromElement(summaryItem.name),
                    marginRight: 1,
                  }}
                />
                <Typography
                  color="darkGrey.20"
                  variant="body5"
                >
                  {summaryItem.name}
                </Typography>
              </CenteredBox>
              <CenteredBox>
                <Typography variant="body4">
                  {`${roundToFirstDecimal(getNumberFromString(summaryItem.value))}${
                    summaryItem.unit && summaryItem.unit !== 'Integer'
                      ? `${formatUnit(summaryItem.unit)}`
                      : ''
                  }`}
                </Typography>
              </CenteredBox>
            </DenseSectionListItem>
            )
          );
        })}
      </SectionContent>
      <SectionContent sx={{ padding: '9px 20px', display: 'flex' }}>
        {map(cellTitles, (t) => {
          const summaryItem = findSummaryItemByTitle(summary, t);
          return (
            summaryItem && (
            <Box
              key={`${t}_bar`}
              sx={{
                width: `${roundToFirstDecimal(
                  getNumberFromString(summaryItem.value),
                )}%`,
                backgroundColor:
                  summaryItem.name
                  && getColorFromElement(summaryItem.name),
                height: 10,
              }}
            />
            )
          );
        })}
      </SectionContent>
    </>
  );
}
export default LegendaryBarChart;
