import { useEffect, useMemo, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  isDropdownElement,
  isJetHeatmapElement,
  UIComponents,
} from 'src/api/biomarkers/models';
import { projectStateAtom } from 'src/state';

import { ChildrenState, ConfiguredProject, ProjectConfigSpec } from 'src/types';

function useConfiguredProject(
  projectConfigSpec: ProjectConfigSpec,
  uiComponents: UIComponents,
): ConfiguredProject {
  const setProjectState = useSetRecoilState(projectStateAtom);
  const utilizeHoverSubShowHideRef = useRef<boolean>(false);
  const { elements } = uiComponents;
  const { elementConfig } = projectConfigSpec;

  const elementState: ChildrenState = useMemo(
    () => elements.map((element, index) => {
      if (
        elementConfig[index].childOptions === 'show-with-state'
          && !utilizeHoverSubShowHideRef.current
      ) {
        utilizeHoverSubShowHideRef.current = true;
      }

      if (isJetHeatmapElement(element)) {
        return { on: true };
      }

      if (isDropdownElement(element)) {
        return {
          on: true,
          active: element.elements[0].content,
        };
      }

      if (elementConfig[index].childOptions === 'show-with-state') {
        return {
          on: true,
          childrenOn: element.elements.map(() => ({ on: true })),
        };
      }

      return { on: true };
    }),
    [elements, elementConfig],
  );

  const projectInitialState = useMemo(
    () => ({
      on: true,
      childState: elementState,
    }),
    [elementState],
  );

  useEffect(() => {
    setProjectState(projectInitialState);
  }, [projectInitialState, setProjectState]);

  return useMemo(
    () => ({
      ...projectConfigSpec,
      utilizeHoverSubShowHide: utilizeHoverSubShowHideRef.current,
    }),
    [projectConfigSpec, utilizeHoverSubShowHideRef],
  );
}

export default useConfiguredProject;
