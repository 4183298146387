import FormTemplate from '@lunit/scope-components/Form/Template';
import Select from '@lunit/scope-components/Select/Select';

import {
  AlertColor,
  Button,
  InputLabel,
  Typography,
  TextField,
} from '@mui/material';

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import countryList from 'country-list';

import * as userAPI from 'src/api/auth/queries';
import { useMyInfoQuery } from 'src/hooks/useAuthQueries';
import SelectItem from '@lunit/scope-components/Select/SelectItem';
import AuthAlert from '@lunit/scope-components/Auth/AuthAlert';
import { DialogContext } from '@lunit/scope-components/Dialog/context';
import { removeTokensAndRedirectToLogin } from 'src/pages/auth/utils';
import { APIError, ErrorMessage } from 'src/api/utils/apiRequestHelper';
import useSnackbar from 'src/hooks/useSnackbar';
import { FormRow } from './styled';

interface ProfileDialogProps {
  open: boolean
  onCancel: () => void
}

interface Alert {
  severity: AlertColor
  message: string
}

const FIELD_NAMES = [
  'email',
  'firstName',
  'lastName',
  'organization',
  'job',
  'country',
];

function ProfileDialog({ open, onCancel }: ProfileDialogProps) {
  const { handleDialog: openDialog } = useContext(DialogContext);
  const { enqueueSnackbar } = useSnackbar();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const alert: Alert | null = useMemo(() => {
    if (errorMessage) {
      return {
        severity: 'error',
        message: errorMessage,
      };
    }
    return null;
  }, [errorMessage]);

  const { data: user, isLoading: isQueryLoading, refetch } = useMyInfoQuery();

  const { mutate: updateProfile, isLoading: isMutationLoading } = useMutation(
    userAPI.updateUser,
    {
      onSuccess: () => {
        refetch();
        onCancel();
        enqueueSnackbar(
          'Successfully saved.',
          { variant: 'success' },
          { isLight: true },
        );
      },
      onError: (error: APIError) => {
        if (error.message === ErrorMessage.FetchError || error.code === 0) {
          setErrorMessage('Connection unavailable.');
        } else {
          setErrorMessage(`Something went wrong: ${error.details}`);
        }
      },
    },
  );

  const { mutate: deleteUser } = useMutation(userAPI.deleteUser, {
    onSuccess: () => {
      removeTokensAndRedirectToLogin();
    },
  });

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = useCallback(
    (data: any) => {
      setErrorMessage('');
      updateProfile(data);
    },
    [updateProfile],
  );

  useEffect(
    () => {
      refetch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSubmitError = (err: any) => {
    setErrorMessage('');
    let errorMessageSet = false;
    FIELD_NAMES.forEach((name) => {
      if (err[name] && !errorMessageSet) {
        setErrorMessage(err[name].message);
        errorMessageSet = true;
      }
    });
  };

  useEffect(() => {
    reset({ ...user });
  }, [reset, user]);

  const isLoading = isQueryLoading || isMutationLoading;

  const COUNTRIES = countryList.getNames().sort();

  const onDeleteAccountClick = useCallback(() => {
    openDialog({
      width: 320,
      title: 'Delete my account',
      content: (
        <Typography variant="body5">
          This action cannot be undone. This will permanently delete your
          account.
        </Typography>
      ),
      agree: 'Delete',
      agreeButtonSx: { background: '#FA4D56' },
      handleAgreementCallback: () => {
        deleteUser();
      },
    });
  }, [openDialog, deleteUser]);

  return (
    <FormTemplate
      title="Your profile"
      open={open}
      onCancel={() => {
        onCancel();
        reset();
        setErrorMessage('');
      }}
      width={500}
      loading={isLoading}
      isValidating={isLoading}
      onSubmit={handleSubmit(onSubmit, onSubmitError)}
      submitActionName="Update"
      additionalAction={(
        <Button
          sx={{ color: '#FA4D56' }}
          onClick={onDeleteAccountClick}
        >
          Delete my account
        </Button>
      )}
    >
      <FormRow>
        <InputLabel
          htmlFor="email"
          required
        >
          <Typography
            variant="body3"
            color="darkGrey.5"
          >
            Email
          </Typography>
        </InputLabel>
        <TextField
          id="email"
          name="email"
          variant="outlined"
          disabled
          defaultValue={user?.email}
          type="email"
        />
      </FormRow>
      <FormRow>
        <InputLabel
          htmlFor="firstName"
          required
        >
          <Typography
            variant="body3"
            color="darkGrey.5"
          >
            First name
          </Typography>
        </InputLabel>
        <TextField
          id="firstName"
          variant="outlined"
          defaultValue={user?.firstName}
          type="firstName"
          placeholder="First name"
          {...register('firstName', {
            required: 'This field is required.',
            maxLength: {
              value: 100,
              message: 'Maximum 100 characters.',
            },
          })}
          error={!!errors.firstName}
        />
      </FormRow>
      <FormRow>
        <InputLabel
          htmlFor="lastName"
          required
        >
          <Typography
            variant="body3"
            color="darkGrey.5"
          >
            Last name
          </Typography>
        </InputLabel>
        <TextField
          id="lastName"
          variant="outlined"
          defaultValue={user?.lastName}
          type="lastName"
          placeholder="Last name"
          {...register('lastName', {
            required: 'This field is required.',
            maxLength: {
              value: 100,
              message: 'Maximum 100 characters.',
            },
          })}
          error={!!errors.lastName}
        />
      </FormRow>
      <FormRow>
        <InputLabel
          htmlFor="organization"
          required
        >
          <Typography
            variant="body3"
            color="darkGrey.5"
          >
            Organization
          </Typography>
        </InputLabel>
        <TextField
          id="organization"
          variant="outlined"
          defaultValue={user?.organization}
          type="organization"
          placeholder="Organization"
          {...register('organization', {
            required: 'This field is required.',
            maxLength: {
              value: 100,
              message: 'Maximum 100 characters.',
            },
          })}
          error={!!errors.organization}
        />
      </FormRow>
      <FormRow>
        <InputLabel
          htmlFor="job"
          required
        >
          <Typography
            variant="body3"
            color="darkGrey.5"
          >
            Job Title
          </Typography>
        </InputLabel>
        <TextField
          id="job"
          variant="outlined"
          defaultValue={user?.job}
          type="job"
          placeholder="job"
          {...register('job', {
            required: 'This field is required.',
            maxLength: {
              value: 100,
              message: 'Maximum 100 characters.',
            },
          })}
          error={!!errors.job}
        />
      </FormRow>
      <FormRow>
        <Controller
          name="country"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel
                htmlFor="country"
                required
              >
                <Typography
                  variant="body3"
                  color="darkGrey.5"
                >
                  Country
                </Typography>
              </InputLabel>
              <Select
                name="country"
                value={value}
                onChange={onChange}
                sx={{ width: '100%' }}
                MenuProps={{ sx: { maxHeight: 200 } }}
              >
                {COUNTRIES.map((country) => (
                  <SelectItem
                    key={country}
                    value={country}
                  >
                    {country}
                  </SelectItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormRow>
      {alert && (
        <AuthAlert
          sx={{ marginTop: 3 }}
          severity={alert.severity}
        >
          {alert.message}
        </AuthAlert>
      )}
    </FormTemplate>
  );
}

export default ProfileDialog;
