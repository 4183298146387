import { UIComponents } from 'src/api/biomarkers/models';
import { AnalysisAccordion, AnalysisTable, LegendaryBarChart } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements';
import { findLabelElementByContent } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/func';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';

const REGION_TITLES = [
  'Total Tissue Region',
  'Cancer Region',
  'Analyzable Region',
];

const IP_PROPORTION_TITLES = ['Inflamed', 'Immune-Excluded', 'Immune-Desert'];

interface Props {
  summary: AnalysisData[] | undefined
  uiComponents: UIComponents | undefined
}

function IOPhenotypeSummary({
  summary,
  uiComponents,
}: Props) {
  const sourceComponent = findLabelElementByContent(uiComponents?.elements, 'Immune Phenotype Map');
  return (
    <AnalysisAccordion title="Immune Phenotype Findings">
      <AnalysisTable
        summary={summary}
        titles={REGION_TITLES}
      />
      <LegendaryBarChart
        title="Immune Phenotype Proportion"
        cellTitles={IP_PROPORTION_TITLES}
        sourceComponent={sourceComponent}
        summary={summary}
      />
    </AnalysisAccordion>
  );
}

export default IOPhenotypeSummary;
