import type { ComponentsOverrides, ComponentsProps } from '@mui/material/styles'
import typographyOptions from '../system/typography'

const styleOverrides: ComponentsOverrides['MuiSelect'] = {
  outlined: {
    ...typographyOptions.body5,
    cursor: 'pointer',
  },
}
const defaultProps: ComponentsProps['MuiSelect'] = {
  MenuProps: {},
}
export default {
  styleOverrides,
  defaultProps,
}
