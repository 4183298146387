import MarkerLabelOption from './MarkerLabelOption';
import OpacityHeatmapOption from './OpacityHeatmapOption';
import SubLabelOption from './SubLabelOption';

import {
  isMarkerLabelElement,
  isOpacityHeatmapElement,
  isSubLabelElement,
} from '../../../../../../api/biomarkers/models';
import { SubOptionProps } from '../../../models';

function SubOption({ uiElement, ...baseSubOptionProps }: SubOptionProps) {
  if (isMarkerLabelElement(uiElement)) {
    return (
      <MarkerLabelOption
        uiElement={uiElement}
        {...baseSubOptionProps}
      />
    );
  }
  if (isSubLabelElement(uiElement)) {
    return <SubLabelOption uiElement={uiElement} />;
  }

  if (isOpacityHeatmapElement(uiElement)) {
    return <OpacityHeatmapOption uiElement={uiElement} />;
  }
  // implement other SubOption types (JetHeatmapElement)
  return null;
}

export default SubOption;
