import { useState, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';

import AuthTemplate from '@lunit/scope-components/Auth/AuthTemplate';
import AuthHeading from '@lunit/scope-components/Auth/AuthHeading';
import AuthTextField from '@lunit/scope-components/Auth/AuthTextField';
import AuthActionButton from '@lunit/scope-components/Auth/AuthActionButton';
import AuthNav from '@lunit/scope-components/Auth/AuthNav';
import AuthDescription from '@lunit/scope-components/Auth/AuthDescription';
import AuthAlert from '@lunit/scope-components/Auth/AuthAlert';
import { APIError } from '@lunit/scope-components/api/models';
import useQueryParams from '@lunit/scope-components/hooks/useQueryParams';
import { Link } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';

import { ErrorMessage } from 'src/api/utils/apiRequestHelper';
import * as authAPI from '../../api/auth/queries';

interface Alert {
  severity: AlertColor
  message: string
}

interface ForgotPasswordQueryParams {
  expired: string
}

function ForgotPassword() {
  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  const { expired: rawExpired } = useQueryParams<ForgotPasswordQueryParams>();
  const isExpired = rawExpired != null;

  const {
    register,
    handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const alert: Alert | null = useMemo(() => {
    if (isExpired) {
      return {
        severity: 'error',
        message: 'The link is expired. Please request again.',
      };
    }
    if (errorMessage) {
      return {
        severity: 'error',
        message: errorMessage,
      };
    }
    if (successMessage) {
      return {
        severity: 'success',
        message: successMessage,
      };
    }
    return null;
  }, [isExpired, errorMessage, successMessage]);

  const forgotPassword = useMutation(authAPI.forgotPassword, {
    onSuccess: () => {
      setSuccessMessage(
        'Successfully sent you an email. Please check your email now.',
      );
    },
    onError: (error: APIError) => {
      if (ErrorMessage.BadRequest) {
        setErrorMessage('Something went wrong. Please try again.');
      }
      setErrorMessage(error.details);
    },
  });

  const onSubmit = () => {
    setSuccessMessage('');
    setErrorMessage('');
    forgotPassword.mutate({ email });
  };

  const onSubmitError = (err: any) => {
    setErrorMessage('');
    let errorMessageSet = false;
    if (err.email && !errorMessageSet) {
      setErrorMessage(err.email.message);
      errorMessageSet = true;
    }
  };

  const handleEmailEntry = (value:string) => {
    if (value !== value.toLowerCase()) {
      setErrorMessage('The email address should be lowercase');
    } else {
      setErrorMessage('');
    }
    setEmail(value.toLowerCase());
  };

  return (
    <AuthTemplate logo="/logo-scope-r.svg">
      <form
        onSubmit={handleSubmit(onSubmit, onSubmitError)}
        noValidate
      >
        <AuthHeading>Forgot password?</AuthHeading>
        <AuthNav>
          <Link
            href="/login"
            sx={{ textAlign: 'right' }}
          >
            Back to sign-in
          </Link>
        </AuthNav>
        <AuthDescription sx={{ marginTop: 5 }}>
          Please enter your registered email. A link will be sent to your email
          account to reset your password.
          <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
            <li>
              The link will remain
              {' '}
              <b>valid for 24 hours</b>
              {' '}
              for security reasons.
            </li>
            <li>Please be aware of capital letters.</li>
            <li>
              Please contact the support email, if you forgot your registered
              email address.
            </li>
            <li>
              Please check junk, spam, or other folders, if you can&apos;t find the
              email.
            </li>
          </ul>
        </AuthDescription>
        <AuthTextField
          id="email"
          type="email"
          aria-label="Business email"
          placeholder="Business email"
          value={email}
          {...register('email', {
            required: 'This field is required.',
            // validate: (value) => validateCaseSensitive(value),
          })}
          onChange={(e) => handleEmailEntry(e.target.value)}
          error={!!errors.email}
        />
        <AuthActionButton>Send</AuthActionButton>
        {alert && (
          <AuthAlert
            sx={{ marginTop: 3 }}
            severity={alert.severity}
          >
            {alert.message}
          </AuthAlert>
        )}
        <AuthNav>
          <Link
            href="mailto:cs-scope@lunit.io"
            target="_blank"
            sx={{ textAlign: 'right' }}
          >
            cs-scope@lunit.io
          </Link>
        </AuthNav>
      </form>
    </AuthTemplate>
  );
}

export default ForgotPassword;
