import { UIComponents } from 'src/api/biomarkers/models';
import { SummaryDivider } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/styled';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import AnalysisSummaryPanel from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/AnalysisSummaryPanel';
import IOChart from './IOChart';
import IOPhenotypeSummary from './IOPhenotypeSummary';
import IODensitySummary from './IODensitySummary';

interface IOAnalysisSummaryProps {
  summary: AnalysisData[] | undefined
  uiComponents: UIComponents | undefined
}

function IOAnalysisSummary({ summary, uiComponents }: IOAnalysisSummaryProps) {
  return (
    <AnalysisSummaryPanel>
      {!!summary
      && (
        <>
          <IOChart summaryItem={summary[0]} />
          <SummaryDivider />
          <IOPhenotypeSummary
            summary={summary}
            uiComponents={uiComponents}
          />
          <SummaryDivider />
          <IODensitySummary summary={summary} />
        </>
      )}
    </AnalysisSummaryPanel>
  );
}

export default IOAnalysisSummary;
