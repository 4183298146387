import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import reduce from 'lodash/reduce';
import React from 'react';

export { Tooltip, Box, Divider, IconButton, Typography };

export const SectionContainer = styled('div')({
  padding: '0 0 8px 0',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const SectionHeader = styled('div')(({ theme }) => ({
  width: '100%',
  height: 48,
  paddingTop: 8,
  flex: '0 0 48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 20,
  paddingRight: 16,
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.darkGrey[85],
}));

export const SectionListItem = styled('div')({
  width: '100%',
  height: 32,
  flex: '0 0 32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '20px',
  paddingRight: '20px',
});

export const SubSectionListItem = styled(SectionListItem)({
  height: 28,
  flex: '0 0 28px',
});

export const SubViewOptionLeftContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const TooltipContainer = styled(Box)({ padding: '10px 8px', width: '214px' });

export function TooltipCaption({ children }:{ children:React.ReactNode }) {
  return (
    <Typography
      variant="caption"
      color="darkGrey.30"
      sx={{ mt: 1 }}
    >
      {children}
    </Typography>
  );
}

export const PointIndicator = styled('div')({
  marginLeft: 2,
  width: 6,
  height: 6,
  marginRight: 6,
  borderRadius: 3,
});

export const MaskIndicator = styled('div')({
  width: 10,
  height: 10,
  marginRight: 4,
});

export const SectionContent = styled('div')({
  width: '100%',
  padding: '8px 20px',
});

export const ColorMap = styled('div')({
  flex: 1,
  height: 4,
  borderRadius: 2,
});

export const STOP_WIDTH = 54;

export const ColorMapStop = styled('span')({
  position: 'absolute',
  top: 10,
  opacity: 0.9,
  width: STOP_WIDTH,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '8px',
  lineHeight: '12px',
});

export const getGradientFromColors = (colors: string[]): string => `${reduce(
  colors,
  (gradient, color, index) => `${gradient}, ${color} ${(index / colors.length) * 100}% ${
    index + (1 / colors.length) * 100
  }%`,
  'linear-gradient(to right',
)})`;

export function OpacityHeatmapBar({ color }:{ color:string }) {
  return <div style={{ background: `linear-gradient(to right, #FFF, ${color})`, height: '4px', width: '100%', borderRadius: '2px' }} />;
}

export const TooltipListItem = styled('div')({ margin: '8px 0' });

export const TooltipTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body3,
  marginBottom: 4,
}));

export const containerSx = {
  flex: '0 0 152px',
  maxHeight: 152,
};

export const headerSx = {
  // opacity: viewOptions.visible ? 1 : 0.5,
  opacity: 1,
};

export function ViewOptionsHeader({ children }:{ children:React.ReactNode }) {
  return (
    <SectionHeader>
      <Typography variant="body3">{children}</Typography>
    </SectionHeader>
  );
}

export function TooltipSpacer({ height }:{ height:string }) {
  return <div style={{ height }} />;
}
