import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Box, Link, Menu, MenuItem } from '@mui/material';
import { CheckIcon } from '@lunit/scope-icons';
import { Biomarkers } from '../../../../api/biomarkers/models';
import { Product, getProductsFromAPIProducts } from '../../../../pages/home/ProductList/models';
import ProductLogo from '../../ProductLogo';

const productOrder = ['io', 'pdl1_tps', 'pdl1_cps_breast', 'pdl1_cps_bladder', 'her2', 'tumor_purity'];

interface QuickAppMenuProp {
  isOpen: boolean
  isLoading: boolean
  anchorEl: HTMLElement | null
  products: Biomarkers['products'],
  currentProductPath:string | undefined,
  onClose: () => void
}

function AppSkeleton() {
  return (
    <Skeleton
      width={184}
      height={32}
      style={{ marginLeft: '8px' }}
    />
  );
}

function QuickAppMenu({
  isOpen,
  isLoading,
  anchorEl,
  onClose,
  products: apiProducts,
  currentProductPath,
}: QuickAppMenuProp) {
  const products = useMemo(
    () => {
      const fetchedProducts = getProductsFromAPIProducts(apiProducts);
      const sortedProducts = productOrder.map(
        (path) => fetchedProducts.find((product:Product | undefined) => product?.path === path),
      );
      return sortedProducts as Product[];
    },
    [apiProducts],
  );

  return (
    <Menu
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{
        elevation: 1,
        sx: {
          borderRadius: '8px',
          width: '366px',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {isLoading && products ? (
        <Box>
          <AppSkeleton />
          <AppSkeleton />
          <AppSkeleton />
          <AppSkeleton />
          <AppSkeleton />
          <AppSkeleton />
        </Box>
      ) : (
        products.map((product: Product) => (
          <Link
            key={product.path}
            href={`/${product?.path}`}
            sx={{ color: 'inherit' }}
          >
            <MenuItem
              sx={{
                display: 'grid',
                gap: '8px',
                gridTemplateColumns: '20px auto',
                padding: '8px 4px',
              }}
            >
              <CheckIcon style={{ visibility: product.path === currentProductPath ? 'visible' : 'hidden' }} />
              <ProductLogo product={product} />
            </MenuItem>
          </Link>
        ))
      )}
    </Menu>
  );
}

export default QuickAppMenu;
