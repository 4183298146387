import type { ComponentsOverrides } from '@mui/material/styles'
import type {} from '@mui/lab/themeAugmentation'

const styleOverrides: ComponentsOverrides['MuiTabPanel'] = {
  root: {
    padding: 0,
  },
}
export default {
  styleOverrides,
}
