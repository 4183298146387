import ProjectViewer from 'src/components/common/ProjectPage/ProjectViewer';
import { SlideUrlData } from 'src/components/common/SlideViewer/types';
import useCPSBladderVisualization from 'src/hooks/visualization/useCPSBladderVisualization';
import { ResultWorkerPreparedEventData } from 'src/workers/AnalysisResultWorker/types';

interface CPSBladderSlideViewerProps {
  slideData: SlideUrlData
  resultData?: ResultWorkerPreparedEventData
}

function CPSBladderSlideViewer(
  { slideData, resultData }: CPSBladderSlideViewerProps,
) {
  const visualization = useCPSBladderVisualization(resultData);

  return (
    <ProjectViewer
      slideData={slideData}
      resultData={resultData}
      visualization={visualization}
    />
  );
}

export default CPSBladderSlideViewer;
