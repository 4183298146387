import FormTemplate from '@lunit/scope-components/Form/Template';
import AuthAlert from '@lunit/scope-components/Auth/AuthAlert';

import {
  Box,
  InputLabel,
  Typography,
  TextField,
  AlertColor,
} from '@mui/material';

import * as userAPI from 'src/api/auth/queries';
import { APIError, ErrorMessage } from 'src/api/utils/apiRequestHelper';
import { UpdatePasswordInfo } from 'src/api/auth/models';

import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import useSnackbar from 'src/hooks/useSnackbar';
import { FormRow, FormRowItem } from './styled';

interface PasswordDialogProps {
  open: boolean
  onCancel: () => void
}

interface Alert {
  severity: AlertColor
  message: string
}

const FIELD_NAMES = ['currentPassword', 'newPassword', 'repeatPassword'];

function PasswordDialog({ open, onCancel }: PasswordDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const { mutate, isLoading } = useMutation<
  unknown,
  APIError,
  UpdatePasswordInfo
  >(userAPI.updatePassword, {
    onSuccess: () => {
      reset();
      onCancel();
      enqueueSnackbar(
        'Successfully saved.',
        { variant: 'success' },
        { isLight: true },
      );
    },
    onError: (error: APIError) => {
      if (error.details.includes('is incorrect')) {
        setErrorMessage('Incorrect password. Try again.');
      } else if (error.message === ErrorMessage.FetchError || error.code === 0) {
        setErrorMessage('Connection unavailable.');
      } else {
        setErrorMessage(`Something went wrong: ${error.details}`);
      }
    },
  });

  const alert: Alert | null = useMemo(() => {
    if (errorMessage) {
      return {
        severity: 'error',
        message: errorMessage,
      };
    }
    return null;
  }, [errorMessage]);

  const onSubmit = (data: any) => {
    setErrorMessage('');
    mutate(data);
  };

  const onSubmitError = (err: any) => {
    setErrorMessage('');
    let errorMessageSet = false;
    FIELD_NAMES.forEach((name) => {
      if (err[name] && !errorMessageSet) {
        setErrorMessage(err[name].message);
        errorMessageSet = true;
      }
    });
  };

  return (
    <FormTemplate
      title="Reset password"
      open={open}
      onCancel={() => {
        onCancel();
        reset();
        setErrorMessage('');
      }}
      width={500}
      loading={isLoading}
      isValidating={isLoading}
      submitActionName="Update"
      onSubmit={handleSubmit(onSubmit, onSubmitError)}
    >
      <FormRow>
        <Typography variant="body5">
          <Box>
            Password should contain
            <FormRowItem
              sx={{
                color:
                  errors
                  && errors.newPassword
                  && (errors.newPassword.type === 'minLength'
                    || errors.newPassword.type === 'maxLength')
                    ? '#FA4D56'
                    : 'inherit',
              }}
            >
              • 8-20 characters long.
            </FormRowItem>
            <FormRowItem
              sx={{
                color:
                  errors
                  && errors.newPassword
                  && errors.newPassword.type === 'containsNumber'
                    ? '#FA4D56'
                    : 'inherit',
              }}
            >
              • At least one number.
            </FormRowItem>
            <FormRowItem
              sx={{
                color:
                  errors
                  && errors.newPassword
                  && errors.newPassword.type === 'containsLetter'
                    ? '#FA4D56'
                    : 'inherit',
              }}
            >
              • At least one letter.
            </FormRowItem>
            <FormRowItem
              sx={{
                color:
                  errors
                  && errors.newPassword
                  && errors.newPassword.type === 'containsSpecialCharacters'
                    ? '#FA4D56'
                    : 'inherit',
              }}
            >
              • At least one special character($@%^&*!#).
            </FormRowItem>
          </Box>
        </Typography>
      </FormRow>
      <FormRow>
        <InputLabel
          htmlFor="currentPassword"
          required
        >
          <Typography
            variant="body3"
            color="darkGrey.5"
          >
            Current password
          </Typography>
        </InputLabel>
        <TextField
          id="currentPassword"
          variant="outlined"
          type="password"
          placeholder="Current password"
          {...register('currentPassword', { required: 'This field is required.' })}
          error={!!errors.currentPassword}
        />
      </FormRow>
      <FormRow>
        <InputLabel
          htmlFor="newPassword"
          required
        >
          <Typography
            variant="body3"
            color="darkGrey.5"
          >
            New password
          </Typography>
        </InputLabel>
        <TextField
          id="newPassword"
          variant="outlined"
          type="password"
          placeholder="New password"
          {...register('newPassword', {
            required: 'This field is required.',
            minLength: {
              value: 8,
              message: 'Minimum 8 characters.',
            },
            maxLength: {
              value: 20,
              message: 'Maximum 20 characters.',
            },
            validate: {
              containsNumber: (value) => (/\d/g.test(value) ? true : 'At least one number.'),
              containsLetter: (value) => (/[a-zA-Z]/g.test(value) ? true : 'At least one letter.'),
              containsSpecialCharacters: (value) => (/[$@%^&*!#]/g.test(value)
                ? true
                : 'At least one special character ($@%^&*!#).'),
            },
          })}
          error={!!errors.newPassword}
        />
      </FormRow>
      <FormRow>
        <InputLabel
          htmlFor="repeatPassword"
          required
        >
          <Typography
            variant="body3"
            color="darkGrey.5"
          >
            Repeat password
          </Typography>
        </InputLabel>
        <TextField
          id="repeatPassword"
          variant="outlined"
          type="password"
          placeholder="Confirm new password"
          {...register('repeatPassword', {
            required: 'This field is required.',
            validate: {
              passwordMatches: (value) => {
                const newPassword = getValues('newPassword');
                return value === newPassword
                  ? true
                  : 'Password must match with the new password.';
              },
            },
          })}
          error={!!errors.repeatPassword}
        />
      </FormRow>
      {alert && (
        <AuthAlert
          sx={{ marginTop: 3 }}
          severity={alert.severity}
        >
          {alert.message}
        </AuthAlert>
      )}
    </FormTemplate>
  );
}

export default PasswordDialog;
