import { atom } from 'recoil';
import accessTokenManager from '@lunit/scope-components/api/utils/accessTokenManager';
import { UserToken } from '../api/auth/models';

const authAtom = atom<UserToken>({
  key: 'auth',
  // get initial state from local storage to enable user to stay logged in
  default: accessTokenManager.getAccessTokenPayloadFromStorage(),
});

// eslint-disable-next-line import/prefer-default-export
export { authAtom };
