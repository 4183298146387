import { JetHeatmapElement } from 'src/api/biomarkers/models';
import { SingleOptionProps } from '../../models';
import BaseOption from './BaseOption';
import HeatmapOption from './OptionComponents/HeatmapOption';

function JetHeatmapOption({
  uiElement,
  disableSubOptionToggleButtons,
  isInTooltip = false,
  showToggleButton,
  hasCenter = false,
  ...baseOptionProps
}: SingleOptionProps & {
  uiElement: JetHeatmapElement
}) {
  return (
    <>
      {!isInTooltip && (
        <BaseOption
          {...baseOptionProps}
          {...baseOptionProps.elementController}
          content={uiElement.content}
        />
      )}
      <HeatmapOption
        isInTooltip={isInTooltip}
        hasCenter={hasCenter}
      />
    </>
  );
}

export default JetHeatmapOption;
