import * as React from 'react'

const SvgClose = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99 3.99a.5.5 0 0 1 .707 0l11.314 11.313a.5.5 0 1 1-.707.708L3.99 4.697a.5.5 0 0 1 0-.707Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99 16.01a.5.5 0 0 0 .707 0L16.011 4.697a.5.5 0 1 0-.707-.708L3.99 15.303a.5.5 0 0 0 0 .707Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgClose
