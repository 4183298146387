const descriptions: { [key: string]: string[] } = {
  io: [
    'AI-powered immune phenotyping from H&E, via automated identification of tumour area, stroma, and TILs',
  ],
  her2: [
    'AI-powered',
    'Analyzer for HER2 in Breast Cancer',
  ],
  tumor_purity: [
    'AI-powered',
    'Tumor Purity assessment from H&E',
  ],
  pdl1_tps: [
    'AI-powered Analyzer for PD-L1',
    'TPS of NSCLC',
  ],
  pdl1_cps_bladder: [
    'AI-powered Analyzer for PD-L1',
    'CPS of Bladder',
  ],
  pdl1_cps_breast: [
    'AI-powered Analyzer for PD-L1',
    'CPS of Breast',
  ],
};
export default descriptions;
