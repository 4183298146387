import { LabelElement } from 'src/api/biomarkers/models';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import { calculateBarWidth, getColorFromMarkerLabel } from '../func';
import { Bar } from '../styled';

const barSx = {
  height: 10,
  top: 4,
  borderRadius: 0,
  flexShrink: 1,
  minWidth: '1px',
  '&:not(:last-child)': { marginRight: '1px' },
};

interface Props {
  analysisData:AnalysisData
  cellDetectionElement:LabelElement | undefined
}

function DynamicBar({ analysisData, cellDetectionElement }:Props) {
  return (
    <Bar
      sx={{
        ...barSx,
        backgroundColor: getColorFromMarkerLabel(cellDetectionElement, analysisData.labelId!),
        width: '100%',
        flexBasis: `${calculateBarWidth(Number(analysisData.value))}%`,
      }}
    />
  );
}

export default DynamicBar;
