import * as React from 'react'

const SvgMenu = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 5.5A.5.5 0 0 1 2 5h16a.5.5 0 1 1 0 1H2a.5.5 0 0 1-.5-.5Zm0 9A.5.5 0 0 1 2 14h16a.5.5 0 1 1 0 1H2a.5.5 0 0 1-.5-.5Zm.5-5a.5.5 0 0 0 0 1h16a.5.5 0 0 0 0-1H2Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgMenu
