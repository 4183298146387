import { isLabelElement, isMarkerLabelElement, LabelElement, MarkerLabelElement, UIComponents } from 'src/api/biomarkers/models';
import { RGB } from 'src/types';

export const roundToFirstDecimal = (num: number) => `${(Math.round(num * 10) / 10).toFixed(1)}`;

export const hexToRgba = (hex: string): RGB => ({
  r: Number.parseInt(hex.substr(1, 2), 16),
  g: Number.parseInt(hex.substr(3, 2), 16),
  b: Number.parseInt(hex.substr(5, 2), 16),
});

export function getZoomFromViewportZoom(
  zoom: number,
  microscopeWidth1x: number,
  viewer?: OpenSeadragon.Viewer,
) {
  if (viewer) {
    const viewportWidth = viewer.viewport.getContainerSize().x;
    const scaleFactor = microscopeWidth1x / viewportWidth;
    return zoom / scaleFactor;
  }
  return zoom;
}

export const findCellElement = (
  uiComponents: UIComponents | undefined,
  id: string,
) => {
  const cellDetectionElement = uiComponents?.elements.find(
    (element): element is LabelElement => isLabelElement(element) && element.content === 'Histological Features',
  );

  return cellDetectionElement?.elements.find(
    (ipType): ipType is MarkerLabelElement => ipType.id === id && isMarkerLabelElement(ipType),
  );
};

export function checkAndSetGlobalAlpha(context:CanvasRenderingContext2D, targetAlpha:number) {
  if (context.globalAlpha !== targetAlpha) {
    context.globalAlpha = targetAlpha;
  }
}
