import {
  ControllerProps,
  ProjectConfigSpec,
  WithSubChildControllerProps,
} from 'src/types';

export const cpsBladderConfig: ProjectConfigSpec = {
  projectName: 'pdl1-cps-bladder',
  titleHasTooltip: true,
  hasSummarySelector: false,
  elementConfig: [
    {
      name: 'CPS Map with Cutoffs',
      elementType: 'label',
      childOptions: 'show-only',
    },
    {
      name: 'Tissue Segmentation',
      elementType: 'label',
      childOptions: 'show-only',
    },
    {
      name: 'Cell Detection',
      elementType: 'label',
      childOptions: 'show-with-state',
      elementInTitleTooltipConfig: { caption: 'Cells show when over x40.' },
    },
  ],
};

export type CPSBladderControllerOrder = [
  ControllerProps,
  ControllerProps,
  WithSubChildControllerProps,
];
