import { Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { AuthPropKeys } from './types';

interface AuthDescriptionProps extends Pick<TypographyProps, AuthPropKeys> {}

const AuthDescription = forwardRef<HTMLDivElement, AuthDescriptionProps>(
  (props, ref) => (
    <Typography
      ref={ref}
      {...props}
      className={clsx('AuthDescription', props.className)}
      sx={{ marginBottom: 3, ...props.sx }}
      variant="body5"
    />
  ),
);

AuthDescription.displayName = 'AuthDescription';

export default AuthDescription;
