import * as React from 'react'

const SvgSuccess = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 10a7.5 7.5 0 0 1 11.496-6.348l.258-.258a3.01 3.01 0 0 1 .529-.422 8.5 8.5 0 1 0 3.455 4.923l-.844.844A7.5 7.5 0 1 1 2.5 10Z"
      fill="currentColor"
    />
    <path
      className="success_svg__fixed-color"
      d="m5.875 9.016 3.5 3.5 8.508-8.508"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgSuccess
