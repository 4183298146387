declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    selected: React.CSSProperties['color']
    primary: React.CSSProperties['color']
    secondary: React.CSSProperties['color']
  }

  interface SlideStatusPalette {
    dot: React.CSSProperties['color']
    contrastText: React.CSSProperties['color']
    main: React.CSSProperties['color']
  }

  interface Palette {
    background: TypeBackground
    brand: {
      primary: React.CSSProperties['color']
      secondary: React.CSSProperties['color']
    }
    scope1: Palette['primary']
    scope2: Palette['primary']
    scope3: Palette['primary']
    scope4: Palette['primary']
    slideStatus: {
      preanalyzing: SlideStatusPalette
      ready: SlideStatusPalette
      analyzing: SlideStatusPalette
      analyzed: SlideStatusPalette
      failed: SlideStatusPalette
    }
    alphaGrey: {
      8: React.CSSProperties['color']
      16: React.CSSProperties['color']
      40: React.CSSProperties['color']
      80: React.CSSProperties['color']
      100: React.CSSProperties['color']
    }
    darkGrey: {
      0: React.CSSProperties['color']
      5: React.CSSProperties['color']
      10: React.CSSProperties['color']
      15: React.CSSProperties['color']
      20: React.CSSProperties['color']
      30: React.CSSProperties['color']
      40: React.CSSProperties['color']
      50: React.CSSProperties['color']
      60: React.CSSProperties['color']
      70: React.CSSProperties['color']
      80: React.CSSProperties['color']
      85: React.CSSProperties['color']
      90: React.CSSProperties['color']
      95: React.CSSProperties['color']
      100: React.CSSProperties['color']
    }
  }

  interface PaletteOptions {
    background?: Partial<TypeBackground>
    brand?: {
      primary: React.CSSProperties['color']
      secondary: React.CSSProperties['color']
    }
    scope1?: PaletteOptions['primary']
    scope2?: PaletteOptions['primary']
    scope3?: PaletteOptions['primary']
    scope4?: PaletteOptions['primary']
    preanalyzing?: SlideStatusPalette
    ready?: SlideStatusPalette
    analyzing?: SlideStatusPalette
    analyzed?: SlideStatusPalette
    failed?: SlideStatusPalette
    slideStatus?: {
      preanalyzing: SlideStatusPalette
      ready: SlideStatusPalette
      analyzing: SlideStatusPalette
      analyzed: SlideStatusPalette
      failed: SlideStatusPalette
    }
    alphaGrey?: {
      8: React.CSSProperties['color']
      16: React.CSSProperties['color']
      40: React.CSSProperties['color']
      80: React.CSSProperties['color']
      100: React.CSSProperties['color']
    }
    darkGrey?: {
      0: React.CSSProperties['color']
      5: React.CSSProperties['color']
      10: React.CSSProperties['color']
      15: React.CSSProperties['color']
      20: React.CSSProperties['color']
      30: React.CSSProperties['color']
      40: React.CSSProperties['color']
      50: React.CSSProperties['color']
      60: React.CSSProperties['color']
      70: React.CSSProperties['color']
      80: React.CSSProperties['color']
      85: React.CSSProperties['color']
      90: React.CSSProperties['color']
      95: React.CSSProperties['color']
      100: React.CSSProperties['color']
    }
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    scope1: true
    scope2: true
    scope3: true
    scope4: true
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    preanalyzing: true;
    ready: true;
    analyzing: true;
    analyzed: true;
    failed: true;
  }
}

const slideStatus = {
  preanalyzing: {
    dot: '#9775FA',
    contrastText: '#3612A1',
    main: '#D0BFFF',
  },
  ready: {
    dot: '#748FFC',
    contrastText: '#132A9B',
    main: '#BAC8FF',
  },
  analyzing: {
    dot: '#4DABF7',
    contrastText: '#034480',
    main: '#A5D8FF',
  },
  analyzed: {
    dot: '#3BC9DB',
    contrastText: '#014B58',
    main: '#99E9F2',
  },
  failed: {
    dot: '#FF8787',
    contrastText: '#A60D0D',
    main: '#FFC9C9',
  },
}

const palette = {
  brand: {
    primary: '#7292FD',
    secondary: '#587EFC',
  },
  scope1: '#7292FD',
  scope2: '#587EFC',
  scope3: '#B0C0F4',
  scope4: '#3A5DD9',
  text: {
    primary: '#FFFFFF',
    secondary: '#AFAFB1',
    disabled: '#A8A8A8',
  },
  background: {
    primary: '#1F1F21',
    secondary: '#2D2D2F',
    selected: '#3E3E40',
  },
  slideStatus,
  ...slideStatus,
  alphaGrey: {
    8: 'rgba(134, 148, 177, 0.08)',
    16: 'rgba(134, 148, 177, 0.16)',
    40: 'rgba(134, 148, 177, 0.40)',
    80: 'rgba(134, 148, 177, 0.80)',
    100: '#8694B1',
  },
  darkGrey: {
    0: '#FFFFFF',
    5: '#FAFAFB',
    10: '#F1F1F4',
    15: '#D4D4D6',
    20: '#C6C6C8',
    30: '#AFAFB1',
    40: '#99999B',
    50: '#7F7F81',
    60: '#626264',
    70: '#4E4E50',
    80: '#3E3E40',
    85: '#2D2D2F',
    90: '#1F1F21',
    95: '#111113',
    100: '#000000',
  },
  error: {
    main: '#EE5140',
    light: '#EE5140',
    dark: '#EE5140',
  },
  success: {
    main: '#00C9EA',
    light: '#00C9EA',
    dark: '#00C9EA',
  },
  warning: {
    main: '#FFE600',
    light: '#FFE600',
    dark: '#FFE600',
  },
}

export default palette
