import { atom } from 'recoil';

import { projectInitialState } from 'src/const';

import { ProjectState } from 'src/types';

// initialize recoil atom
export const projectStateAtom = atom<ProjectState>({
  key: 'projectState',
  default: projectInitialState,
});

export const her2SummaryAtom = atom<'CA' | 'CIS'>({
  key: 'her2SummaryTab',
  default: 'CA',
});
