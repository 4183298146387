import type { ComponentsOverrides } from '@mui/material/styles'
import palette from '../colors'

const styleOverrides: ComponentsOverrides['MuiDialogContent'] = {
  root: {
    padding: '0 32px 32px',
  },
  dividers: {
    borderTop: `1px solid ${palette.alphaGrey[16]}`,
    borderBottom: 'transparent',
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
}
export default {
  styleOverrides,
}
