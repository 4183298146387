export interface Product {
  name: string
  description: string
  path: string
}

export interface Biomarkers {
  products: Product[]
}

export interface Slide {
  slideDziUrl: string
  slideDziMetaUrl: string
  slideThumbnailUrl: string
  resultPath: string
  description: string
}

export type MarkerLabelElement = {
  type: 'marker-label'
  symbol: MarkerLabelElementSymbol
  id: string
  content: string
  color: string
};

export enum MarkerLabelElementSymbol {
  Tissue = 'tissue',
  Cell = 'cell',
}

export type JetHeatmapElement = {
  id: string
  type: 'jet-heatmap'
  content: string
};

export type OpacityHeatmapElement = {
  id: string
  type: 'opacity-heatmap'
  content: string
  color: string
};

export type SubLabelElement = {
  id: string
  type: 'label'
  content: string
  textColor: string
};

export type SubUIElement =
  | MarkerLabelElement
  | JetHeatmapElement
  | OpacityHeatmapElement
  | SubLabelElement;

export function isMarkerLabelElement(
  element: SubUIElement,
): element is MarkerLabelElement {
  return element.type === 'marker-label';
}

export function isJetHeatmapElement(
  element: SubUIElement | UIElement,
): element is JetHeatmapElement {
  return element.type === 'jet-heatmap';
}

export function isOpacityHeatmapElement(
  element: SubUIElement,
): element is OpacityHeatmapElement {
  return element.type === 'opacity-heatmap';
}

export function isSubLabelElement(
  element: SubUIElement,
): element is SubLabelElement {
  return element.type === 'label';
}

export type DropdownElement = {
  type: 'drop-down'
  elements: LabelElement[]
};

export type LabelElement = {
  type: 'label'
  content: string
  elements: SubUIElement[]
};

export type UIElement = LabelElement | DropdownElement | JetHeatmapElement;

export function isLabelElement(element: UIElement): element is LabelElement {
  return element.type === 'label';
}

export function isDropdownElement(
  element: UIElement,
): element is DropdownElement {
  return element.type === 'drop-down';
}

export interface UIComponents {
  content: string
  elements: UIElement[]
  type: UIElement['type']
}

export interface BiomarkerMetadata {
  biomarkerName: string
  uiComponents: UIComponents
  results: Slide[]
}
