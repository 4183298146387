import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiDivider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import find from 'lodash/find';
import HeaderButton, { HeaderButtonMenuItem } from '../../HeaderButton';

export { Typography, Tooltip, Box, Button };

export const Divider = styled(MuiDivider)({
  margin: '14px 8px',
  height: 20,
});

export const Container = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const LeftContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
export const RightContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export function tooltipWithShortcut(title: string, shortcut?: string) {
  return (
    <>
      <Typography
        color="darkGrey.0"
        variant="body5"
      >
        {title}
      </Typography>
      {shortcut && (
        <Typography
          color="darkGrey.30"
          variant="body6"
          sx={{ marginLeft: 1 }}
        >
          {shortcut}
        </Typography>
      )}
    </>
  );
}

export function tooltipForMenuButton(
  menuItems: HeaderButtonMenuItem[],
  selectedMenuId: string,
) {
  const selectedMenu = find(
    menuItems,
    (menuItem) => menuItem.id === selectedMenuId,
  );
  if (!selectedMenu) return null;
  return tooltipWithShortcut(selectedMenu.title, selectedMenu.shortcut);
}

export function TypoLabel({ children }: { children: React.ReactNode }) {
  return (
    <HeaderButton
      disabled
      sx={{
        paddingLeft: 1,
        paddingRight: 1,
        '&:disabled': { color: (theme) => theme.palette.darkGrey[0] },
      }}
    >
      <Typography variant="body3">{children}</Typography>
    </HeaderButton>
  );
}

export const CellClassTypography = styled(Typography)({
  width: 36,
  height: 28,
  textAlign: 'center',
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 4,
});
