import { Typography } from '@mui/material';
import { Product } from 'src/pages/home/ProductList/models';

const LOGO_BG_DARK_PATHS: { [key:string]: string } = {
  scope: 'lunit-scope-w.svg',
  io: 'logo-scope-io-w.svg',
  her2: 'logo-scope-her2-w.svg',
  tumor_purity: 'logo-scope-tumor-purity-w.svg',
  pdl1: 'logo-scope-pdl1-w.svg',
};

const LOGO_BG_LIGHT_PATHS: { [key:string]: string } = {
  scope: 'lunit-scope-b.svg',
  io: 'logo-scope-io-b.svg',
  her2: 'logo-scope-her2-b.svg',
  tumor_purity: 'logo-scope-tumor-purity-b.svg',
  pdl1: 'logo-scope-pdl1-b.svg',
};

interface Props {
  product?: Product
  assayVisible?: boolean
  bgMode?: 'light' | 'dark'
  fallback?: boolean
  height?: React.ImgHTMLAttributes<HTMLImageElement>['height']
}

function ProductLogo({ product, assayVisible = true, bgMode = 'dark', fallback = false, height = 22 }: Props) {
  if (product == null && !fallback) {
    return null;
  }

  const logoPaths = bgMode === 'light'
    ? LOGO_BG_LIGHT_PATHS
    : LOGO_BG_DARK_PATHS;

  const { name = 'Lunit SCOPE', path = 'scope', assay } = product || {};

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '13px' }}>
      <img
        alt={name}
        src={`/${logoPaths[path.includes('pdl1') ? 'pdl1' : path]}`}
        height={height}
      />
      {assayVisible && assay && (
      <Typography
        variant="caption"
      >
        {assay}
      </Typography>
      )}
    </div>
  );
}
export default ProductLogo;
