import { ControllerProps, DropdownControllerProps } from 'src/types';
import {
  JetHeatmapOption,
  LabelOption,
  LabelWithChildrenOption,
  DropdownOption,
} from './OptionVariants';

import {
  isJetHeatmapElement,
  isLabelElement,
  isDropdownElement,
} from '../../../../api/biomarkers/models';
import { SingleOptionProps } from '../models';

function hasChildren(config: SingleOptionProps['config']): boolean {
  return config?.childOptions !== 'hide';
}

function SingleOption({
  uiElement,
  elementController,
  ...optionProps
}: SingleOptionProps) {
  if (isLabelElement(uiElement)) {
    if (hasChildren(optionProps.config)) {
      return (
        <LabelWithChildrenOption
          uiElement={uiElement}
          elementController={elementController}
          {...optionProps}
        />
      );
    }
    return (
      <LabelOption
        uiElement={uiElement}
        elementController={elementController as ControllerProps}
        {...optionProps}
      />
    );
  }
  if (isJetHeatmapElement(uiElement)) {
    return (
      <JetHeatmapOption
        uiElement={uiElement}
        elementController={elementController as ControllerProps}
        {...optionProps}
      />
    );
  }
  if (isDropdownElement(uiElement)) {
    return (
      <DropdownOption
        uiElement={uiElement}
        elementController={elementController as DropdownControllerProps}
        {...optionProps}
      />
    );
  }
  return null;
}

export default SingleOption;
