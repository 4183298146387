import type { ComponentsOverrides } from '@mui/material/styles'
import palette from '../colors'

const styleOverrides: ComponentsOverrides['MuiLinearProgress'] = {
  root: {
    height: 3,
    backgroundColor: 'transparent',
  },
  barColorPrimary: {
    backgroundColor: palette.scope1,
  },
}
export default {
  styleOverrides,
}
