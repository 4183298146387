import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';

interface Props {
  children: React.ReactNode
}
function SlidePanel({ children }: Props) {
  return (
    <Drawer
      sx={{
        width: 260,
        flexShrink: 0,
      }}
      variant="permanent"
      PaperProps={{
        sx: {
          borderLeft: 'none',
          width: 260,
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      anchor="right"
    >
      <Toolbar />
      {children}
    </Drawer>
  );
}

export default SlidePanel;
