import React, { useCallback, useState, useEffect } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import useViewerZoom from 'src/hooks/useViewerZoom';
import { ZOOM_LEVELS } from 'src/const';
import {
  ZoomToggleButton,
  ZoomButton,
  LabelController,
  SlideContainer,
  ZoomWrapper,
  Tooltip,
  Typography,
  ToggleButtonGroup,
  PlusIcon,
  MinusIcon,
} from './ZoomControllerMUI';

import { ZoomControllerProps } from '../types';

function ZoomController({ noSubdrawer }: ZoomControllerProps) {
  const [tmpZoomValue, setTmpZoomValue] = useState(1);
  const [levelLabelHidden, setLevelLabelHidden] = useState(false);

  const {
    setZoomLevel,
    zoomState: { zoom: zoomLevel },
  } = useViewerZoom();

  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newValue: number) => {
      if (newValue === null) return;
      setZoomLevel(newValue || 0.1);
    },
    [setZoomLevel],
  );

  const getClosestZoomLevel = useCallback(
    (
      target: number,
    ) => ZOOM_LEVELS.reduce(
      (prev, curr) => (Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev),
    ),
    [],
  );

  useEffect(() => {
    // To subscribe the updated zoom state and update tmpZoomValue correspondingly.
    // Mostly used when user tries to zoom in/out
    // \w a device such as a mouse wheel or trackpad
    const closest = getClosestZoomLevel(zoomLevel);
    setTmpZoomValue(closest);
  }, [zoomLevel, getClosestZoomLevel]);

  const handleZoomIn = useCallback(() => {
    const currentIdx = ZOOM_LEVELS.findIndex((level) => level === tmpZoomValue);
    if (currentIdx === ZOOM_LEVELS.length - 1) {
      return;
    }
    const nextIdx = currentIdx + 1;
    // eslint-disable-next-line consistent-return
    return setZoomLevel(ZOOM_LEVELS[nextIdx]);
  }, [tmpZoomValue, setZoomLevel]);

  const handleZoomOut = useCallback(() => {
    const currentIdx = ZOOM_LEVELS.findIndex((level) => level === tmpZoomValue);
    if (currentIdx === 0) {
      return;
    }
    const nextIdx = currentIdx - 1;
    // eslint-disable-next-line consistent-return
    return setZoomLevel(ZOOM_LEVELS[nextIdx]);
  }, [tmpZoomValue, setZoomLevel]);

  useHotkeys(
    '=',
    () => {
      handleZoomIn();
    },
    [handleZoomIn],
  );

  useHotkeys(
    '-',
    () => {
      handleZoomOut();
    },
    [handleZoomOut],
  );

  useHotkeys(
    '1,2,3,4,5,6,7',
    (keyboardEvent, handler) => {
      const pressedNum = Number(handler.key);
      if (pressedNum > 0 && pressedNum < 8) {
        setZoomLevel(ZOOM_LEVELS[pressedNum]);
      }
    },
    [],
  );

  return (
    <ZoomWrapper noSubdrawer={noSubdrawer}>
      <SlideContainer>
        <ToggleButtonGroup
          size="small"
          orientation="vertical"
          value={tmpZoomValue.toString()}
          exclusive
          onChange={handleChange}
        >
          <Tooltip
            placement="left"
            title={(
              <div>
                Zoom
                {' '}
                <LabelController
                  onClick={() => setLevelLabelHidden(!levelLabelHidden)}
                >
                  {levelLabelHidden ? 'Show' : 'Hide'}
                  {' '}
                  labels
                </LabelController>
              </div>
            )}
            aria-label="zoom-in"
          >
            <ZoomButton
              color="inherit"
              aria-label="zoom-in"
              onClick={handleZoomIn}
            >
              <PlusIcon />
            </ZoomButton>
          </Tooltip>
          {!levelLabelHidden
            && ZOOM_LEVELS
              // https://stackoverflow.com/questions/5024085/whats-the-point-of-slice0-here
              .slice(0)
              // `.reverse()` renders magnification starting with the largest number.
              .reverse()
              .map((level, index) => (
                <ZoomToggleButton
                  key={`zoom-level-${level}`}
                  onClick={() => setTmpZoomValue(level)}
                  value={level.toString()}
                  aria-label={level.toString()}
                  disableRipple
                >
                  <Typography variant="caption">
                    { /* eslint-disable-next-line no-nested-ternary */ }
                    {index === 0
                      ? 'Max'
                      : index >= ZOOM_LEVELS.length - 1
                        ? 'Min'
                        : `x${level}`}
                  </Typography>
                </ZoomToggleButton>
              ))}
          <Tooltip
            placement="left"
            title={(
              <div>
                Zoom
                {' '}
                <LabelController
                  onClick={() => setLevelLabelHidden(!levelLabelHidden)}
                >
                  {levelLabelHidden ? 'Show' : 'Hide'}
                  {' '}
                  labels
                </LabelController>
              </div>
            )}
            aria-label="zoom-out"
          >
            <ZoomButton
              color="inherit"
              aria-label="zoom-out"
              onClick={handleZoomOut}
            >
              <MinusIcon />
            </ZoomButton>
          </Tooltip>
        </ToggleButtonGroup>
      </SlideContainer>
    </ZoomWrapper>
  );
}

export default ZoomController;
