import { useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthTemplate from '@lunit/scope-components/Auth/AuthTemplate';
import AuthHeading from '@lunit/scope-components/Auth/AuthHeading';
import AuthTextField from '@lunit/scope-components/Auth/AuthTextField';
import AuthActionButton from '@lunit/scope-components/Auth/AuthActionButton';
import AuthNav from '@lunit/scope-components/Auth/AuthNav';
import AuthDescription from '@lunit/scope-components/Auth/AuthDescription';
import AuthAlert from '@lunit/scope-components/Auth/AuthAlert';
import { Link } from '@mui/material';
import { useMutation } from 'react-query';
import { APIError } from '@lunit/scope-components/api/models';
import { AlertColor } from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import * as authAPI from '../../api/auth/queries';

interface Alert {
  severity: AlertColor
  message: string
}

const FIELD_NAMES = ['newPassword', 'repeatPassword'];

function ResetPassword() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const params = useParams();
  const key: string = params.key ? params.key : '';

  const alert: Alert | null = useMemo(() => {
    if (errorMessage) {
      return {
        severity: 'error',
        message: errorMessage,
      };
    }
    return null;
  }, [errorMessage]);

  const resetPassword = useMutation(authAPI.resetPassword, {
    onSuccess: () => {
      navigate('/login');
    },
    onError: (error: APIError) => {
      // console.log('API error', error);
      // console.log('error.details', error.details);
      setErrorMessage(`Something went wrong: ${error.details}`);
    },
  });

  const verifyValidKey = useMutation(authAPI.verifyValidKey, {
    onSuccess: () => {
      // console.log('key valid');
    },
    onError: () => {
      // console.log('API error', error);
      // console.log('error.details', error.details);
      // console.log('not valid key');
      navigate('/forgot-password?expired');
    },
  });

  useEffect(
    () => {
      if (
        !verifyValidKey.isLoading
        && !verifyValidKey.isSuccess
        && !verifyValidKey.isError
      ) {
        verifyValidKey.mutate({ key });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      key,
      verifyValidKey.mutate,
      verifyValidKey.isLoading,
      verifyValidKey.isSuccess,
      verifyValidKey.isError,
    ],
  );

  const onSubmit = (data: any) => {
    resetPassword.mutate({
      key,
      newPassword: data.newPassword,
    });
  };

  const onSubmitError = (err: any) => {
    setErrorMessage('');
    let errorMessageSet = false;
    FIELD_NAMES.forEach((name) => {
      if (err[name] && !errorMessageSet) {
        setErrorMessage(err[name].message);
        errorMessageSet = true;
      }
    });
  };

  return (
    <AuthTemplate logo="/logo-scope-r.svg">
      <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <AuthHeading>Reset password</AuthHeading>
        <AuthNav>
          <Link
            href="/login"
            sx={{ textAlign: 'right' }}
          >
            Back to sign-in
          </Link>
        </AuthNav>
        <AuthDescription sx={{ marginTop: 5 }}>
          Password should contain
          <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
            <li
              style={{
                color:
                  errors
                  && errors.newPassword
                  && (errors.newPassword.type === 'minLength'
                    || errors.newPassword.type === 'maxLength')
                    ? '#FA4D56'
                    : 'inherit',
              }}
            >
              8-20 characters.
            </li>
            <li
              style={{
                color:
                  errors
                  && errors.newPassword
                  && errors.newPassword.type === 'containsNumber'
                    ? '#FA4D56'
                    : 'inherit',
              }}
            >
              At least one number.
            </li>
            <li
              style={{
                color:
                  errors
                  && errors.newPassword
                  && errors.newPassword.type === 'containsLetter'
                    ? '#FA4D56'
                    : 'inherit',
              }}
            >
              At least one letter.
            </li>
            <li
              style={{
                color:
                  errors
                  && errors.newPassword
                  && errors.newPassword.type === 'containsSpecialCharacters'
                    ? '#FA4D56'
                    : 'inherit',
              }}
            >
              At least one special character ($@%^&*!#).
            </li>
          </ul>
        </AuthDescription>
        <AuthTextField
          id="newPassword"
          type="password"
          aria-label="Password"
          placeholder="New password"
          autoFocus
          {...register('newPassword', {
            required: 'This field is required.',
            minLength: {
              value: 8,
              message: 'Minimum 8 characters.',
            },
            maxLength: {
              value: 20,
              message: 'Maximum 20 characters.',
            },
            validate: {
              containsNumber: (value) => (/\d/g.test(value) ? true : 'At least one number.'),
              containsLetter: (value) => (/[a-zA-Z]/g.test(value) ? true : 'At least one letter.'),
              containsSpecialCharacters: (value) => (/[$@%^&*!#]/g.test(value)
                ? true
                : 'At least one special character ($@%^&*!#).'),
            },
          })}
          error={!!errors.newPassword}
        />
        <AuthTextField
          id="repeatPassword"
          type="password"
          aria-label="Password confirmation"
          placeholder="Confirm new password"
          {...register('repeatPassword', {
            required: 'This field is required.',
            validate: {
              passwordMatches: (value) => {
                const newPassword = getValues('newPassword');
                return value === newPassword
                  ? true
                  : 'Password must match with the new password.';
              },
            },
          })}
          error={!!errors.repeatPassword}
        />
        <AuthActionButton>Confirm</AuthActionButton>
        {alert && (
          <AuthAlert
            sx={{ marginTop: 3 }}
            severity={alert.severity}
          >
            {alert.message}
          </AuthAlert>
        )}
        <AuthNav>
          <Link
            href="mailto:cs-scope@lunit.io"
            target="_blank"
            sx={{ textAlign: 'right' }}
          >
            cs-scope@lunit.io
          </Link>
        </AuthNav>
      </form>
    </AuthTemplate>
  );
}

export default ResetPassword;
