import { map } from 'lodash';
import { LabelElement } from 'src/api/biomarkers/models';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import { SectionListItem, Typography } from '../../styled';
import IntensityTypeProportionIndicator from './IntensityTypeProportionIndicator';

interface Props {
  summaryItem:AnalysisData
  cellDetectionElement:LabelElement
}

function IntensityProportionTable({ summaryItem, cellDetectionElement }:Props) {
  return (
    <>
      <SectionListItem>
        <Typography variant="body5">
          Intensity Type Proportion
        </Typography>
      </SectionListItem>
      {map(summaryItem.items, (data) => (
        <IntensityTypeProportionIndicator
          key={data.name}
          data={data}
          cellDetectionElement={cellDetectionElement}
        />
      ))}
    </>
  );
}
export default IntensityProportionTable;
