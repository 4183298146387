import { OpacityHeatmapElement } from 'src/api/biomarkers/models';
import { ColorMapStop, OpacityHeatmapBar } from '../../../styled';

interface Props {
  uiElement:OpacityHeatmapElement
}

function OpacityHeatmapOption({ uiElement }:Props) {
  function makeLabel(str:string):string {
    const result = str.replace('HER2 ', '');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '24px',
      padding: '2px 0',
      gap: '4px',
    }}
    >
      <OpacityHeatmapBar color={uiElement.color} />
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <ColorMapStop
          sx={{ position: 'static', textAlign: 'left', color: (theme) => theme.palette.grey['500'] }}
        >
          0
        </ColorMapStop>
        <ColorMapStop
          sx={{ position: 'static', width: 'fit-content', color: (theme) => theme.palette.grey['500'] }}
        >
          {makeLabel(uiElement.content)}
        </ColorMapStop>
        <ColorMapStop
          sx={{ position: 'static', textAlign: 'right', color: (theme) => theme.palette.grey['500'] }}
        >
          1
        </ColorMapStop>
      </div>
    </div>
  );
}

export default OpacityHeatmapOption;
