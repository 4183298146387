import KDBush from 'kdbush';
import { UIComponents } from 'src/api/biomarkers/models';
import { getSlideMsgpackPresignedUrl } from '../../api/biomarkers/queries';
import {
  isErrorEventData,
  isPreparedEventData,
  ResultWorkerEventData,
  ResultWorkerPreparedEventData,
} from './types';

const INITIAL_CACHE = {
  cacheKey: '',
  data: null,
};

let resultCache: {
  cacheKey: string
  data: ResultWorkerPreparedEventData | null
} = INITIAL_CACHE;

let canvas: HTMLCanvasElement | null = null;
let offscreen: OffscreenCanvas | null = null;

export const fetchVisualizationData = async (
  msgpackFilePath: string,
  worker: Worker,
  uiComponents: UIComponents,
): Promise<ResultWorkerPreparedEventData> => {
  if (resultCache.cacheKey === msgpackFilePath && !!resultCache.data) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => resolve(resultCache.data!));
  }
  const msgpackUrl = await getSlideMsgpackPresignedUrl(msgpackFilePath);
  return new Promise((resolve, reject) => {
    if (!canvas && !offscreen) {
      canvas = document.createElement('canvas');
      offscreen = canvas.transferControlToOffscreen();
    }
    // eslint-disable-next-line no-param-reassign
    worker.onmessage = (ev: MessageEvent<ResultWorkerEventData>) => {
      // console.log(ev)
      if (isPreparedEventData(ev.data)) {
        resultCache.cacheKey = msgpackFilePath;
        resultCache.data = ev.data;
        resultCache.data.cellData?.forEach((coords, index) => {
          if (resultCache.data && resultCache.data.cellData) {
            resultCache.data.cellData[index].indexedCoords = new KDBush(
              coords.coordinates,
              (p) => p.x,
              (p) => p.y,
              64,
              Int32Array,
            );
          }
        });
        resultCache.data.gridData?.forEach((grid, index) => {
          if (resultCache.data && resultCache.data.gridData) {
            resultCache.data.gridData[index].indexedCoords = new KDBush(
              grid.gridResults,
              (p) => p.minX,
              (p) => p.minY,
              64,
              Int32Array,
            );
          }
        });
        // console.log(resultCache)
        resolve(ev.data);
      } else if (isErrorEventData(ev.data)) {
        reject(new Error(ev.data.message));
      }
    };

    worker.postMessage(
      {
        action: 'prepare',
        msgpackUrl,
        uiComponents,
        offscreen,
      },
      [offscreen!],
    );
  });
};

export const clearCache = (key: string) => {
  if (key === resultCache.cacheKey) {
    resultCache.data?.maskImages?.forEach(({ image }) => image.close());
    resultCache = INITIAL_CACHE;
    canvas = null;
    offscreen = null;
  }
};
