import { ListItemText, Typography } from '@mui/material';
import map from 'lodash/map';
import { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { BiomarkerMetadata } from 'src/api/biomarkers/models';
import { SlideLink } from './SlideLink';
import { DrawerList } from './styled';

interface SlideListProps {
  loading?: boolean
  base?: string
  results?: BiomarkerMetadata['results']
  refetchResults?: () => void
}

// eslint-disable-next-line import/prefer-default-export
export function SlideList({
  loading,
  results,
  base,
  refetchResults,
}: SlideListProps) {
  const match = useMatch('/:productPath/:slideDesc');
  const navigate = useNavigate();
  useEffect(() => {
    if (base && !match && results) {
      navigate(`/${base}/${results[0].description}`, { replace: true });
    }
  }, [match, base, results, navigate]);
  return (
    <DrawerList>
      <ListItemText
        sx={{
          flex: 0,
          ml: (theme) => theme.spacing(2),
          mt: (theme) => theme.spacing(2.25),
          mb: (theme) => theme.spacing(1),
        }}
      >
        <Typography
          variant="body3"
          color="darkGrey.0"
        >
          Slide List
        </Typography>
      </ListItemText>
      {(loading || !results) && (
        <>
          <SlideLink />
          <SlideLink />
          <SlideLink />
        </>
      )}
      {!!results
        && map(results, (slide) => (
          <SlideLink
            key={slide.description}
            base={base}
            slide={slide}
            refetchResults={refetchResults}
          />
        ))}
    </DrawerList>
  );
}
