import { AnalysisFootnotes } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements';

function TumorPurityFootnotes() {
  return (
    <AnalysisFootnotes>
      Tumor cell count /
      <br />
      Total cell count [%]
    </AnalysisFootnotes>
  );
}
export default TumorPurityFootnotes;
