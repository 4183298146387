import { useCallback } from 'react';

import {
  CanvasOverlayProps,
  TooltipOverlayProps,
} from '@lunit/osd-react-renderer';
import map from 'lodash/map';
import max from 'lodash/max';
import OpenSeadragon from 'openseadragon';

import { ResultWorkerPreparedEventData } from 'src/workers/AnalysisResultWorker/types';

import { ControllerOrder } from 'src/const/projectConfigSpecs';
import useViewerZoom from '../useViewerZoom';
import { useProjectState } from '../projectConfig';
import { getZoomFromViewportZoom } from './func/utils';
import { drawTissueImage } from './func/drawing';
import { Origin } from './webgl/types';
import useWebGLCellDrawing from './webgl/useWebGLCellDrawing';

const LOCAL_TPS_RADIUS_IN_UM = 281.34027777777754;
interface TooltipMsg {
  client: OpenSeadragon.Point
  negativeCount: number
  positiveCount: number
  localTPSRadiusInPx: number
}

const drawBiomarkerInfoTooltip = (
  context: CanvasRenderingContext2D,
  viewer: OpenSeadragon.Viewer,
  tooltipMsg: TooltipMsg,
) => {
  const { client, positiveCount, negativeCount, localTPSRadiusInPx } = tooltipMsg;
  const bounds = viewer.viewport.viewportToImageRectangle(
    viewer.viewport.getBounds(true),
  );
  const tpsScore = `${Math.floor(
    (positiveCount / (positiveCount + negativeCount)) * 100,
  )}%`;
  const sizeRect = new OpenSeadragon.Rect(0, 0, 2, 2);
  const lineWidth = viewer.viewport.viewportToImageRectangle(
    viewer.viewport.viewerElementToViewportRectangle(sizeRect),
  ).width;
  // circle
  context.lineWidth = lineWidth;
  context.strokeStyle = 'rgb(0,0,0)';
  context.fillStyle = 'transparent';
  context.beginPath();
  context.arc(client.x, client.y, localTPSRadiusInPx, 0, 2 * Math.PI);
  context.closePath();
  context.stroke();
  // tooltip box
  context.fillStyle = 'rgba(21,30,45,0.8)';
  const totalCountGTE100 = positiveCount + negativeCount >= 100;
  const tooltipRect = !totalCountGTE100
    ? new OpenSeadragon.Rect(0, 28, 155, 30)
    : new OpenSeadragon.Rect(0, 28, 230, 84);
  const tooltipBounds = viewer.viewport.viewportToImageRectangle(
    viewer.viewport.viewerElementToViewportRectangle(tooltipRect),
  );
  if (totalCountGTE100) {
    const textHeight = (72 / 84) * tooltipBounds.height;
    const lineHeight = textHeight / 4;
    const fontSize = (14 / 18) * lineHeight;
    context.font = `300 ${fontSize}px/${lineHeight}px Proxima Nova`;
    // measureText for deciding box width
    const longText1 = context.measureText(
      `# of PD-L1 Negative Tumor Cells: ${negativeCount}`,
    );
    const longText2 = context.measureText(
      `# of PD-L1 Positive Tumor Cells: ${positiveCount}`,
    );
    const longestTextWidth = max([longText1.width, longText2.width]);
    context.beginPath();
    context.rect(
      client.x,
      client.y + (tooltipBounds.y - bounds.y),
      max([
        tooltipBounds.width,
        longestTextWidth! + (16 / 230) * tooltipBounds.width,
      ])!,
      tooltipBounds.height,
    );
    context.closePath();
    context.fill();
    // tooltip text
    context.fillStyle = 'rgb(255,255,255)';
    context.textBaseline = 'middle';
    context.textAlign = 'start';
    const textOffsetX = client.x + tooltipBounds.width * (8 / 230);
    let textOffsetY = client.y
      + (tooltipBounds.y - bounds.y)
      + tooltipBounds.height * (6 / 84)
      + lineHeight / 2;
    context.font = `400 ${fontSize}px/${lineHeight}px Proxima Nova`;
    context.fillText(`Local TPS: ${tpsScore}`, textOffsetX, textOffsetY);
    context.font = `300 ${fontSize}px/${lineHeight}px Proxima Nova`;
    textOffsetY += lineHeight;
    context.fillText(
      `# of PD-L1 Positive Tumor Cells: ${positiveCount}`,
      textOffsetX,
      textOffsetY,
    );
    textOffsetY += lineHeight;
    context.fillText(
      `# of PD-L1 Negative Tumor Cells: ${negativeCount}`,
      textOffsetX,
      textOffsetY,
    );
    textOffsetY += lineHeight;
    context.fillText(
      `Radius of Circle: ${LOCAL_TPS_RADIUS_IN_UM.toFixed(2)}μm`,
      textOffsetX,
      textOffsetY,
    );
  } else {
    const lineHeight = (18 / 30) * tooltipBounds.height;
    const fontSize = (14 / 18) * lineHeight;
    context.font = `300 ${fontSize}px/${lineHeight}px Proxima Nova`;
    context.beginPath();
    context.rect(
      client.x,
      client.y + (tooltipBounds.y - bounds.y),
      tooltipBounds.width,
      tooltipBounds.height,
    );
    context.closePath();
    context.fill();
    // tooltip text
    context.fillStyle = 'rgb(255,255,255)';
    context.textBaseline = 'middle';
    context.textAlign = 'start';
    const textOffsetX = client.x + tooltipBounds.width * (8 / 155);
    const textOffsetY = client.y
      + (tooltipBounds.y - bounds.y)
      + tooltipBounds.height * (6 / 30)
      + lineHeight / 2;
    context.font = `400 ${fontSize}px/${lineHeight}px Proxima Nova`;
    context.fillText('# of Tumor Cells < 100', textOffsetX, textOffsetY);
  }
};

const usePDL1TPSVisualization = (
  resultData: ResultWorkerPreparedEventData | undefined,
) => {
  const viewOptions = useProjectState();
  const { handleCellDrawing } = useWebGLCellDrawing();
  const {
    zoomState: { zoom },
    physicalWidthPx,
  } = useViewerZoom();

  const [cellDetection, tpsMap, liveLocal] = viewOptions.controllers as ControllerOrder['pdl1-tps'];

  // In PDL1-TPS, used for Cell Detection
  const onWebGLOverlayRedraw = useCallback((
    glCanvas: HTMLCanvasElement,
    normalCanvas: HTMLCanvasElement,
    viewer: OpenSeadragon.Viewer,
    origin:Origin,
  ) => {
    if (!resultData) return;
    if (!viewOptions.visible) return;
    if (!cellDetection) return;

    const microscopeWidth1x = physicalWidthPx * 10;
    const currentZoom = getZoomFromViewportZoom(
      viewer.viewport.getZoom(true),
      microscopeWidth1x,
      viewer,
    );

    const gl = glCanvas.getContext('webgl2', {
      antialias: true,
      premultipliedAlpha: false,
    });
    const ctx = normalCanvas.getContext('2d');

    if (!gl) {
      console.log('failed to load webgl context');
      return;
    }
    if (!ctx) {
      console.log('failed to load 2d context');
      return;
    }

    if (
      cellDetection.visible
      && zoom >= 10
      && Math.abs(currentZoom - zoom) < 0.1
      && resultData.cellData
    ) {
      resultData.cellData.forEach((data) => {
        handleCellDrawing(gl, ctx, viewer, data, zoom, origin);
      });
    }
  }, [cellDetection, handleCellDrawing, physicalWidthPx, resultData, viewOptions.visible, zoom]);

  // In PDL1 TPS, used for Tissue Segmentation
  const onCanvasOverlayRedraw: CanvasOverlayProps['onRedraw'] = useCallback(
    (overlayEl: HTMLCanvasElement) => {
      if (!resultData) return;
      if (!viewOptions.visible) return;
      if (!cellDetection || !tpsMap) return;

      const context = overlayEl.getContext('2d');
      if (!context) return;

      if (
        tpsMap.visible
        && resultData.maskImages
        && resultData.maskImageIndex
        && resultData.maskImages[resultData.maskImageIndex[tpsMap.active]]
      ) {
        drawTissueImage(
          context,
          0.3,
          resultData.maskImages[resultData.maskImageIndex[tpsMap.active]],
        );
      }
    },
    [resultData, viewOptions.visible, cellDetection, tpsMap],
  );
  const onTooltipOverlayRedraw: TooltipOverlayProps['onRedraw'] = useCallback(
    ({ overlayCanvasEl, viewer, tooltipCoord }
    : {
      overlayCanvasEl:HTMLCanvasElement,
      viewer:OpenSeadragon.Viewer,
      tooltipCoord?:OpenSeadragon.Point
    }) => {
      const context = overlayCanvasEl.getContext('2d');
      if (
        !liveLocal
        || !context
        || !resultData
        || !resultData.cellData
        || resultData.cellData.length < 2
        || !resultData.slideProperties
        || !resultData.slideProperties.mppX
        || !tooltipCoord
      ) return;
      if (!viewOptions.visible) return;
      const localTPSRadiusInPx = LOCAL_TPS_RADIUS_IN_UM / resultData.slideProperties.mppX;
      if (zoom >= 10 && liveLocal.visible) {
        const counts = map(resultData.cellData, (cells) => ({
          id: cells.id,
          counts: cells.indexedCoords!.within(
            tooltipCoord.x,
            tooltipCoord.y,
            localTPSRadiusInPx,
          ).length,
        }));
        const tooltipMsg = {
          client: tooltipCoord,
          negativeCount: counts.find(({ id }) => id === 'tp-')!.counts,
          positiveCount: counts.find(({ id }) => id === 'tp+')!.counts,
          localTPSRadiusInPx,
        };
        drawBiomarkerInfoTooltip(context, viewer, tooltipMsg);
      }
    },
    [resultData, viewOptions.visible, liveLocal, zoom],
  );
  return { onCanvasOverlayRedraw, onTooltipOverlayRedraw, onWebGLOverlayRedraw };
};

export default usePDL1TPSVisualization;
