import { LabelElement } from 'src/api/biomarkers/models';
import BaseOption from './BaseOption';

import { SingleOptionProps } from '../../models';

function LabelOption({
  uiElement,
  config,
  disableSubOptionToggleButtons,
  isInTooltip,
  subOptionStates,
  showToggleButton,
  ...baseOptionProps
}: SingleOptionProps & {
  uiElement: LabelElement
}) {
  if (isInTooltip) {
    // TODO: handle rendering an option in tooltip
    return null;
  }
  return (
    <BaseOption
      {...baseOptionProps}
      {...baseOptionProps.elementController}
      content={uiElement.content}
    />
  );
}

export default LabelOption;
