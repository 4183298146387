import { Bar, BarLabel, ScoreDot, ScoreText } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/styled';
import { LineChartContainer, PositiveNegativeTitle } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/Charts';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import { AnalysisAccordion } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements';
import getScorePosition, { ScoreAxis } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/Charts/getScorePosition';

const scoreAxis: ScoreAxis = [
  [0, 0],
  [20, 0.2],
  [100, 1],
];

interface Props {
  summaryItem: AnalysisData | undefined
}

function IOChart({ summaryItem }:Props) {
  if (!summaryItem) return null;

  const score = Math.floor(Number(summaryItem.value));
  const [scorePosition, scoreTable] = getScorePosition(scoreAxis, score);

  const title = <PositiveNegativeTitle summaryItem={summaryItem} />;

  const bars = [
    <Bar sx={{ width: scoreTable['20'].size, backgroundColor: '#7292FD' }} />,
    <Bar sx={{ width: scoreTable['100'].size, backgroundColor: '#EE5140' }} />,
  ];

  const barLabels = scoreAxis.map(
    ([coord, position]) => <BarLabel sx={{ left: `${position * 100}%` }}>{coord}</BarLabel>,
  );

  const scoreIndicator = (
    <ScoreDot
      sx={{ left: `${scorePosition * 100}%` }}
      data-value={score}
    />
  );
  const scoreLabel = (
    <ScoreText
      sx={{ left: `${scorePosition * 100}%` }}
      data-value={score}
    >
      {score}
    </ScoreText>
  );

  return (
    <AnalysisAccordion title="Biomarker Findings">
      <LineChartContainer
        title={title}
        bars={bars}
        barLabels={barLabels}
        scoreIndicator={scoreIndicator}
        scoreLabel={scoreLabel}
      />
    </AnalysisAccordion>
  );
}
export default IOChart;
