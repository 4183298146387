import React, { createContext, ReactNode } from 'react';
import {
  SxProps,
  Theme,
} from '@mui/material';
// eslint-disable-next-line import/no-cycle
import useDialog from './useDialog';
// eslint-disable-next-line import/no-cycle
import Dialog from './Template';

export interface IDialogContent {
  handleAgreementCallback?: () => void
  handleDisagreementCallback?: () => void
  content: ReactNode
  title?: ReactNode
  disagree?: string
  agree?: string
  useCustomActionBtns?: boolean
  useCloseBtn?: boolean
  forceAction?: boolean
  disableBackdropClick?: boolean
  width?: number
  height?: number
  loading?: boolean
  divider?: boolean
  agreeButtonSx?: SxProps<Theme>
}
interface IDialogContextProps {
  open: boolean
  handleDialog: (content: IDialogContent) => void
  dialogContent: IDialogContent
}

const DialogContext: React.Context<IDialogContextProps> = createContext({} as IDialogContextProps);
const { Provider } = DialogContext;

interface Props {
  children: React.ReactNode
}
function DialogProvider({ children }: Props) {
  const { open, handleDialog, dialogContent } = useDialog();
  return (
    <Provider value={{ open, handleDialog, dialogContent }}>
      <>
        <Dialog />
        {children}
      </>
    </Provider>
  );
}

export { DialogContext, DialogProvider };
