import type { ComponentsOverrides } from '@mui/material/styles'
import palette from '../colors'

const styleOverrides: ComponentsOverrides['MuiLink'] = {
  root: {
    boxSizing: 'border-box',
    textDecoration: 'none',
    color: palette.scope1,
  },
  button: {
    position: 'initial',
    display: 'inline',
    font: 'inherit',
    verticalAlign: 'initial',
  },
}
export default {
  styleOverrides,
}
