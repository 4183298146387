export const getPDL1TPSResultWorker = () => new Worker(new URL('./pdl1-tps/PDL1TPSResult.worker.ts', import.meta.url));

export const getTumorPurityResultWorker = () => new Worker(
  new URL('./tumor-purity/TumorPurityResult.worker.ts', import.meta.url),
);

export const getHER2ResultWorker = () => new Worker(new URL('./her2/HER2Result.worker.ts', import.meta.url));

export const getIOResultWorker = () => new Worker(new URL('./io/IOResult.worker.ts', import.meta.url));

export const getCPSBreastResultWorker = () => new Worker(new URL('./cps-breast/CPSBreastResult.worker.ts', import.meta.url));

export const getCPSBladderResultWorker = () => new Worker(new URL('./cps-bladder/CPSBladderResult.worker.ts', import.meta.url));
