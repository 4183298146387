import * as React from 'react'

const SvgZoomIn = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 3a.5.5 0 0 0-1 0v6.5H3a.5.5 0 0 0 0 1h6.5V17a.5.5 0 0 0 1 0v-6.5H17a.5.5 0 0 0 0-1h-6.5V3Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgZoomIn
