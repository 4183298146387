import type { ComponentsOverrides } from '@mui/material/styles'

const styleOverrides: ComponentsOverrides['MuiListItem'] = {
  root: {
    height: '40px',
    padding: '10px 8px',
  },
}
export default {
  styleOverrides,
}
