import useSlideFromPath from 'src/hooks/useSlideFromPath';
import { useHER2ResultData } from 'src/workers/hooks';

import { ProjectPageProps } from 'src/types';

import ProjectPage from 'src/components/common/ProjectPage';
import HER2Viewer from './HER2Viewer';
import HER2AnalysisSummary from './HER2AnalysisSummary';
import HER2DensityHeatmapTooltip from './HER2DensityHeatmapTooltip';

function HER2({ metadata, projectConfig }: ProjectPageProps) {
  const { currentSlide } = useSlideFromPath(metadata.results);
  const { data, isLoading } = useHER2ResultData(
    currentSlide?.resultPath,
    metadata.uiComponents,
  );

  if (!currentSlide) return null;

  const viewer = (
    <HER2Viewer
      slideData={{
        slideDziUrl: currentSlide.slideDziUrl,
        slideDziMetaUrl: currentSlide.slideDziMetaUrl,
      }}
      resultData={data}
    />

  );

  const analysisSummaryPanel = (
    <HER2AnalysisSummary
      uiComponents={metadata?.uiComponents}
      summary={data?.analysisSummary}
    />
  );

  const infoTooltipConfig = [{
    name: 'Density Heatmap',
    element: <HER2DensityHeatmapTooltip uiComponents={metadata.uiComponents} />,
  }];
  return (
    <ProjectPage
      viewer={viewer}
      analysisSummaryPanel={analysisSummaryPanel}
      isLoadingResultData={isLoading}
      metadata={metadata}
      projectConfig={projectConfig}
      infoTooltipConfig={infoTooltipConfig}
    />
  );
}

export default HER2;
