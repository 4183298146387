import {
  ViewerToolbar,
  ToolbarLeft,
  ToolbarRight,
} from '@lunit/scope-components/Analysis/ViewerToolbar/ToolbarElements';

import KeyboardShortcutsButton from './KeyboardShortcuts/KeyboardShortcutsButton';

function BasicToolbar() {
  return (
    <ViewerToolbar>
      <ToolbarLeft>
        <span />
      </ToolbarLeft>
      <ToolbarRight>
        <KeyboardShortcutsButton />
      </ToolbarRight>
    </ViewerToolbar>
  );
}
export default BasicToolbar;
