import { Button, styled, Tooltip } from '@mui/material';
import HeaderButton from '@lunit/scope-components/HeaderButton';

import useCurrentProduct from 'src/hooks/useCurrentProduct';
import { ArrowIcon } from '@lunit/scope-icons';
import QuickAppMenu from './QuickAppMenu';
import ProductLogo from '../../ProductLogo';

import { useBiomarkers } from '../../../../hooks/useBiomarkerQueries';
import usePopper from '../../../../hooks/usePopper';

const SelectButton = styled(Button)(({ theme }) => ({
  border: '1px solid transparent',
  minWidth: 'auto',
  height: '28px',
  padding: theme.spacing(0.5),
  color: theme.palette.darkGrey[10],
  '.MuiButton-endIcon': { margin: 0 },
}));

function QuickAppSelector() {
  const { anchorEl, onClose, onOpen, open } = usePopper();
  const { data: biomarkers, isLoading } = useBiomarkers();

  const currentProduct = useCurrentProduct();

  return (
    <Tooltip title={!open ? 'Apps' : ''}>
      <span>
        <HeaderButton
          onClick={onOpen}
          sx={{ padding: 0, ':hover': { color: '#D4D4D6' } }}
        >
          <ProductLogo
            product={currentProduct}
            fallback
          />
          {!isLoading && (
          <SelectButton
            sx={{ color: (theme) => theme.palette.darkGrey[10] }}
            endIcon={(
              <ArrowIcon
                style={{ transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
              )}
          />
          )}
        </HeaderButton>
        <QuickAppMenu
          isOpen={open}
          anchorEl={anchorEl}
          onClose={onClose}
          isLoading={isLoading}
          products={biomarkers?.products ?? []}
          currentProductPath={currentProduct?.path}
        />
      </span>
    </Tooltip>
  );
}
export default QuickAppSelector;
