import { DeleteIcon } from '@lunit/scope-icons';
import { tooltipWithShortcut } from '../../ToolbarMUI';
import HeaderButton from '../../../../HeaderButton';

interface DeleteSelectedButtonProps {
  shouldHide?: boolean
  onDeleteSelected: () => void
}

function DeleteSelectedButton({
  shouldHide,
  onDeleteSelected,
}: DeleteSelectedButtonProps) {
  if (shouldHide) return null;
  return (
    <HeaderButton
      sx={{ color: (theme) => theme.palette.darkGrey[0] }}
      shortcut="Backspace"
      tooltip={tooltipWithShortcut('Delete Selected', 'Backspace')}
      disabled={!onDeleteSelected}
      onClick={onDeleteSelected}
      onShortcutPress={onDeleteSelected}
    >
      <DeleteIcon />
    </HeaderButton>
  );
}

export default DeleteSelectedButton;
