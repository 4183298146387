import useSlideFromPath from 'src/hooks/useSlideFromPath';
import { useCPSBreastResultData } from 'src/workers/hooks';

import { ProjectPageProps } from 'src/types';

import ProjectPage from 'src/components/common/ProjectPage';
import CPSAnalysisSummary from 'src/components/CPSAnalysisSummary';
import CPSBreastSlideViewer from './CPSBreastSlideViewer';

function CPSBreast({ metadata, projectConfig }: ProjectPageProps) {
  const { currentSlide } = useSlideFromPath(metadata.results);

  const { data, isLoading } = useCPSBreastResultData(
    currentSlide?.resultPath,
    metadata.uiComponents,
  );
  if (!currentSlide) return null;

  const viewer = (
    <CPSBreastSlideViewer
      slideData={{
        slideDziUrl: currentSlide.slideDziUrl,
        slideDziMetaUrl: currentSlide.slideDziMetaUrl,
      }}
      resultData={data}
    />
  );
  const analysisSummaryPanel = (
    <CPSAnalysisSummary
      uiComponents={metadata.uiComponents}
      summary={data?.analysisSummary}
    />
  );

  return (
    <ProjectPage
      viewer={viewer}
      analysisSummaryPanel={analysisSummaryPanel}
      isLoadingResultData={isLoading}
      metadata={metadata}
      projectConfig={projectConfig}
    />
  );
}

export default CPSBreast;
