import { Checkbox, FormControlLabel, FormHelperText, FormLabel, Grid, TextField, Typography } from '@mui/material';
import { map, sortBy } from 'lodash';
import React from 'react';
import { Controller, Control, UseFormRegister } from 'react-hook-form';
import { SignUpInfo, SurveyOption } from 'src/api/auth/models';

const LONG_OPTIONS = [
  'Academic & research institute',
  'Contract Research Organization (CRO)',
];

const CONSTRAIN_WIDTH_ON_DETAIL = [
  'Which of the following digital pathology systems are of interest or used?',
];

interface Props {
  register: UseFormRegister<any>
  control: Control<any, any>
  errors:any
  survey:SignUpInfo['survey'] | undefined
  handleCheckSurvey:(option: SurveyOption, checked: boolean) => any
  showSurveyDetail: {
    [question: string]: boolean;
  }
}

function Survey({ register, control, errors, survey, handleCheckSurvey, showSurveyDetail }: Props) {
  if (!survey) {
    return null;
  }
  return (
    <>
      {
        map(sortBy(survey, 'seq'), (question) => (
          <Controller
            key={question.surveyQuestionId}
            name={`survey_${question.surveyQuestionId}`}
            control={control}
            rules={{
              validate: (checkedOptions = []) => {
                const matchedOption = checkedOptions.find(
                  (option: any) => option.surveyQuestionId === question.surveyQuestionId,
                );
                return matchedOption != null
                  ? true
                  : 'This field is required.';
              },
            }}
            render={({ field: { onChange, name, ref } }) => (
              <Grid
                item
                xs={12}
              >
                <Grid
                  key={question.surveyQuestionId}
                  container
                  spacing={0}
                  sx={{ mt: 1 }}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <FormLabel component="legend">
                      <Typography
                        variant="body5"
                        color="darkGrey.5"
                        sx={{ mb: 1 }}
                      >
                        {question.title}
                        *
                      </Typography>
                    </FormLabel>
                  </Grid>
                  {question.options.map((option, optionIndex) => (
                    <React.Fragment key={option.surveyOptionId}>
                      <Grid
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                        item
                        xs={
                    // eslint-disable-next-line no-nested-ternary
                    LONG_OPTIONS.findIndex(
                      (val) => val === option.optionName,
                    ) < 0
                      ? option.detailRequired
                        ? 12
                        : 4
                      : 8
                  }
                      >
                        <FormControlLabel
                          control={(
                            <Checkbox
                              inputRef={optionIndex === 0 ? ref : undefined}
                              name={name}
                              onChange={(event) => {
                                onChange(
                                  handleCheckSurvey(
                                    option,
                                    event.target.checked,
                                  ),
                                );
                              }}
                            />
                    )}
                          key={option.optionName}
                          label={(
                            <Typography
                              variant="body5"
                              color="darkGrey.30"
                            >
                              {option.optionName}
                            </Typography>
                    )}
                          disableTypography
                        />
                        {option.detailRequired && (
                        <TextField
                          id={`${question.surveyQuestionId}_${option.surveyOptionId}`}
                          variant="outlined"
                          sx={{
                            flex: 1,
                            maxWidth:
                          CONSTRAIN_WIDTH_ON_DETAIL.findIndex((
                            val,
                          ) => question.title.includes(val)) >= 0 ? '440px' : 'auto',
                          }}
                          disabled={
                        !showSurveyDetail[
                          `${question.surveyQuestionId}_${option.surveyOptionId}`
                        ]
                      }
                          placeholder="Tell us a bit more about your request"
                          error={
                        errors[
                          `${question.surveyQuestionId}_${option.surveyOptionId}`
                        ]
                      }
                          helperText={
                        errors[
                          `${question.surveyQuestionId}_${option.surveyOptionId}`
                        ]
                        && errors[
                          `${question.surveyQuestionId}_${option.surveyOptionId}`
                        ].message
                      }
                          {...register(
                            `${question.surveyQuestionId}_${option.surveyOptionId}`,
                            {
                              maxLength: {
                                value: 100,
                                message: 'Maximum 100 characters.',
                              },
                            },
                          )}
                        />
                        )}
                      </Grid>
                    </React.Fragment>
                  ))}
                  {errors[`survey_${question.surveyQuestionId}`]?.message
              != null && (
              <Grid
                item
                xs={12}
              >
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: (theme) => theme.palette.error.main,
                  }}
                >
                  {
                    errors[`survey_${question.surveyQuestionId}`]
                      ?.message
                  }
                </FormHelperText>
              </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          />
        ))
}

    </>
  );
}
export default Survey;
