import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';

import { Product } from 'src/pages/home/ProductList/models';
import { useBiomarkers } from './useBiomarkerQueries';

function useCurrentProduct() {
  const { data: biomarkers } = useBiomarkers();
  const match = useMatch('/:productPath/*');

  return useMemo<Product | undefined>(() => {
    if (!match || !biomarkers) {
      return undefined;
    }
    const path = match.params.productPath;
    const productsWithLogos = biomarkers.products as Product[];
    return productsWithLogos.find(
      (product) => product.path === path,
    );
  }, [match, biomarkers]);
}

export default useCurrentProduct;
