import {
  ControllerProps,
  WithSubChildControllerProps,
  ProjectConfigSpec,
} from 'src/types';

export const tpConfig: ProjectConfigSpec = {
  projectName: 'tumor-purity',
  titleHasTooltip: false,
  hasSummarySelector: false,
  elementConfig: [
    {
      name: 'Tumor Density Map',
      elementType: 'heatmap',
      childOptions: 'hide',
    },
    {
      name: 'Cell Detection',
      elementType: 'label',
      childOptions: 'show-with-state',
    },
    {
      name: 'Tissue Segmentation',
      elementType: 'label',
      childOptions: 'show-with-state',
    },
  ],
};

export type TPControllerOrder = [
  ControllerProps,
  WithSubChildControllerProps,
  WithSubChildControllerProps,
];
