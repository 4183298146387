import * as React from 'react'

const SvgZoomOut = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17 10H3" stroke="currentColor" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 10a.5.5 0 0 0 .5.5h14a.5.5 0 0 0 0-1H3a.5.5 0 0 0-.5.5Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgZoomOut
