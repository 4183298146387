import colormap from 'colormap';
import {
  ColorMap,
  ColorMapStop,
  getGradientFromColors,
  SectionListItem,
} from '../../../styled';

const STOP_WIDTH = 54;
interface Props {
  isInTooltip:boolean,
  hasCenter:boolean
}

function HeatmapSubOption({
  isInTooltip = false,
  hasCenter = false,
}: Props) {
  const gradientFromColors = getGradientFromColors(
    colormap({
      colormap: 'jet',
      nshades: 101,
      format: 'hex',
      alpha: 1,
    }),
  );

  return (
    <SectionListItem
      sx={{
        height: 30,
        alignItems: 'flex-start',
        flex: '0 0 30px',
        position: 'relative',
        paddingTop: '4px',
        padding: isInTooltip ? '0' : '0 20px',
      }}
    >
      <ColorMap sx={{ background: gradientFromColors }} />
      <ColorMapStop
        sx={{
          textAlign: 'left',
          left: isInTooltip ? '0' : '20px',
        }}
      >
        0%
      </ColorMapStop>
      {hasCenter && (
      <ColorMapStop
        sx={{
          textAlign: 'center',
          left: `calc(50% - ${STOP_WIDTH / 2}px)`,
        }}
      >
        Local TPS
      </ColorMapStop>
      )}

      <ColorMapStop
        sx={{
          textAlign: 'right',
          right: isInTooltip ? '0' : '20px',
        }}
      >
        100%
      </ColorMapStop>
    </SectionListItem>
  );
}

export default HeatmapSubOption;
