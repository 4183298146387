import * as React from 'react'

const SvgDelete = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.596 8.775a.5.5 0 0 1 0 .707l-1.768 1.768 1.768 1.768a.5.5 0 1 1-.707.707l-1.768-1.768-1.767 1.768a.5.5 0 0 1-.708-.707l1.768-1.768-1.768-1.768a.5.5 0 1 1 .708-.707l1.767 1.768 1.768-1.768a.5.5 0 0 1 .707 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.573 15.692 2.787 5.5H2a.5.5 0 0 1 0-1h4.5V4A2.5 2.5 0 0 1 9 1.5h2A2.5 2.5 0 0 1 13.5 4v.5H18a.5.5 0 0 1 0 1h-.787l-.786 10.192A2.5 2.5 0 0 1 13.934 18H6.066a2.5 2.5 0 0 1-2.493-2.308ZM16.21 5.5H3.79l.78 10.115A1.5 1.5 0 0 0 6.066 17h7.868a1.5 1.5 0 0 0 1.496-1.385L16.21 5.5Zm-3.71-1V4A1.5 1.5 0 0 0 11 2.5H9A1.5 1.5 0 0 0 7.5 4v.5h5Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgDelete
