import { Dialog, DialogContent, Typography, Box } from '@mui/material';
import { Biomarkers } from 'src/api/biomarkers/models';
import ProductList from 'src/pages/home/ProductList';

function Home({ products }: { products: Biomarkers['products'] }) {
  return (
    <Dialog
      PaperProps={{
        elevation: 1,
        sx: {
          width: '1052px',
          minWidth: '1052px',
          height: '762px',
          padding: (theme) => theme.spacing(4, 4, 1),
        },
      }}
      open
    >
      <Box
        sx={{
          width: '420px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: '#30DFFC' }} // TODO - find this in the theme
        >
          Welcome to Lunit!
        </Typography>
        <Typography variant="h2">
          Which product
          <br />
          would you like to try?
        </Typography>
      </Box>
      <DialogContent
        sx={{
          padding: (theme) => theme.spacing(5, 0, 0, 0),
          display: 'flex',
        }}
      >
        <ProductList products={products} />
      </DialogContent>
    </Dialog>
  );
}

export default Home;
