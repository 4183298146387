import React from 'react';
import Box from '@mui/material/Box';
import { MenuItemProps } from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { ArrowIcon } from '@lunit/scope-icons';
import { SxProps } from '@mui/system';
import usePopper from './hooks/usePopper';
import SelectItem from './Select/SelectItem';
import EllipsizedTypography, { EllipsizedTypographyProps } from './EllipsizedTypography';
import EditableSelectItem from './EditableSelectItem';

interface PlainSelectProps {
  children:
  | React.ReactElement<MenuItemProps>
  | React.ReactElement<MenuItemProps>[]
  value: any
  disabled?: boolean
  SelectedOptionTypographyProps?: EllipsizedTypographyProps
  optionEditable?: boolean
  onEditOption?: (option: { value: any; label: string }) => void
  isOptionEditable?: (index: number) => boolean
  onDeleteOption?: (value: any) => void
  onChange: (value: any) => void
  getOptionIcon?: (value: any) => React.ReactNode
  sx?: SxProps
}

interface SelectItemOptions {
  menuItemEl: React.ReactElement<MenuItemProps>
  selectedValue: any
  onClick: () => void
  onEditOption?: (option: { value: any; label: string }) => void
  onDeleteOption?: (value: any) => void
  getOptionIcon?: (value: any) => React.ReactNode
  isOptionEditable?: (index: number) => boolean
  index: number
  optionEditable: boolean
}

function selectItemFromMenuItemElement({
  menuItemEl,
  selectedValue,
  onClick,
  onEditOption,
  onDeleteOption,
  isOptionEditable,
  index,
  getOptionIcon,
  optionEditable,
}: SelectItemOptions) {
  if (optionEditable) {
    return React.createElement(EditableSelectItem, {
      ...menuItemEl.props,
      selected: selectedValue === menuItemEl.props.value,
      isOptionEditable: isOptionEditable ? isOptionEditable(index) : true,
      onEditOption,
      onDeleteOption,
      getOptionIcon,
      onClick,
    });
  }
  return React.createElement(SelectItem, {
    ...menuItemEl.props,
    selected: selectedValue === menuItemEl.props.value,
    onClick,
  });
}

function PlainSelect({
  children,
  value,
  onChange,
  optionEditable,
  onEditOption,
  onDeleteOption,
  isOptionEditable,
  getOptionIcon,
  SelectedOptionTypographyProps,
  disabled,
  sx,
}: PlainSelectProps) {
  let selectedOptionTitle: React.ReactNode = 'EMPTY';
  const { anchorEl, onClose, onOpen, open } = usePopper();
  React.Children.forEach(children, (option) => {
    if (option.props.value === value) {
      selectedOptionTitle = option.props.children;
    }
  });
  return (
    <>
      <Box
        tabIndex={0}
        onClick={disabled ? undefined : onOpen}
        sx={{
          border: '1px solid transparent',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          marginLeft: '-12px',
          paddingLeft: '11px',
          paddingRight: '11px',
          height: '28px',
          cursor: 'default',
          ...sx,
          ...(!disabled
            ? {
              cursor: 'pointer',
              '&:hover': { borderColor: (theme) => theme.palette.darkGrey[30] },
              '&:focus': { borderColor: (theme) => theme.palette.scope1.main },
            }
            : {}),
        }}
      >
        <EllipsizedTypography
          variant="body5"
          {...SelectedOptionTypographyProps}
          sx={{
            opacity: selectedOptionTitle === 'EMPTY' ? 0.5 : 1,
            ...SelectedOptionTypographyProps?.sx,
          }}
        >
          {selectedOptionTitle}
        </EllipsizedTypography>
        <ArrowIcon />
      </Box>
      {!disabled && (
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          PaperProps={{
            style: {
              width: 436,
              maxHeight: 300,
              overflowY: 'auto',
              marginTop: 4,
            },
          }}
          disableAutoFocus
          disableEnforceFocus
          disableAutoFocusItem
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {React.Children.map(children, (menuItemEl, index) => selectItemFromMenuItemElement({
            menuItemEl,
            selectedValue: value,
            onClick: () => {
              onChange(menuItemEl.props.value);
              onClose();
            },
            getOptionIcon,
            onEditOption,
            onDeleteOption,
            isOptionEditable,
            index,
            optionEditable,
          }))}
        </Menu>
      )}
    </>
  );
}

export default PlainSelect;
