import { SubLabelElement } from 'src/api/biomarkers/models';
import { SubSectionListItem, Typography } from '../../../styled';

function SubLabelOption({ uiElement }: { uiElement: SubLabelElement }) {
  return (
    <SubSectionListItem
      sx={{
        alignItems: 'flex-start',
        paddingTop: '4px',
      }}
    >
      <Typography
        variant="caption"
        color="darkGrey.30"
      >
        {uiElement.content}
      </Typography>
    </SubSectionListItem>
  );
}

export default SubLabelOption;
