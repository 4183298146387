import { CanvasOverlayProps } from '@lunit/osd-react-renderer';
import forEach from 'lodash/forEach';
import { useCallback } from 'react';
import useHER2SummarySelection from 'src/pages/projects/her2/useHER2SummarySelection';
import { ControllerOrder } from 'src/const/projectConfigSpecs';
import { ResultWorkerPreparedEventData } from 'src/workers/AnalysisResultWorker/types';
import { useProjectState } from '../projectConfig';
import useViewerZoom from '../useViewerZoom';
import { drawTissueImage } from './func/drawing';
import { getZoomFromViewportZoom } from './func/utils';
import useWebGLCellDrawing from './webgl/useWebGLCellDrawing';
import { Origin } from './webgl/types';

const useHER2Visualization = (
  resultData: ResultWorkerPreparedEventData | undefined,
) => {
  const viewOptions = useProjectState();
  const { handleCellDrawing } = useWebGLCellDrawing();
  const {
    zoomState: { zoom },
    physicalWidthPx,
  } = useViewerZoom();

  const summaryTab = useHER2SummarySelection();

  const [densityMap, cellDetection, tissueSegmentation] = viewOptions.controllers as ControllerOrder['her2'];

  const onWebGLOverlayRedraw = useCallback((
    glCanvas: HTMLCanvasElement,
    normalCanvas: HTMLCanvasElement,
    viewer: OpenSeadragon.Viewer,
    origin:Origin,
  ) => {
    if (!resultData) return;
    if (!viewOptions.visible) return;
    if (!cellDetection) return;

    const microscopeWidth1x = physicalWidthPx * 10;
    const currentZoom = getZoomFromViewportZoom(
      viewer.viewport.getZoom(true),
      microscopeWidth1x,
      viewer,
    );

    const gl = glCanvas.getContext('webgl2', {
      antialias: true,
      premultipliedAlpha: false,
    });
    const ctx = normalCanvas.getContext('2d');

    if (!gl) {
      console.log('failed to load webgl context');
      return;
    }
    if (!ctx) {
      console.log('failed to load 2d context');
      return;
    }
    if (
      cellDetection.visible
      && zoom >= 10
      && Math.abs(currentZoom - zoom) < 0.1
      && resultData.cellData
    ) {
      forEach(cellDetection.childController, (cell, index) => {
        if (cell.visible && resultData.cellData && resultData.cellData[index]) {
          handleCellDrawing(gl, ctx, viewer, resultData.cellData[index], zoom, origin);
        }
      });
    }
  }, [cellDetection, handleCellDrawing, physicalWidthPx, resultData, viewOptions.visible, zoom]);

  const onCanvasOverlayRedraw: CanvasOverlayProps['onRedraw'] = useCallback(
    (overlayEl: HTMLCanvasElement) => {
      if (!resultData) return;
      if (!viewOptions.visible) return;
      if (!tissueSegmentation || !densityMap) return;

      const context = overlayEl.getContext('2d');
      if (!context) return;
      if (tissueSegmentation.visible && resultData.maskImages) {
        forEach(tissueSegmentation.childController, (tissue, index) => {
          if (
            tissue.visible
            && resultData.maskImages
            && resultData.maskImageIndex
            && resultData.maskImages[
              resultData.maskImageIndex['Tissue Segmentation'] + index
            ]
          ) {
            drawTissueImage(
              context,
              0.3,
              resultData.maskImages[
                resultData.maskImageIndex['Tissue Segmentation'] + index
              ],
            );
          }
        });
      }
      if (
        densityMap.visible
        && resultData.maskImages
        && resultData.maskImageIndex
        && resultData.maskImages[
          resultData.maskImageIndex[`Density Heatmap_${summaryTab.value}`]
        ]
      ) {
        // render density map
        drawTissueImage(
          context,
          0.3,
          resultData.maskImages[
            resultData.maskImageIndex[`Density Heatmap_${summaryTab.value}`]
          ],
        );
      }
    },
    [resultData, tissueSegmentation, densityMap, summaryTab.value, viewOptions.visible],
  );
  return { onCanvasOverlayRedraw, onWebGLOverlayRedraw };
};

export default useHER2Visualization;
