import { styled } from '@mui/material/styles';
import { ViewerToolType } from '../../../types';

// import { Crosshair, Polygon, Pointer, Hand } from '@lunit/scope-icons/assets'

// function cursorTypeByActiveTool(toolType: ViewerToolType, toolId: string) {
//   switch (toolType) {
//     case ViewerToolType.ROI:
//       if (toolId !== 'Polygon') {
//         return `url(${Crosshair}) 10 10, auto`
//       }
//       return `url(${Polygon}) 10 10, auto`
//     case ViewerToolType.Cell:
//       if (toolId === 'Polygon') return `url(${Polygon}) 10 10, auto`
//       return `url(${Crosshair}) 9 9, auto`
//     case ViewerToolType.Pointer:
//       if (toolId === 'Pointer') return `url(${Pointer}) 6 3, auto`
//       else if (toolId === 'Hand') return `url(${Hand}) 9 10, auto`
//       return 'default'
//     default:
//       return 'default'
//   }
// }

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const OuterViewerContainer = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
});

const InnerViewerContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'activeToolType'
    && prop !== 'activeToolId'
    && prop !== 'tempHandEnabled'
    && prop !== 'toolsEnabled',
})<
React.DetailedHTMLProps<
React.HTMLAttributes<HTMLDivElement>,
HTMLDivElement
> & {
  activeToolType: ViewerToolType
  activeToolId: string
  tempHandEnabled: boolean
  toolsEnabled: boolean
}
>(() => ({
  width: 'calc(100% - 240px)',
  height: '100%',
  // cursor: toolsEnabled
  //   ? tempHandEnabled
  //     ? `url(${Hand}) 9 10, auto`
  //     : cursorTypeByActiveTool(activeToolType, activeToolId)
  //   : 'default',
  cursor: 'default',
}));

const osdContainerSX = {
  position: 'relative',
  height: '100%',
  backgroundColor: '#fff',
  '& .navigator': {
    width: '160px !important',
    height: '160px !important',
    border: 'solid 1px rgba(134, 148, 177, 0.16) !important',
    backgroundColor: '#fff !important',
    marginTop: '16px !important',
    marginRight: '16px !important',
    borderRadius: '4px',
  },
  '& .openseadragon-canvas': { outline: 'none' },
  '& .displayregion': { border: '2px solid #5A79E3 !important' },
} as const;

const OSDSlideContainer = styled('div')({
  height: '100%',
  '.navigator': {
    width: '160px !important',
    height: '160px !important',
    border: 'solid 1px #3E3E40 !important',
    marginTop: '16px !important',
    marginRight: '16px !important',
    borderRadius: '8px',
  },
});

export {
  Container,
  OuterViewerContainer,
  InnerViewerContainer,
  osdContainerSX,
  OSDSlideContainer,
};
