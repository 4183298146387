import * as React from 'react'

const SvgHidden = props => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 18 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.508 3.333 1 4.827M15.492 3.333 17 4.827M5.32 4.833l-.607 2.033M12.68 4.833l.607 2.033M9.002 5.375v2.121M17.375.6S15.7 5.374 9 5.374.625.6.625.6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgHidden
