import { styled } from '@mui/material';

export const ShortcutListContainer = styled('div')({
  width: '100%',
  padding: '16px 32px',
  maxHeight: '800px',
  overflowY: 'auto',
});

export const ShortcutTextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '10px',

  '& > .MuiTypography-root': {
    padding: '4px 8px',
    border: `1px solid ${theme.palette.darkGrey[70]}`,
    borderRadius: '8px',
  },
}));

export const ViewIconContainer = styled('div')({
  minWidth: '32px',
  display: 'flex',
  alignItems: 'center',
});
