import { DrawerNavLink, EllipsizedTypography } from '@lunit/scope-components';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Skeleton from 'react-loading-skeleton';
import { Slide } from 'src/api/biomarkers/models';
import LoadableImage from './LoadableImage';
import { SlideLinkContainer } from './styled';

interface SlideLinkProps {
  loading?: boolean
  base?: string
  slide?: Slide
  refetchResults?: () => void
}

// eslint-disable-next-line import/prefer-default-export
export function SlideLink({
  loading,
  slide,
  base = '',
  refetchResults,
}: SlideLinkProps) {
  return (
    <ListItem sx={{ height: 'auto', padding: (theme) => theme.spacing(0.5, 1) }}>
      <ListItemButton
        to={
          slide
            ? `/${base}/${encodeURIComponent(slide.description)}`
            : 'undefined'
        }
        disabled={loading || !slide}
        component={DrawerNavLink}
        activeClassName="bgActive"
        sx={{
          width: '100%',
          textDecoration: 'none',
        }}
      >
        <SlideLinkContainer>
          <Box
            sx={{
              lineHeight: 1,
              width: 60,
              height: '100%',
            }}
          >
            {loading || !slide ? (
              <Skeleton
                height={98}
                width={60}
              />
            ) : (
              <LoadableImage
                src={slide.slideThumbnailUrl}
                onRefetch={refetchResults}
                fallback="/empty-thumbnail.png"
                loadingSkeleton={(
                  <Skeleton
                    height={98}
                    width={60}
                  />
)}
              />
            )}
          </Box>
          <EllipsizedTypography
            sx={{
              maxHeight: 98,
              flex: 1,
              marginLeft: '12px',
              WebkitLineClamp: 3,
              wordBreak: 'break-all',
            }}
            direction="column"
            variant="body2"
          >
            {loading || !slide ? <Skeleton width={136} /> : slide.description}
          </EllipsizedTypography>
        </SlideLinkContainer>
      </ListItemButton>
    </ListItem>
  );
}
