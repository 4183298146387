import { map } from 'lodash';
import { UIComponents, UIElement } from 'src/api/biomarkers/models';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import { Box, SectionListItem, Typography } from '../styled';
import { findLabelElementByContent, getColorFromMarkerLabel, getStringFromHasComma } from './func';
import { CenteredBox } from './styled';

interface Props {
  summaryItem:AnalysisData
  uiComponents:UIComponents | undefined
}
function findCellColor(uiElements:UIElement[] | undefined, labelID:string) {
  return getColorFromMarkerLabel(findLabelElementByContent(uiElements, 'Cell Detection'), labelID);
}
function replacePosNegString(title: string):string {
  let ret = title.replace('+', '+');
  ret = ret.replace('-', '-');
  return ret;
}

function TotalBreakdownTable({ summaryItem, uiComponents }:Props) {
  return (
    <>
      <SectionListItem sx={{ paddingRight: 2.5 }}>
        <CenteredBox>
          <Typography variant="body5">{summaryItem.name}</Typography>
        </CenteredBox>
        <CenteredBox>
          <Typography variant="body4">
            {getStringFromHasComma(String(summaryItem.value))}
          </Typography>
        </CenteredBox>
      </SectionListItem>
      {map(summaryItem.items, (item) => (
        <SectionListItem
          sx={{ paddingRight: 2.5 }}
          key={item.labelId}
        >
          <CenteredBox>
            <Box
              sx={{
                width: 4,
                height: 4,
                borderRadius: 2,
                backgroundColor: findCellColor(uiComponents?.elements, item.labelId!),
                marginRight: '6px',
              }}
            />
            <Typography variant="body5">
              {replacePosNegString(item.name!)}
            </Typography>
          </CenteredBox>
          <CenteredBox>
            <Typography variant="body4">
              {getStringFromHasComma(String(item.value))}
            </Typography>
          </CenteredBox>
        </SectionListItem>
      ))}
    </>
  );
}
export default TotalBreakdownTable;
