import useSlideFromPath from 'src/hooks/useSlideFromPath';
import { useTumorPurityResultData } from 'src/workers/hooks';

import { ProjectPageProps } from 'src/types';

import ProjectPage from 'src/components/common/ProjectPage';
import TumorPurityViewer from './TumorPurityViewer';
import TumorPurityAnalysisSummary from './TumorPurityAnalysisSummary';

function TumorPurity({ metadata, projectConfig }:ProjectPageProps) {
  const { currentSlide } = useSlideFromPath(metadata.results);
  const { data, isLoading } = useTumorPurityResultData(
    currentSlide?.resultPath,
    metadata.uiComponents,
  );

  if (!currentSlide) return null;

  const viewer = (
    <TumorPurityViewer
      slideData={{
        slideDziUrl: currentSlide.slideDziUrl,
        slideDziMetaUrl: currentSlide.slideDziMetaUrl,
      }}
      resultData={data}
    />
  );

  const analysisSummaryPanel = <TumorPurityAnalysisSummary summary={data?.analysisSummary} />;

  return (
    <ProjectPage
      viewer={viewer}
      analysisSummaryPanel={analysisSummaryPanel}
      isLoadingResultData={isLoading}
      metadata={metadata}
      projectConfig={projectConfig}
    />
  );
}

export default TumorPurity;
