import { Typography } from '@mui/material';

import { numberWithCommas } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/func';
import { SectionListItem } from 'src/components/common/ViewOptionsPanel/styled';

import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';

interface Props {
  analysisData:AnalysisData[]
}

function TumorPurityTable({ analysisData }:Props) {
  return (
    <>
      { analysisData.map((resultData, idx) => (
        <SectionListItem
      // eslint-disable-next-line react/no-array-index-key
          key={`${resultData.name}_${idx}`}
        >
          <Typography variant="body5">{resultData.name}</Typography>
          <Typography variant="body5">
            {numberWithCommas(Math.round(Number(resultData.value)))}
          </Typography>
        </SectionListItem>
      ))}
    </>
  );
}
export default TumorPurityTable;
