import { ComponentType, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import accessTokenManager from '../api/utils/accessTokenManager';

export default function withAuthGuard(
  Component: ComponentType<any>,
): ComponentType<any> {
  // eslint-disable-next-line func-names
  return function () {
    const [hasAccessToken, setHasAccessToken] = useState(
      accessTokenManager.hasAccessTokenInStorage(),
    );
    // eslint-disable-next-line consistent-return
    useEffect(() => {
      if (!hasAccessToken) {
        const handler: EventListener = () => {
          setHasAccessToken(accessTokenManager.hasAccessTokenInStorage());
        };
        document.addEventListener('accesstoken', handler);
        return () => document.removeEventListener('accesstoken', handler);
      }
    }, [hasAccessToken]);
    if (!hasAccessToken || !accessTokenManager.isAccessTokenSane()) {
      accessTokenManager.removeAccessToken();
      return <Navigate to="login" />;
    }
    return <Component />;
  };
}
