import { UIComponents } from 'src/api/biomarkers/models';
import { TotalBreakdownTable } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements';
import AnalysisSummaryPanel from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/AnalysisSummaryPanel';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import PDL1TPSChart from './PDL1TPSChart';

interface PDL1TPSAnalysisSummaryProps {
  uiComponents: UIComponents | undefined
  summary: AnalysisData[] | undefined
}

function PDL1TPSAnalysisSummary({
  uiComponents,
  summary,
}: PDL1TPSAnalysisSummaryProps) {
  return (
    <AnalysisSummaryPanel>
      {summary && (
        <>
          <PDL1TPSChart summaryItem={summary[0]} />
          <TotalBreakdownTable
            summaryItem={summary[1]}
            uiComponents={uiComponents}
          />
        </>
      )}
    </AnalysisSummaryPanel>
  );
}

export default PDL1TPSAnalysisSummary;
