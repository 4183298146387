import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';

import * as authAPI from 'src/api/auth/queries';
import { APIError } from 'src/api/utils/apiRequestHelper';
import { AlertColor, Link } from '@mui/material';
import AuthTemplate from '@lunit/scope-components/Auth/AuthTemplate';
import AuthHeading from '@lunit/scope-components/Auth/AuthHeading';
import AuthNav from '@lunit/scope-components/Auth/AuthNav';
import AuthDescription from '@lunit/scope-components/Auth/AuthDescription';
import AuthTextField from '@lunit/scope-components/Auth/AuthTextField';
import AuthActionButton from '@lunit/scope-components/Auth/AuthActionButton';
import AuthAlert from '@lunit/scope-components/Auth/AuthAlert';
import useQueryParams from '@lunit/scope-components/hooks/useQueryParams';

interface Alert {
  severity: AlertColor
  message: string
}

interface VerifyEmailQueryParams {
  expired: string
}

function VerifyEmail() {
  const { expired: rawExpired } = useQueryParams<VerifyEmailQueryParams>();
  const isExpired = rawExpired != null;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    // setError,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const alert: Alert | null = useMemo(() => {
    if (successMessage) {
      return {
        severity: 'success',
        message: successMessage,
      };
    }
    if (isExpired) {
      return {
        severity: 'error',
        message: 'The link is expired. Please request again.',
      };
    }
    if (errorMessage) {
      return {
        severity: 'error',
        message: errorMessage,
      };
    }
    return null;
  }, [isExpired, errorMessage, successMessage]);

  const verifyEmail = useMutation(authAPI.verifyEmail, {
    onSuccess: () => {
      setButtonDisabled(true);
      setSuccessMessage(
        'Successfully sent you an email. Please check your email now.',
      );
    },
    onError: (error: APIError) => {
      if (
        error.details.toLowerCase().includes('already exists')
        || error.details.toLowerCase().includes('that email is taken')
      ) {
        setErrorMessage('That email is taken. Try another.');
      } else {
        setErrorMessage(error.details);
      }
    },
  });

  const onSubmit = (data: any) => {
    setErrorMessage('');
    verifyEmail.mutate(data);
  };

  const onSubmitError = () => {
    setErrorMessage('');
    let errorMessageSet = false;
    if (errors.email && !errorMessageSet) {
      setErrorMessage(errors.email.message);
      errorMessageSet = true;
    }
  };

  const validateCaseSensitive = (value:string) => {
    if (value !== value.toLowerCase()) {
      setErrorMessage('The email address should be lowercase');
      setValue('email', value.toLowerCase());
    } else {
      setErrorMessage('');
    }
    return true;
  };

  return (
    <AuthTemplate logo="/logo-scope-r.svg">
      <form
        onSubmit={handleSubmit(onSubmit, onSubmitError)}
        noValidate
      >
        <AuthHeading>Verify your email</AuthHeading>
        <AuthNav sx={{ marginBottom: 3 }}>
          <Link
            href="login"
            sx={{ textAlign: 'right' }}
          >
            Back to sign-in
          </Link>
        </AuthNav>
        <AuthDescription>
          A link will be sent to your email to create your Lunit account.
          <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
            <li>
              The link will remain
              {' '}
              <b>valid for 72 hours</b>
              {' '}
              for security
              reasons.
            </li>
            <li>Please be aware of capital letters.</li>
            <li>
              Please check junk, spam, or other folders, if you can’t find the
              email.
            </li>
          </ul>
        </AuthDescription>
        <AuthTextField
          id="email"
          type="email"
          autoComplete="username"
          aria-label="Business email"
          placeholder="Business email"
          sx={{ marginBottom: 0 }}
          {...register('email', {
            required: 'This field is required.',
            validate: (value) => validateCaseSensitive(value),
          })
          }
          error={!!errors.email}
        />
        <AuthActionButton
          size="large"
          disabled={buttonDisabled}
        >
          Send
        </AuthActionButton>
        {alert && (
          <AuthAlert
            sx={{ marginTop: 3 }}
            severity={alert.severity}
          >
            {alert.message}
          </AuthAlert>
        )}
        <AuthNav>
          <Link
            href="mailto:cs-scope@lunit.io"
            target="_blank"
            sx={{ textAlign: 'right' }}
          >
            cs-scope@lunit.io
          </Link>
        </AuthNav>
      </form>
    </AuthTemplate>
  );
}

export default VerifyEmail;
