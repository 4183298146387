import * as React from 'react'

const SvgArrowDown = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.646 7.646a.5.5 0 0 1 .708 0L10 11.293l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgArrowDown
