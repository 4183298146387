/* eslint-disable no-bitwise */
import { FragmentShader, VertexShader } from './types';

export function createShader(
  gl: WebGL2RenderingContext,
  type: VertexShader | FragmentShader,
  source: string,
): WebGLShader | undefined {
  const shader = gl.createShader(type);
  if (!shader) {
    console.error('Failed to create shader');
    return undefined;
  }
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
  if (success) {
    return shader;
  }
  console.groupCollapsed(
    type === 35633 ? 'Vertex Shader Error' : 'Fragment Shader Error',
  );
  console.warn('Failed to compile');
  console.warn(source);
  console.groupEnd();
  gl.deleteShader(shader);
  return undefined;
}

export function createProgram(
  gl: WebGL2RenderingContext,
  vertexShader: WebGLShader,
  fragmentShader: WebGLShader,
): WebGLProgram | undefined {
  const program = gl.createProgram();
  if (!program) {
    console.error('Failed to create program');
    return undefined;
  }

  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  const success = gl.getProgramParameter(program, gl.LINK_STATUS);
  if (success) {
    return program;
  }
  gl.deleteProgram(program);
  return undefined;
}

export function setProgram(
  gl: WebGL2RenderingContext,
  source: { vertex: string; fragment: string },
): WebGLProgram | undefined {
  const vertexShader = createShader(gl, gl.VERTEX_SHADER, source.vertex);
  const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, source.fragment);
  if (!vertexShader || !fragmentShader) return undefined;
  return createProgram(gl, vertexShader, fragmentShader);
}

export function hexToRgbVector(hexColor: string) {
  const hex = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r: r / 255, g: g / 255, b: b / 255, a: 1.0 };
}

export function clampedPointSize(zoom:number, baseSize:number) {
  const pointSize = Math.floor(baseSize + (zoom / 20));
  if (pointSize > 12) return 12;
  if (pointSize < 4) return 4;
  return pointSize;
}
