import useSlideFromPath from 'src/hooks/useSlideFromPath';
import { usePDL1TPSResultData } from 'src/workers/hooks';

import { ProjectPageProps } from 'src/types';

import ProjectPage from 'src/components/common/ProjectPage';
import PDL1TPSAnalysisSummary from './PDL1TPSAnalysisSummary';
import PDL1TPSSlideViewer from './PDL1TPSSlideViewer';

function PDL1TPS({ metadata, projectConfig }: ProjectPageProps) {
  const { currentSlide } = useSlideFromPath(metadata.results);
  const { data, isLoading } = usePDL1TPSResultData(
    currentSlide?.resultPath,
    metadata.uiComponents,
  );
  if (!currentSlide) return null;

  const viewer = (
    <PDL1TPSSlideViewer
      slideData={{
        slideDziUrl: currentSlide.slideDziUrl,
        slideDziMetaUrl: currentSlide.slideDziMetaUrl,
      }}
      resultData={data}
    />
  );
  const analysisSummaryPanel = (
    <PDL1TPSAnalysisSummary
      uiComponents={metadata.uiComponents}
      summary={data?.analysisSummary}
    />
  );

  return (
    <ProjectPage
      viewer={viewer}
      analysisSummaryPanel={analysisSummaryPanel}
      isLoadingResultData={isLoading}
      metadata={metadata}
      projectConfig={projectConfig}
    />
  );
}

export default PDL1TPS;
