import { map, reverse } from 'lodash';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import { SectionContent, Typography } from '../styled';
import { convertMinAvgMax, findSummaryItemByTitle, formatUnit, getNumberFromString, roundToFirstDecimal } from './func';
import { CenteredBox, DenseSectionListItem } from './styled';

interface Props {
  cellTitles:string[]
  summary:AnalysisData[] | undefined
}

function AverageBreakdownTable({ cellTitles, summary }:Props) {
  return (
    <>
      {map(cellTitles, (title) => {
        const summaryItem = findSummaryItemByTitle(summary, title);
        const subItems = reverse(Array.from(summaryItem?.items || []));
        return (
          summaryItem && (
          <SectionContent
            key={`${summaryItem.name}`}
            sx={{ padding: '6px 20px' }}
          >
            <DenseSectionListItem>
              <Typography variant="body5">{summaryItem.name}</Typography>
            </DenseSectionListItem>
            <>
              {!!summaryItem.items
                  && map(subItems, (item) => (
                    <DenseSectionListItem key={`${item.name}`}>
                      <Typography
                        color="darkGrey.20"
                        variant="body5"
                      >
                        {convertMinAvgMax(item.name!)}
                      </Typography>
                      <CenteredBox>
                        <Typography variant="body4">
                          {`${roundToFirstDecimal(getNumberFromString(item.value))}${
                            summaryItem.unit && summaryItem.unit !== 'Integer'
                              ? `/${formatUnit(summaryItem.unit)}`
                              : ''
                          }`}
                        </Typography>
                      </CenteredBox>
                    </DenseSectionListItem>
                  ))}
            </>
          </SectionContent>
          )
        );
      })}
    </>

  );
}
export default AverageBreakdownTable;
