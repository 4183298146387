import * as React from 'react'

const SvgMenuOpen = props => (
  <svg
    width={20}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4805 0.00415039V23.0042H0V14.4609L2.9769 8.28318L5.23029 12.9653L7.48368 8.28318L9.74026 12.9653L11.9937 8.28318L14.2502 12.9653V0.00415039H19.4805Z" fill="#000"/>
  </svg>
)

export default SvgMenuOpen
