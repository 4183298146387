import { Alert as MuiAlert, AlertProps as MuiAlertProps } from '@mui/material';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { AuthPropKeys } from './types';

interface AuthAlertProps extends Pick<MuiAlertProps, AuthPropKeys> {
  severity?: MuiAlertProps['severity']
}

const AuthAlert = forwardRef<HTMLDivElement, AuthAlertProps>((props, ref) => (
  <MuiAlert
    ref={ref}
    {...props}
    className={clsx('Alert', props.className)}
  />
));
AuthAlert.displayName = 'AuthAlert';

export default AuthAlert;
