import ProjectViewer from 'src/components/common/ProjectPage/ProjectViewer';
import useTumorPurityVisualization from 'src/hooks/visualization/useTumorPurityVisualization';
import { ResultWorkerPreparedEventData } from 'src/workers/AnalysisResultWorker/types';
import { SlideUrlData } from '../../../components/common/SlideViewer/types';

interface TumorPurityViewerProps {
  slideData: SlideUrlData
  resultData?: ResultWorkerPreparedEventData
}

function TumorPurityViewer({
  slideData,
  resultData,
}: TumorPurityViewerProps) {
  const visualization = useTumorPurityVisualization(
    resultData,
  );

  return (
    <ProjectViewer
      defaultZoom={2}
      slideData={slideData}
      resultData={resultData}
      visualization={visualization}
    />
  );
}

export default TumorPurityViewer;
