import { AuthTemplate } from '@lunit/scope-components';
import AuthDescription from '@lunit/scope-components/Auth/AuthDescription';
import AuthHeading from '@lunit/scope-components/Auth/AuthHeading';
import AuthNav from '@lunit/scope-components/Auth/AuthNav';
import { Link, Typography } from '@mui/material';
import { useSignupInfoQuery } from 'src/hooks/useAuthQueries';
import { getTermsBySeq, getTermsDescription, termsWithNewLine } from './utils';

function TermsOfUse() {
  const { data: signupInfo } = useSignupInfoQuery();

  const targetTerms = getTermsBySeq(signupInfo?.serviceTerm, 1);
  const termsOfUse = termsWithNewLine(getTermsDescription(targetTerms));

  return (
    <AuthTemplate
      logo="/logo-scope-r.svg"
      wide
      headerFixed={false}
    >
      <AuthHeading>Terms Of Use</AuthHeading>
      <AuthNav>
        <Link
          href="/login"
          sx={{ textAlign: 'right' }}
        >
          Back to sign-in
        </Link>
      </AuthNav>
      <AuthDescription sx={{ marginTop: 5 }}>
        <Typography variant="body5">{termsOfUse}</Typography>
      </AuthDescription>
      <AuthNav>
        <Link
          href="mailto:cs-scope@lunit.io"
          target="_blank"
          sx={{ textAlign: 'right' }}
        >
          cs-scope@lunit.io
        </Link>
      </AuthNav>
    </AuthTemplate>
  );
}

export default TermsOfUse;
