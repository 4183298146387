import { ChartTitle, LineChartContainer } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/Charts';
import { Bar, BarLabel, ScoreDot, ScoreText } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/styled';

import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';

import getScorePosition, { ScoreAxis } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/Charts/getScorePosition';

interface Props {
  summaryItem:AnalysisData
}

const scoreAxis: ScoreAxis = [
  [0, 0],
  [100, 1],
];

function TumorPurityChart({ summaryItem }:Props) {
  const score = Math.floor(Number(summaryItem?.value ?? '0'));
  const [scorePosition, scoreTable] = getScorePosition(scoreAxis, score);

  const title = (
    <ChartTitle label="Tumor purity">
      {score}
      %
    </ChartTitle>
  );

  const bars = [
    <Bar sx={{ width: scoreTable['100'].size, backgroundColor: '#EE5140' }} />,
  ];

  const barLabels = scoreAxis.map(
    ([coord, position]) => <BarLabel sx={{ left: `${position * 100}%` }}>{coord}</BarLabel>,
  );

  const scoreIndicator = (
    <ScoreDot
      sx={{ left: `${scorePosition * 100}%` }}
      data-value={score}
    />
  );
  const scoreLabel = (
    <ScoreText
      sx={{ left: `${scorePosition * 100}%` }}
      data-value={score}
    >
      {score}
    </ScoreText>
  );

  return (
    <LineChartContainer
      title={title}
      bars={bars}
      barLabels={barLabels}
      scoreIndicator={scoreIndicator}
      scoreLabel={scoreLabel}
    />
  );
}
export default TumorPurityChart;
