import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material';

export const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
  ':before': { backgroundColor: 'transparent' },
}));

export const StyledAccordionSummary = styled(AccordionSummary)({
  minHeight: '36px',
  padding: '0 16px 0 20px',
  '& .MuiAccordionSummary-content': {
    marginTop: 8,
    marginBottom: 8,
  },
});

export const StyledAccordionDetail = styled(AccordionDetails)({ padding: 0 });
