import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useMatch, useNavigate } from 'react-router-dom';

import { APIError, ErrorMessage } from 'src/api/utils/apiRequestHelper';
import * as biomarkerQueries from '../api/biomarkers/queries';
import useSnackbar from './useSnackbar';

const METADATA_STALE_TILE = 3600 * 1000;

const useBiomarkers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const match = useMatch('/');

  const data = useQuery(
    biomarkerQueries.getBiomarkers.key(),
    () => biomarkerQueries.getBiomarkers(),
    {
      suspense: false,
      cacheTime: METADATA_STALE_TILE,
      staleTime: METADATA_STALE_TILE,
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      onError: (error: APIError) => {
        if (
          error.message === ErrorMessage.FetchError || error.code === 0
        ) {
          enqueueSnackbar('Connection unavailable', {
            variant: 'error',
            preventDuplicate: true,
            persist: true,
          });
        } else {
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
        if (!match || match.pathname !== '/') {
          navigate('/');
        }
      },
    },
  );
  return data;
};

function useBiomarkerMetaData(projectPath: string) {
  const [refetchOnWindowFocus, setRefetchOnWindowFocus] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const match = useMatch('/');
  const data = useQuery(
    biomarkerQueries.getSlideData.key(projectPath),
    () => biomarkerQueries.getSlideData(projectPath),
    {
      suspense: false,
      useErrorBoundary: false,
      cacheTime: METADATA_STALE_TILE,
      staleTime: METADATA_STALE_TILE,
      enabled: !!projectPath,
      refetchOnWindowFocus,
      onError: (error: APIError) => {
        if (
          error.message === ErrorMessage.FetchError || error.code === 0
        ) {
          enqueueSnackbar('Connection unavailable', {
            variant: 'error',
            preventDuplicate: true,
            persist: true,
          });
        } else {
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
        if (!match || match.pathname !== '/') {
          navigate('/');
        }
      },
    },
  );
  useEffect(() => {
    if (data.isError) {
      setRefetchOnWindowFocus(true);
    } else {
      setRefetchOnWindowFocus(false);
    }
  }, [data.isError]);
  return data;
}

function useSlideMsgpackPresignedUrl(msgpackPath: string) {
  const data = useQuery(
    biomarkerQueries.getSlideMsgpackPresignedUrl.key(msgpackPath),
    () => biomarkerQueries.getSlideMsgpackPresignedUrl(msgpackPath),
    { suspense: false, refetchOnWindowFocus: false },
  );
  return data;
}

export { useBiomarkers, useBiomarkerMetaData, useSlideMsgpackPresignedUrl };
