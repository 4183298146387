import { Typography } from '@mui/material';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';

const CUTOFF = 20;

interface Props {
  summaryItem:AnalysisData | undefined
}

function PositiveNegativeTitle({ summaryItem }:Props) {
  return (
    <>
      <Typography variant="body2">Score</Typography>
      <Typography
        sx={{
          marginLeft: 1,
          color: Number(summaryItem?.value) >= CUTOFF
            ? '#EE5140'
            : '#7292FD',
          whiteSpace: 'pre-wrap',
        }}
        variant="subtitle3"
      >
        {`${
          Number(summaryItem?.value) >= CUTOFF ? 'Positive' : 'Negative'
        } `}
      </Typography>
      <Typography variant="subtitle3">
        {summaryItem ? Number(summaryItem?.value) : '...'}
        %
      </Typography>
    </>
  );
}
export default PositiveNegativeTitle;
