import { AnalysisAccordion, AverageBreakdownTable } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';

const TIL_DENSITY_TITLES = ['Intratumoral TIL Density', 'Stromal TIL Density'];

interface Props {
  summary: AnalysisData[] | undefined

}

function IODensitySummary({ summary }:Props) {
  return (
    <AnalysisAccordion title="Tumor Infiltrating Lymphocyte Density">
      <AverageBreakdownTable
        cellTitles={TIL_DENSITY_TITLES}
        summary={summary}
      />
    </AnalysisAccordion>
  );
}
export default IODensitySummary;
