import {
  ControllerProps,
  DropdownControllerProps,
  ProjectConfigSpec,
} from 'src/types';

export const pdl1TPSConfig: ProjectConfigSpec = {
  projectName: 'pdl1-tps',
  titleHasTooltip: true,
  hasSummarySelector: false,
  elementConfig: [
    {
      name: 'Cell Detection',
      elementType: 'label',
      childOptions: 'hide',
      elementInTitleTooltipConfig: { caption: 'Cells show when over x10.' },
    },
    {
      name: 'TPS',
      elementType: 'drop-down',
      childOptions: 'hide',
    },
    {
      name: 'Live Local',
      elementType: 'label',
      childOptions: 'hide',
      elementInTitleTooltipConfig: { content: 'Local TPS shows where mouse hover. Shown from zoom controller x10.' },
    },
  ],
};

export type PDL1TPSControllerOrder = [
  ControllerProps,
  DropdownControllerProps,
  ControllerProps,
];
