import type { ComponentsOverrides } from '@mui/material/styles'

const styleOverrides: ComponentsOverrides['MuiModal'] = {
  root: {
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'auto',
    },
  },
}
export default {
  styleOverrides,
}
