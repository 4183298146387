import { Bar, BarLabel, ScoreDot, ScoreText } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/styled';
import { ChartTitle, LineChartContainer } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/Charts';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import getScorePosition, { ScoreAxis } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/Charts/getScorePosition';

interface Props {
  summaryItem:AnalysisData
}

const scoreAxis: ScoreAxis = [
  [0, 0],
  [1, 0.1],
  [50, 0.5],
  [100, 1],
];

function PDL1TPSChart({ summaryItem }: Props) {
  const score = Math.floor(Number(summaryItem?.value ?? '0'));
  const [scorePosition, scoreTable] = getScorePosition(scoreAxis, score);

  const title = (
    <ChartTitle label="TPS">
      {score}
      {summaryItem?.unit}
    </ChartTitle>
  );

  const bars = [
    <Bar sx={{ width: scoreTable['1'].size, backgroundColor: '#5091ff' }} />,
    <Bar sx={{ width: scoreTable['50'].size, backgroundColor: '#0CA678' }} />,
    <Bar sx={{ width: scoreTable['100'].size, backgroundColor: '#F76707' }} />,
  ];

  const barLabels = scoreAxis.map(
    ([coord, position]) => <BarLabel sx={{ left: `${position * 100}%` }}>{coord}</BarLabel>,
  );

  const scoreIndicator = (
    <ScoreDot
      sx={{ left: `${scorePosition * 100}%` }}
      data-value={score}
    />
  );
  const scoreLabel = (
    <ScoreText
      sx={{ left: `${scorePosition * 100}%` }}
      data-value={score}
    >
      {score}
    </ScoreText>
  );

  return (
    <LineChartContainer
      title={title}
      bars={bars}
      barLabels={barLabels}
      scoreIndicator={scoreIndicator}
      scoreLabel={scoreLabel}
    />
  );
}
export default PDL1TPSChart;
