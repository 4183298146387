import type { ComponentsOverrides } from '@mui/material/styles'
import { SCROLLBAR_WIDTH } from '../system'
import typographyOptions from '../system/typography'
import palette from '../colors'

const heading = {
  h1: { fontWeight: 500 },
  h2: { fontWeight: 500 },
  h3: { fontWeight: 500 },
  h4: { fontWeight: 500 },
  h5: { fontWeight: 500 },
  h6: { fontWeight: 500 },
}

const html = {
  position: 'fixed',
  overflow: 'hidden',
  fontFamily: `Pretendard, sans-serif`,
}

const body = {
  ...html,
  width: '100%',
  height: '100%',
  margin: 0,
  padding: 0,
  lineHeight: 'normal',
  backgroundColor: palette.background.primary,
  '& a': {
    textDecoration: 'none',
  },
}

const styleOverrides: ComponentsOverrides['MuiCssBaseline'] = {
  html,
  body,
  ...heading,
  '.App': body,
  '#root': body,
  '*::-webkit-scrollbar': {
    width: SCROLLBAR_WIDTH,
    height: SCROLLBAR_WIDTH,
  },
  '*::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: palette.darkGrey[40],
    borderRadius: 3.5,
  },
  'svg.MuiCircularProgress-svg *': {
    fill: 'none',
  },
  'svg *.stroke-inherit': {
    fill: 'none',
    stroke: 'currentColor',
  },
  '.snackbarVariantError': {
    background: 'rgba(238, 81, 64, 0.18)',
    border: '1px solid #EE5140',
    ...typographyOptions.body5,
  },
  // TODO: delete span style
  span: {
    display: 'inline-block',
  },
  '#page-root.drawer-open + .SnackbarContainer-left': {
    left: 260,
  },
}
export default {
  styleOverrides,
}
