import { UIComponents } from 'src/api/biomarkers/models';
import { TotalBreakdownTable } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements';
import AnalysisSummaryPanel from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/AnalysisSummaryPanel';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import CPSChart from './CPSChart';

interface CPSAnalysisSummaryProps {
  uiComponents: UIComponents | undefined
  summary: AnalysisData[] | undefined
}

function CPSAnalysisSummary({
  uiComponents,
  summary,
}: CPSAnalysisSummaryProps) {
  return (
    <AnalysisSummaryPanel>
      {summary && (
        <>
          <CPSChart summaryItem={summary[0]} />
          <TotalBreakdownTable
            summaryItem={summary[1]}
            uiComponents={uiComponents}
          />
        </>
      )}
    </AnalysisSummaryPanel>
  );
}

export default CPSAnalysisSummary;
