/* ---------- React ----------*/
import clsx from 'clsx';
import { Card, IconButton, MenuItem, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { MoreIcon } from '@lunit/scope-icons';

/* ---------- Components ----------*/
import EllipsizedTypography from '../../EllipsizedTypography';
import ContextMenu from '../../ContextMenu';
import { DATE_FORMAT } from '../../utils/format';

/* ---------- APIs ----------*/
import { Project } from '../../api/projects/models';

/* ---------- Hooks ----------*/
import usePopper from '../../hooks/usePopper';

interface IProjectCardProps {
  project: Project
  isAnalyzer: boolean
  handleEditProject: (project: Project) => void
  handleDeleteProject: (project: Project) => void
  generateProjectLink: (project: Project) => string
}

const CardHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flex: '0 0',
});

const CardFooter = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flex: '0 0',
  marginTop: 'auto',
});

const MoreIconComponent = styled(MoreIcon)({
  padding: 0,
  opacity: 0,
});

function ProjectCard({
  project,
  isAnalyzer,
  handleEditProject,
  handleDeleteProject,
  generateProjectLink,
}: IProjectCardProps) {
  const menuPopper = usePopper();

  const projectLink = generateProjectLink
    ? generateProjectLink(project)
    : `/projects/${project.id}/slides`;

  return (
    <>
      <Link to={projectLink}>
        <Card
          className={clsx(menuPopper.open ? 'Mui-focused' : undefined)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            backgroundColor: (theme) => theme.palette.background.secondary,
            border: '1px solid transparent',
            borderRadius: 2,
            height: 260,
            padding: 3,
            cursor: 'pointer',
            '&:hover, &.Mui-focused': {
              backgroundColor: (theme) => theme.palette.background.selected,
              '& .MoreIcon': { opacity: 1 },
            },
          }}
          variant="outlined"
        >
          <CardHeader>
            <EllipsizedTypography
              sx={{
                maxHeight: 90,
                WebkitLineClamp: 3,
                wordBreak: 'break-all',
                height: '36px',
              }}
              direction="column"
              variant="subtitle1"
            >
              {project.title}
            </EllipsizedTypography>
            {!isAnalyzer && (
              <IconButton
                aria-label="Ellipsis Button"
                aria-expanded={menuPopper.open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  menuPopper.onOpen(e);
                }}
                sx={{
                  '&:hover, &[aria-expanded*="true"]': { backgroundColor: (theme) => theme.palette.darkGrey[70] },
                  color: '#fff',
                }}
              >
                <MoreIconComponent className="MoreIcon" />
              </IconButton>
            )}
          </CardHeader>
          <EllipsizedTypography
            sx={{
              flex: '0 1 auto',
              marginTop: 2,
              color: (theme) => theme.palette.darkGrey[40],
              WebkitLineClamp: 3,
              wordBreak: 'break-all',
            }}
            direction="column"
            variant="body5"
          >
            {project.description}
          </EllipsizedTypography>
          <CardFooter>
            <Tooltip title="Date Range">
              <Typography variant="body6">
                {project.fromDate
                  && format(new Date(project.fromDate), DATE_FORMAT)}
                &nbsp;
                {project.toDate
                  && `- ${format(new Date(project.toDate), DATE_FORMAT)}`}
              </Typography>
            </Tooltip>
            <Tooltip title="No. of Slides">
              <Typography variant="body6">{project.slideCount}</Typography>
            </Tooltip>
          </CardFooter>
        </Card>
      </Link>
      <ContextMenu
        anchorEl={menuPopper.anchorEl}
        open={menuPopper.open}
        onClose={menuPopper.onClose}
      >
        <MenuItem
          onClick={() => {
            handleEditProject(project);
            menuPopper.onClose();
          }}
        >
          Edit Project
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteProject(project);
            menuPopper.onClose();
          }}
        >
          Delete Project
        </MenuItem>
      </ContextMenu>
    </>
  );
}

export default ProjectCard;
