import { UIComponents } from 'src/api/biomarkers/models';
import {
  AnalysisData,
  CoordData,
  GridData,
  MaskImage,
  SlideImageProperties,
} from './common/types';

export type ResultWorkerPreparedEventData = {
  type: 'prepared'
  maskImages?: MaskImage[]
  maskImageIndex?: { [key: string]: number }
  cellData?: CoordData[]
  gridData?: GridData[]
  slideProperties?: SlideImageProperties
  analysisSummary?: AnalysisData[]
};

export type ResultWorkerErrorEventData = {
  type: 'error'
  message: string
};

export type ResultWorkerEventData =
  | ResultWorkerPreparedEventData
  | ResultWorkerErrorEventData;

export const isPreparedEventData = (
  data: ResultWorkerEventData,
): data is ResultWorkerPreparedEventData => data.type === 'prepared';

export const isErrorEventData = (
  data: ResultWorkerEventData,
): data is ResultWorkerErrorEventData => data.type === 'error';

export type PrepareActionEventData = {
  action: 'prepare'
  msgpackUrl: string
  uiComponents: UIComponents
  offscreen: OffscreenCanvas
};
