import { useState } from 'react';

import ViewOptions from '@lunit/scope-components/Analysis/SlidePanel/ViewOptions';
import { isDropdownElement, UIComponents } from 'src/api/biomarkers/models';

import { ShownIcon, HiddenIcon } from '@lunit/scope-icons';

import { useProjectState } from 'src/hooks/projectConfig';

import { ConfiguredProject, InfoTooltipConfig } from 'src/types';
import SingleOption from './OptionElements/SingleOption';
import ViewOptionsTooltip from './ViewOptionsTooltip';
import { TooltipContainer } from './styled';
import TooltipOption from './TooltipElements';

interface ViewOptionsSubPanelProps {
  uiComponents: UIComponents
  config: ConfiguredProject
  infoTooltipConfig?:InfoTooltipConfig[]
}

function ViewOptionsSubPanel({
  uiComponents,
  config,
  infoTooltipConfig,
}: ViewOptionsSubPanelProps) {
  const controller = useProjectState();

  const [subToggleIconShown, setToggleIconShown] = useState(false);

  const { elements } = uiComponents;
  const { elementConfig } = config;
  const { on, visible, toggle, controllers } = controller;

  const handleMouseEnter = () => {
    if (config.utilizeHoverSubShowHide) {
      setToggleIconShown(true);
    }
  };

  const handleMouseLeave = () => {
    if (config.utilizeHoverSubShowHide) {
      setToggleIconShown(false);
    }
  };

  return (
    <ViewOptions
      containerSx={{ flex: '0 1 140px' }}
      infoIcon={config.titleHasTooltip}
      headerSx={{ opacity: visible ? 1 : 0.5 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      title={config.titleHasTooltip && (
      <ViewOptionsTooltip
        uiElements={elements}
        config={config}
      />
      )}
      viewIcon={on ? <ShownIcon /> : <HiddenIcon />}
      handleOptionClick={toggle}
    >
      {controllers.length > 0
        && elements.map((element, index) => {
          const configForElement = elementConfig[index];

          let infoTooltip;
          if (configForElement.individualInfoTooltip === 'generated' && !isDropdownElement(element)) {
            infoTooltip = (
              <TooltipContainer>
                <TooltipOption element={element} />
              </TooltipContainer>
            );
          }
          if (configForElement.individualInfoTooltip === 'passed-in' && infoTooltipConfig) {
            infoTooltip = infoTooltipConfig?.find(
              (conf) => conf.name === configForElement.name,
            )?.element;
          }
          return (
            <SingleOption
              key={configForElement.name}
              uiElement={element}
              config={configForElement}
              infoTooltip={infoTooltip}
              showToggleButton={
                config.utilizeHoverSubShowHide ? subToggleIconShown : false
              }
              elementController={controllers[index]}
            />
          );
        })}
    </ViewOptions>
  );
}
export default ViewOptionsSubPanel;
