/* ---------- React ----------*/
import { useCallback, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ImageList, ImageListItem, Typography, styled } from '@mui/material';
// import { useSnackbar } from 'notistack'

/* ---------- Components ----------*/
import ProjectCard from './components/ProjectCard';
import ProjectTable from './components/ProjectTable';
import { FormMode } from '../Form/Template';
import { DialogContext } from '../Dialog/context';
import { subScrollbarWidth } from '../utils/styles';

/* ---------- APIs ----------*/
import { createProjectFormValues, Project } from '../api/projects/models';
import * as projectAPI from '../api/projects/queries';

const TableContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: `0 ${subScrollbarWidth(theme.spacing(5))} 0 ${theme.spacing(5)}`,
  overflowX: 'auto',
}));

interface ProjectContentProps {
  viewMode: any
  isAnalyzer: boolean
  selectedPage?: number
  selectedPerPage?: number
  projectPerPage: Array<any>
  breakpointIndex: number
  ProjectViewMode: any
  dispatchProjectForm?: any
  projectFormActions?: any
  generateProjectLink?: (project: any) => string
}

function ProjectContent({
  viewMode,
  isAnalyzer,
  selectedPage,
  selectedPerPage,
  projectPerPage,
  breakpointIndex,
  ProjectViewMode,
  dispatchProjectForm,
  projectFormActions,
  generateProjectLink = null,
}: ProjectContentProps) {
  const queryClient = useQueryClient();
  const { handleDialog: openDialog } = useContext(DialogContext);

  // const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const deleteProjects = useMutation(projectAPI.deleteProjects, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        projectAPI.getProjectPerPage.key({
          page: selectedPerPage,
          size: selectedPage,
        }),
      );
      // need to add noti snackbar @ sh.bae
      // enqueueSnackbar({
      //   message: 'Successfully deleted a project',
      //   options: {
      //     variant: 'success',
      //   },
      // })
    },
    onError: () => {
      // enqueueSnackbar({
      //   message: 'Failed to delete a project',
      //   options: {
      //     variant: 'error',
      //   },
      // })
    },
    onSettled: () => openDialog(null),
  });

  const handleEditProject = useCallback(
    (project: Project) => {
      dispatchProjectForm(
        projectFormActions.openForm(
          FormMode.Edit,
          createProjectFormValues(project),
        ),
      );
    },
    [dispatchProjectForm, projectFormActions],
  );

  const handleDeleteProject = useCallback(
    (project: Project) => openDialog({
      width: 320,
      content: (
        <>
          <Typography variant="subtitle3">Delete Project</Typography>
          <Typography variant="body5">
            Are you sure you want to delete the project? This action cannot be
            undone.
            <br />
            (Even if the project is deleted, the slides files won’t be
            deleted.)
          </Typography>
        </>
      ),
      handleAgreementCallback: () => {
        deleteProjects.mutate({ ids: [project.id] });
      },
      disableBackdropClick: true,
      loading: false,
    }),
    [openDialog, deleteProjects],
  );

  switch (viewMode) {
    case ProjectViewMode.Grid:
      return (
        <ImageList
          rowHeight={260}
          gap={20}
          sx={{
            flex: 1,
            width: '100%',
            margin: 0,
            paddingBottom: '10px',
            justifyContent: 'center',
          }}
          cols={breakpointIndex}
          style={{
            gridTemplateColumns: 'repeat(auto-fill, 520px)',
            gridAutoRows: 'min-content',
          }}
        >
          {projectPerPage.map((project) => (
            <ImageListItem
              cols={1}
              rows={1}
              key={project.id}
            >
              <ProjectCard
                project={project}
                isAnalyzer={isAnalyzer}
                handleEditProject={handleEditProject}
                handleDeleteProject={handleDeleteProject}
                generateProjectLink={generateProjectLink}
              />
            </ImageListItem>
          ))}
        </ImageList>
      );
    case ProjectViewMode.List:
      return (
        <TableContainer>
          <ProjectTable
            projects={projectPerPage}
            isAnalyzer={isAnalyzer}
            handleEditProject={handleEditProject}
            handleDeleteProject={handleDeleteProject}
            generateProjectLink={generateProjectLink}
          />
        </TableContainer>
      );
    default:
      return <span />;
  }
}

export default ProjectContent;
