import { createAPIQuery } from '../utils/apiQueryHelper';
import { FetchMethod, queryString, request } from '../utils/apiRequestHelper';
import { Paginate } from '../utils/commonModels';
import {
  APICreateProjectParams,
  APIDeleteProjectsParams,
  APIGetProjectsPerPageParams,
  APIProjectBulkMappingDeleteParams,
  APIUpdateProjectParams,
  ProjectDtoOutput,
  ProjectUUID,
} from './models';

export const getAllProjects = createAPIQuery(
  () => request<ProjectDtoOutput[]>({
    method: FetchMethod.Get,
    path: 'projects',
  }),
  () => ['projects', 'getAllProjects'],
);

export const getProjectPerPage = createAPIQuery(
  (payload: APIGetProjectsPerPageParams) => request<Paginate<ProjectDtoOutput>>({
    method: FetchMethod.Get,
    path: `projects/pages?${queryString(payload)}`,
  }),
  (payload: APIGetProjectsPerPageParams) => [
    'projects',
    'getProjectPerPage',
    payload,
  ],
);

export const createProject = (payload: APICreateProjectParams) => request<ProjectDtoOutput>({
  method: FetchMethod.Post,
  path: 'projects',
  payload,
});

export const deleteProjects = (payload: APIDeleteProjectsParams) => request({
  method: FetchMethod.Delete,
  path: 'projects',
  payload: payload.ids,
  skipJson: true,
});

export const updateProject = (payload: APIUpdateProjectParams) => {
  const { id, ...body } = payload;
  return request<ProjectDtoOutput>({
    method: FetchMethod.Put,
    path: `projects/${id}`,
    payload: body,
  });
};

export const enterProject = (payload: ProjectUUID) => request({
  method: FetchMethod.Post,
  path: `projects/${payload}/enter`,
  skipJson: true,
});

export const deleteProjectMappings = (
  payload: APIProjectBulkMappingDeleteParams,
) => request({
  method: FetchMethod.Delete,
  path: `projects/${payload.id}/mapping/slide`,
  payload: payload.targets,
  skipJson: true,
});
