import type { ComponentsOverrides } from '@mui/material/styles'
import palette from '../colors'

const styleOverrides: ComponentsOverrides['MuiListItemButton'] = {
  root: {
    '&:hover': {
      backgroundColor: palette.darkGrey[80],
    },
    padding: '6px 8px',
    borderRadius: 8,
    color: palette.darkGrey[30],
    '&.bgActive': {
      color: palette.darkGrey[0],
      backgroundColor: palette.darkGrey[80],
    },
    '&.colorActive': {
      color: palette.brand.primary,
    },
  },
}
export default {
  styleOverrides,
}
