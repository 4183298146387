import * as React from 'react'

const SvgShown = props => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 14.957a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-1a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.884 11.69a.757.757 0 0 0 .015-.574C18.44 9.877 16.264 5.108 10 5.108c-6.264 0-8.44 4.77-8.9 6.008a.757.757 0 0 0 .016.575c.49 1.112 2.686 5.114 8.884 5.114s8.393-4.002 8.884-5.114Zm-.955-.313c-.48-1.24-2.453-5.269-7.929-5.269-5.476 0-7.449 4.029-7.929 5.269.485 1.046 2.456 4.428 7.929 4.428 5.473 0 7.444-3.382 7.929-4.428Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgShown
