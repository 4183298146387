import { useState } from 'react';
// import { useMatch } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import HeaderButton from '@lunit/scope-components/HeaderButton';
import { LabelIcon } from '@lunit/scope-icons';
import { LabelDialog } from '@lunit/scope-components';
// import { ProductType } from 'src/types';
import LabelContent from './LabelContent';

// const productList: ProductType = {
//   io: 'IO',
//   her2: 'HER2',
//   pdl1: 'PD-L1',
//   'tumor-purity': 'Tumor Purity',
// };

// const getProuductNameByPath = (path: string | undefined) => (path && productList[path]) || '';

function ProductLabelButton() {
  // const match = useMatch('/:productPath/*');
  const [labelOpen, setLabelOpen] = useState<boolean>(false);

  const handleLabelDialog = () => {
    setLabelOpen(!labelOpen);
  };

  return (
    <Tooltip title={!labelOpen ? 'Label' : ''}>
      <span>
        <HeaderButton onClick={handleLabelDialog}>
          <LabelIcon />
        </HeaderButton>
        <LabelDialog
          open={labelOpen}
          handleClose={handleLabelDialog}
          // eslint-disable-next-line react/no-children-prop
          children={(
            <LabelContent />
          )}
        />
      </span>
    </Tooltip>
  );
}

export default ProductLabelButton;
