import { CanvasOverlayProps } from '@lunit/osd-react-renderer';
import { forEach } from 'lodash';
import { useCallback } from 'react';
import { ControllerOrder } from 'src/const/projectConfigSpecs';
import { ResultWorkerPreparedEventData } from 'src/workers/AnalysisResultWorker/types';
import { useProjectState } from '../projectConfig';
import useViewerZoom from '../useViewerZoom';
import { drawGridTiles, drawTissueImage } from './func/drawing';
import { getZoomFromViewportZoom } from './func/utils';
import { Origin } from './webgl/types';
import useWebGLCellDrawing from './webgl/useWebGLCellDrawing';

const useCPSBreastVisualization = (
  resultData: ResultWorkerPreparedEventData | undefined,
) => {
  const viewOptions = useProjectState();
  const { handleCellDrawing } = useWebGLCellDrawing();
  const {
    zoomState: { zoom },
    physicalWidthPx,
  } = useViewerZoom();

  const [cpsMap, tissueSegmentation, cellDetection] = viewOptions.controllers as ControllerOrder['cps-breast'];

  // In CPS-Breast, used for Cell Detection
  const onWebGLOverlayRedraw = useCallback((
    glCanvas: HTMLCanvasElement,
    normalCanvas: HTMLCanvasElement,
    viewer: OpenSeadragon.Viewer,
    origin:Origin,
  ) => {
    if (!resultData) return;
    if (!viewOptions.visible) return;
    if (!cellDetection) return;

    const microscopeWidth1x = physicalWidthPx * 10;
    const currentZoom = getZoomFromViewportZoom(
      viewer.viewport.getZoom(true),
      microscopeWidth1x,
      viewer,
    );

    const gl = glCanvas.getContext('webgl2', {
      antialias: true,
      premultipliedAlpha: false,
    });
    const ctx = normalCanvas.getContext('2d');

    if (!gl) {
      console.log('failed to load webgl context');
      return;
    }
    if (!ctx) {
      console.log('failed to load 2d context');
      return;
    }

    if (
      cellDetection.visible
      && zoom >= 20
      && Math.abs(currentZoom - zoom) < 0.1
      && resultData.cellData
    ) {
      forEach(cellDetection.childController, (cell, index) => {
        if (cell.visible && resultData.cellData && resultData.cellData[index]) {
          handleCellDrawing(gl, ctx, viewer, resultData.cellData[index], zoom, origin);
        }
      });
    }
  }, [cellDetection, handleCellDrawing, physicalWidthPx, resultData, viewOptions.visible, zoom]);

  // In CPS-Breast, used for Tissue Segmentation and CPS Grid
  const onCanvasOverlayRedraw: CanvasOverlayProps['onRedraw'] = useCallback(
    (overlayEl: HTMLCanvasElement, viewer: OpenSeadragon.Viewer) => {
      if (!resultData) return;
      if (!viewOptions.visible) return;
      if (!cpsMap || !tissueSegmentation || !cellDetection) return;

      const context = overlayEl.getContext('2d');
      if (!context) return;

      const [
        cancerArea, carcinomaInSitu,
      ] = tissueSegmentation.childController;

      if (tissueSegmentation.visible) {
        if (
          cancerArea.visible
          && resultData.maskImages
          && resultData.maskImageIndex
          && resultData.maskImages[resultData.maskImageIndex['Cancer Area']]
        ) {
          drawTissueImage(
            context,
            0.3,
            resultData.maskImages[resultData.maskImageIndex['Cancer Area']],
          );
        }
        if (
          carcinomaInSitu.visible
          && resultData.maskImages
          && resultData.maskImageIndex
          && resultData.maskImages[resultData.maskImageIndex['Carcinoma In Situ']]
        ) {
          drawTissueImage(
            context,
            0.3,
            resultData.maskImages[resultData.maskImageIndex['Carcinoma In Situ']],
          );
        }
      }
      if (cpsMap.visible && resultData.gridData && zoom < 20) {
        drawGridTiles(context, viewer, resultData.gridData);
      }
    },
    [resultData, zoom, viewOptions.visible, cpsMap, tissueSegmentation, cellDetection],
  );

  return { onCanvasOverlayRedraw, onWebGLOverlayRedraw };
};

export default useCPSBreastVisualization;
