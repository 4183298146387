import type { ComponentsOverrides } from '@mui/material/styles'

const styleOverrides: ComponentsOverrides['MuiDialogTitle'] = {
  root: {
    padding: '20px 32px',
    position: 'relative',
    borderRadius: '10px 10px 0px 0px',
  },
}
export default {
  styleOverrides,
}
