import { Box, Tooltip } from '@lunit/scope-components/Analysis/ViewerToolbar/ToolbarMUI';
import { InfoIcon } from '@lunit/scope-icons';
import { TooltipProps } from '@mui/material';

interface Props {
  tooltipContent:TooltipProps['title']
}

function InfoTooltipIcon({ tooltipContent }:Props) {
  return (
    <Tooltip title={tooltipContent}>
      <Box
        sx={{
          display: 'flex',
          marginLeft: 0.5,
          padding: 0.5,
          borderRadius: '8px',
          color: (theme) => theme.palette.darkGrey[30],
          '&:hover': { backgroundColor: (theme) => theme.palette.darkGrey[80] },
        }}
      >
        <InfoIcon />
      </Box>
    </Tooltip>
  );
}
export default InfoTooltipIcon;
