import { useRef } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ClearSmallIcon, SearchIcon } from '@lunit/scope-icons';

const SearchIconComponent = styled(SearchIcon)(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.darkGrey[30],
  pointerEvents: 'none',
  '& path': {
    fill: theme.palette.darkGrey[30],
    stroke: 'currentColor',
  },
}));

interface SearchFieldProps {
  placeholder?: string
  value?: string
  onSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onResetInput?: () => void
}

function SearchField({
  value,
  placeholder,
  onSearch,
  onResetInput,
}: SearchFieldProps) {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <TextField
      placeholder={placeholder}
      sx={{
        width: 320,
        marginRight: theme.spacing(1),
      }}
      value={value}
      variant="outlined"
      onChange={onSearch}
      inputRef={inputRef}
      InputProps={{
        'aria-label': 'search',
        startAdornment: (
          <InputAdornment position="start">
            <SearchIconComponent />
          </InputAdornment>
        ),
        endAdornment: value !== '' && (
          <InputAdornment position="end">
            <IconButton
              onClick={onResetInput}
              sx={{ padding: 0 }}
              size="large"
            >
              <ClearSmallIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchField;
