import { Typography } from '@mui/material';

interface Props {
  label:string,
  children:React.ReactNode
}

function ChartTitle({ label, children }:Props) {
  return (
    <>
      <Typography variant="body2">{label}</Typography>
      <Typography
        variant="subtitle3"
        sx={{ marginLeft: 1 }}
      >
        {children}
      </Typography>
    </>
  );
}
export default ChartTitle;
