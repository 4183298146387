import useSlideFromPath from 'src/hooks/useSlideFromPath';
import { useIOResultData } from 'src/workers/hooks';

import { ProjectPageProps } from 'src/types';

import ProjectPage from 'src/components/common/ProjectPage';
import IOSlideViewer from './IOSlideViewer';
import IOAnalysisSummary from './IOAnalysisSummary';

function IO({ metadata, projectConfig }: ProjectPageProps) {
  const { currentSlide } = useSlideFromPath(metadata.results);
  const { data, isLoading } = useIOResultData(
    currentSlide?.resultPath,
    metadata.uiComponents,
  );

  if (!currentSlide) return null;

  const viewer = (
    <IOSlideViewer
      slideData={{
        slideDziUrl: currentSlide.slideDziUrl,
        slideDziMetaUrl: currentSlide.slideDziMetaUrl,
      }}
      uiComponents={metadata.uiComponents}
      resultData={data}
    />
  );

  const analysisSummaryPanel = (
    <IOAnalysisSummary
      uiComponents={metadata.uiComponents}
      summary={data?.analysisSummary}
    />
  );

  return (
    <ProjectPage
      viewer={viewer}
      analysisSummaryPanel={analysisSummaryPanel}
      isLoadingResultData={isLoading}
      metadata={metadata}
      projectConfig={projectConfig}
    />
  );
}

export default IO;
