import { Bar, BarLabel, ScoreDot, ScoreText } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/styled';
import { ChartTitle, LineChartContainer } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/Charts';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import getScorePosition, { ScoreAxis } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/Charts/getScorePosition';

const scoreAxis: ScoreAxis = [
  [0, 0],
  [10, 0.15],
  [50, 0.5],
  [100, 1],
];

interface Props {
  summaryItem:AnalysisData
}

function CPSChart({ summaryItem }:Props) {
  const score = Math.floor(Number(summaryItem?.value ?? '0'));
  const [scorePosition, scoreTable] = getScorePosition(scoreAxis, score);

  const title = (
    <ChartTitle label="CPS">
      {score}
    </ChartTitle>
  );

  const bars = [
    <Bar
      key="first-bar"
      sx={{ width: scoreTable['10'].size, backgroundColor: '#5091ff' }}
    />,
    <Bar
      key="second-bar"
      sx={{ width: scoreTable['50'].size + scoreTable['100'].size, backgroundColor: '#F76707' }}
    />,
  ];

  const barLabels = scoreAxis.map(
    ([coord, position]) => (
      <BarLabel
        key={`label-${coord}`}
        sx={{ left: `${position * 100}%` }}
      >
        {coord}
      </BarLabel>
    ),
  );

  const scoreIndicator = (
    <ScoreDot
      sx={{ left: `${scorePosition * 100}%` }}
      data-value={score}
    />
  );
  const scoreLabel = (
    <ScoreText
      sx={{ left: `${scorePosition * 100}%` }}
      data-value={score}
    >
      {score}
    </ScoreText>
  );

  return (
    <LineChartContainer
      title={title}
      bars={bars}
      barLabels={barLabels}
      scoreIndicator={scoreIndicator}
      scoreLabel={scoreLabel}
    />
  );
}
export default CPSChart;
