import {
  ControllerProps,
  ProjectConfigSpec,
  WithSubChildControllerProps,
} from 'src/types';

export const her2Config: ProjectConfigSpec = {
  projectName: 'her2',
  titleHasTooltip: false,
  hasSummarySelector: true,
  elementConfig: [
    {
      name: 'Density Heatmap',
      elementType: 'label',
      childOptions: 'hide',
      individualInfoTooltip: 'passed-in',
    },
    {
      name: 'Cell Detection',
      elementType: 'label',
      childOptions: 'show-with-state',
    },
    {
      name: 'Tissue Segmentation',
      elementType: 'label',
      childOptions: 'show-with-state',
    },
  ],
};

export type HER2ControllerOrder = [
  ControllerProps,
  WithSubChildControllerProps,
  WithSubChildControllerProps,
];
