import * as React from 'react'

const SvgShortcut = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 4.5A1.5 1.5 0 0 0 1 6v8a1.5 1.5 0 0 0 1.5 1.5h15A1.5 1.5 0 0 0 19 14V6a1.5 1.5 0 0 0-1.5-1.5h-15ZM2 6a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-15A.5.5 0 0 1 2 14V6Zm2 6a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1H4Zm-.5-4.499a.5.5 0 0 1 .5-.5h1a.5.5 0 1 1 0 1H4a.5.5 0 0 1-.5-.5ZM4 9.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1H4Zm2.5.5a.5.5 0 0 1 .5-.5h.5a.5.5 0 0 1 0 1H7a.5.5 0 0 1-.5-.5Zm8.5-.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1Zm-3 .5a.5.5 0 0 1 .5-.5h.5a.5.5 0 0 1 0 1h-.5a.5.5 0 0 1-.5-.5Zm-2.25-.5a.5.5 0 0 0 0 1h.5a.5.5 0 0 0 0-1h-.5ZM6.5 7.501a.5.5 0 0 1 .5-.5h.5a.5.5 0 1 1 0 1H7a.5.5 0 0 1-.5-.5Zm6-.5a.5.5 0 0 0 0 1h.5a.5.5 0 0 0 0-1h-.5Zm-3.25.5a.5.5 0 0 1 .5-.5h.5a.5.5 0 0 1 0 1h-.5a.5.5 0 0 1-.5-.5Zm5.75-.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1Zm-.5 5.499a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5ZM7 12a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H7Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgShortcut
