import { BarLabel } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/styled';
import { ChartTitle, BarChartContainer, DynamicBar } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/Charts';
import { AnalysisData } from 'src/workers/AnalysisResultWorker/common/types';
import { map } from 'lodash';
import { LabelElement } from 'src/api/biomarkers/models';
import { ScoreAxis } from 'src/components/common/ViewOptionsPanel/AnalysisSummaryElements/Charts/getScorePosition';

interface Props {
  summaryItem:AnalysisData
  cellDetectionElement: LabelElement | undefined
}

const scoreAxis: ScoreAxis = [
  [0, 0],
  [100, 1],
];

function HER2Chart({ summaryItem, cellDetectionElement }:Props) {
  if (!summaryItem.items) { return null; }

  const score = summaryItem.items[0].value;

  const title = (
    <ChartTitle label="SCORE">
      {score}
    </ChartTitle>
  );

  const barChartBars = summaryItem.items
    ? map(summaryItem.items[1].items, (data) => (
      <DynamicBar
        key={data.name}
        analysisData={data}
        cellDetectionElement={cellDetectionElement}
      />
    )) : [];

  const barLabels = scoreAxis.map(
    ([coord, position]) => (
      <BarLabel sx={{ left: `${position * 100}%` }}>
        {coord}
        %
      </BarLabel>
    ),
  );

  return (
    <BarChartContainer
      title={title}
      bars={barChartBars}
      barLabels={barLabels}
    />
  );
}
export default HER2Chart;
