import * as React from 'react'

const SvgMoveUp = props => (
  <svg
    width={21}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.663 3.153a.499.499 0 0 0-.729.009L3.436 10.16a.5.5 0 1 0 .733.68l5.634-6.067V17.5a.5.5 0 0 0 1 0V4.773l5.633 6.067a.5.5 0 0 0 .733-.68l-6.499-7a.341.341 0 0 0-.007-.007Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgMoveUp
