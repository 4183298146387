import { useState } from 'react';
// eslint-disable-next-line import/no-cycle
import { IDialogContent } from './context';

export default function useDialog() {
  const [open, setOpen] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<IDialogContent>({ content: null });

  const handleClose = () => {
    setOpen((prev: boolean) => !prev);
  };

  const handleDialog = (content?: IDialogContent) => {
    if (content) {
      setDialogContent(content);
      if (!open) {
        setOpen(true);
      }
    } else {
      setOpen(false);
    }
  };

  return { open, handleDialog, handleClose, dialogContent };
}
