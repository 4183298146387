type Score = number;
type ScorePosition = number;
type BarRange = number;
type BarSize = number;

export type ScoreAxisItem = [Score, ScorePosition];
export type ScoreAxis = Array<ScoreAxisItem>;

export type ScoreTableItem = { range: BarRange, size: BarSize };
export type ScoreTable = Record<Score, ScoreTableItem>;

export default function getScorePosition(
  axis: ScoreAxis,
  value: Score,
): [ScorePosition, ScoreTable] {
  const table = axis.reduceRight<ScoreTable>(
    (result, [currentCoord, currentPos], index, origin) => {
      const [prevCoord = 0, prevPos = 0] = origin[index - 1] ?? [];
      const range: BarRange = currentCoord - prevCoord;
      const size: BarSize = currentPos - prevPos;
      // eslint-disable-next-line no-param-reassign
      result[currentCoord] = { range, size };
      return result;
    },
    {},
  );

  let position: ScorePosition = 0;
  let remaining: BarRange = value;

  for (let i = 0; i < axis.length; i += 1) {
    const [coord] = axis[i];
    const { range, size } = table[coord];
    if (remaining - coord < 0) {
      position += (remaining / range) * size;
      remaining = 0;
      break;
    }
    position += size;
    remaining -= range;
  }

  return [position, table];
}
