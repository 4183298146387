import type { ComponentsOverrides } from '@mui/material/styles'

const styleOverrides: ComponentsOverrides['MuiAccordionSummary'] = {
  root: {
    padding: '0 24px',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    margin: '15px 0',
    '&$expanded': {
      margin: '15px 0',
    },
  },
}
export default {
  styleOverrides,
}
