import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { UIComponents } from 'src/api/biomarkers/models';
import {
  getTumorPurityResultWorker,
  getHER2ResultWorker,
  getIOResultWorker,
  getPDL1TPSResultWorker,
  getCPSBreastResultWorker,
  getCPSBladderResultWorker,
} from './AnalysisResultWorker';
import { clearCache, fetchVisualizationData } from './AnalysisResultWorker/api';

const useAnalysisResultData = (
  msgpackFilePath: string | undefined,
  uiComponents: UIComponents | undefined,
  getWorker: () => Worker,
  queryKey: string | readonly unknown[],
) => {
  const [worker, setWorker] = useState<Worker>();
  useEffect(
    () => {
      setWorker(getWorker());
      return () => {
        worker?.terminate();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(
    () => () => {
      clearCache(msgpackFilePath as string);
    },
    [msgpackFilePath],
  );
  const data = useQuery(
    queryKey,
    () => fetchVisualizationData(msgpackFilePath!, worker!, uiComponents!),
    {
      cacheTime: 0,
      suspense: false,
      useErrorBoundary: false,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!msgpackFilePath && !!worker && !!uiComponents,
      keepPreviousData: false,
    },
  );
  return data;
};

export const useTumorPurityResultData = (
  msgpackFilePath: string | undefined,
  uiComponents: UIComponents | undefined,
) => useAnalysisResultData(
  msgpackFilePath,
  uiComponents,
  getTumorPurityResultWorker,
  ['tumorPurityVisualizationData', msgpackFilePath],
);

export const useHER2ResultData = (
  msgpackFilePath: string | undefined,
  uiComponents: UIComponents | undefined,
) => useAnalysisResultData(
  msgpackFilePath,
  uiComponents,
  getHER2ResultWorker,
  ['her2VisualizationData', msgpackFilePath],
);

export const useIOResultData = (
  msgpackFilePath: string | undefined,
  uiComponents: UIComponents | undefined,
) => useAnalysisResultData(
  msgpackFilePath,
  uiComponents,
  getIOResultWorker,
  ['ioVisualizationData', msgpackFilePath],
);

export const usePDL1TPSResultData = (
  msgpackFilePath: string | undefined,
  uiComponents: UIComponents | undefined,
) => useAnalysisResultData(
  msgpackFilePath,
  uiComponents,
  getPDL1TPSResultWorker,
  ['pdl1TPSVisualizationData', msgpackFilePath],
);

export const useCPSBreastResultData = (
  msgpackFilePath: string | undefined,
  uiComponents: UIComponents | undefined,
) => useAnalysisResultData(
  msgpackFilePath,
  uiComponents,
  getCPSBreastResultWorker,
  ['cpsBreastVisualizationData', msgpackFilePath],
);

export const useCPSBladderResultData = (
  msgpackFilePath: string | undefined,
  uiComponents: UIComponents | undefined,
) => useAnalysisResultData(
  msgpackFilePath,
  uiComponents,
  getCPSBladderResultWorker,
  ['cpsBladderVisualizationData', msgpackFilePath],
);
