import { useRecoilState } from 'recoil';
import { her2SummaryAtom } from 'src/state';

function useHER2SummarySelection() {
  const [summaryTab, setSummaryTab] = useRecoilState(her2SummaryAtom);

  return {
    value: summaryTab,
    set: setSummaryTab,
  };
}
export default useHER2SummarySelection;
