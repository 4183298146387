import { RightContainer } from '../../ToolbarMUI';

interface ToolbarRightProps {
  children: React.ReactNode
}

function ToolbarRight({ children }: ToolbarRightProps) {
  return <RightContainer>{children}</RightContainer>;
}

export default ToolbarRight;
