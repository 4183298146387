import { Box, List, styled } from '@mui/material';

export const DrawerList = styled(List)({
  paddingTop: 1,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

export const SlideLinkContainer = styled(Box)({
  width: 208,
  height: 98,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

export const LoadableImageContainer = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: 4,
  '& img': {
    width: '100%',
    height: 'auto',
  },
});
