import type { ComponentsOverrides } from '@mui/material/styles'
import palette from '../colors'

const styleOverrides: ComponentsOverrides['MuiDivider'] = {
  root: {
    backgroundColor: palette.darkGrey[60],
    height: 1,
    border: 'none',
  },
  vertical: {
    width: 1,
    border: 'none',
  },
}
export default {
  styleOverrides,
}
