import ProjectViewer from 'src/components/common/ProjectPage/ProjectViewer';
import { SlideUrlData } from 'src/components/common/SlideViewer/types';
import usePDL1Visualization from 'src/hooks/visualization/usePDL1TPSVisualisation';
import { ResultWorkerPreparedEventData } from 'src/workers/AnalysisResultWorker/types';

interface PDL1SlideViewerProps {
  slideData: SlideUrlData
  resultData?: ResultWorkerPreparedEventData
}

function PDL1SlideViewer({ slideData, resultData }: PDL1SlideViewerProps) {
  const visualization = usePDL1Visualization(resultData);

  return (
    <ProjectViewer
      slideData={slideData}
      resultData={resultData}
      visualization={visualization}
    />
  );
}

export default PDL1SlideViewer;
