import { Box, BoxProps, useTheme } from '@mui/material';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { AuthPropKeys } from './types';

interface AuthNavProps extends Pick<BoxProps, AuthPropKeys> {
  leftText?: string
  rightText?: string
}

const AuthNav = forwardRef<HTMLDivElement, AuthNavProps>((props, ref) => {
  const theme = useTheme();
  return (
    <Box
      ref={ref}
      {...props}
      className={clsx('AuthNav', props.className)}
      sx={{
        ...theme.typography.body4,
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 3,
        '& > *:only-child': { marginLeft: 'auto' },
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
});

AuthNav.displayName = 'AuthNav';

export default AuthNav;
